import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ACCESS_STATUS,
  LIC_AGREMENT_LEBELS,
  URLS,
  HTTP_STATUS_CODE,
  ERROR_MSG,
} from "../../../helper/constants";
import { GeoCheck } from "../GeoCheck/GeoCheck";
import "./LicAgreementPopup.scss";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Loader from "../../common/Loader/Loader";
import axios from "axios";
import fileDownload from "js-file-download";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
// import "react-pdf/dist/esm/Page/TextLayer.css";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
export const LicAgreementPopup = () => {
  const [show, setShow] = useState(true);
  const [setgeo, setGeo] = useState(0);
  const { t } = useTranslation();
  const [displaypage, setDisplayPage] = useState(true);
  const [errMSg, setErrMsg] = useState("");
  const [scrolldown, setScrollDown] = useState(false);
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });

  const { tokenValid, isLoadingToken } = useSelector(
    (state: any) => state.config
  );
  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );
  const { location, resLocationStatus, isLoadingLocation } = useSelector(
    (state: any) => state.location
  );
  const { licdata, isLoadingLic, resLicStatus } = useSelector(
    (state: any) => state.licagreement
  );
  const approvedStatus = ACCESS_STATUS.APPROVED;
  const checkStatus = tokenValid?.isSuccess;
  const toolAccess =
    checkStatus === true ? tokenValid?.result?.KEY_TOOL_ACCESS : "";
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = async ({ numPages }) => {
    setNumPages(numPages);
  };
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (
      tokenValid &&
      isLoadingToken === false &&
      checkStatus === true &&
      toolAccess != "" &&
      toolAccess == approvedStatus
    ) {
      setTimeout(function () {
        setShow(true);
      }, 10);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
   // setGeo(1);
  };

  const disagreeBtnHandler = () => {
    window.location.href =
      configData.TECHPUB_AEM_DETAILS?.AEM_HOST + URLS.HOME_AEROSPACE_URL;
  };
  const downloadAgreement = async () => {
    // setLoader(true);
    await axios({
      url: "/api/v1/lic-agreement/download",
      method: "GET",
      responseType: "arraybuffer",
    }).then((resp: any) => {
      //setLoader(false);
      fileDownload(resp.data, configData.LIC_PDF?.PDF_NAME);
    });
  };

  const acceptLicAgreement = async () => {
    handleClose();
    // setLoader(true);
    try {
      const response = await fetch("/api/v1/lic-agreement/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (HTTP_STATUS_CODE.STATUS_200 === response.status) {
        //  setLoader(false);

       // setGeo(1);
      } else {
        setErrMsg("something went wrong");
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };

  const handleScroll = (event) => {
    if (
      Math.round(event.target.scrollHeight) -
        Math.round(event.target.scrollTop) -
        2 <=
      event.target.clientHeight
    ) {
      setScrollDown(true);
    } else {
      setScrollDown(false);
    }
  };

  return (
    <>
      {tokenValid &&
        isLoadingToken === false &&
        isLoadingLocation === false &&
        checkStatus === true &&
        toolAccess != "" &&
        toolAccess == approvedStatus &&
        displaypage === true &&
        setgeo === 1 &&
        resLocationStatus === 204 && (
          <>
            <GeoCheck />
          </>
        )}
      <Modal
        className="modal-wrapper licagreementpopup"
        aria-label="lic poppup"
        show={show}
        id="lic_popup"
      >
        <div className="modalbodycontentlic modalbodycontent">
          <Modal.Header>
            <Modal.Title>
              <span className="modaltitle">{t(LIC_AGREMENT_LEBELS.TITLE)}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Modal-body-lic" onScroll={handleScroll}>
            <div>
              <Document
                file="/lic_agreement/Honeywell-Material-License-Agreement.pdf"
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {[1, 2, 3, 4].map((page) => (
                  <Page pageNumber={page} key={page} />
                ))}
              </Document>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footercontainerlic footercontainer">
              <div className="geocheckbtns">
                <div className="leftbtn">
                  <button
                    className="lic-agreement-btn downloadButton"
                    onClick={downloadAgreement}
                  >
                    <a className="downloadbtn">
                      {" "}
                      {t(LIC_AGREMENT_LEBELS.DOWNLOAD)}
                    </a>
                  </button>
                </div>
                <div className="rightbtn">
                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="cancelbtn"
                    variant="secondary"
                    onClick={disagreeBtnHandler}
                  >
                    {t(LIC_AGREMENT_LEBELS.DISAGREE)}
                  </Button>
                  {loader ? (
                    <div className="loder-css">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {scrolldown ? (
                        <Button
                          type="button"
                          aria-label="Close Preferences"
                          className="submitbtn active"
                          variant="primary"
                          onClick={acceptLicAgreement}
                        >
                          {t(LIC_AGREMENT_LEBELS.AGREE)}
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          aria-label="Close Preferences"
                          className="submitbtn"
                          disabled={true}
                        >
                          {t(LIC_AGREMENT_LEBELS.AGREE)}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
      ;
    </>
  );
};
