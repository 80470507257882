const Feature = ({ name, children }) => {
  const features = JSON.parse(sessionStorage.getItem("flags"));
  let feature = features.find((feature) => feature.name === name);
  if (feature && feature.active) {
    return children;
  }

  return null;
};

export default Feature;
