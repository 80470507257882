import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableComponent from "../TableComponent/TableComponent";
import {
  DOWNLD_DATA,
  ERROR_MSG,
  TABLEPAGINATION,
  TOPHEADERTITLE,
  HTTP_STATUS_CODE,
} from "../../../helper/constants";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import TablePaginations from "../TablePaginations/TablePaginations";
import { useDispatch } from "react-redux";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";

const Downloads = () => {
  const { t } = useTranslation();
  const [responseData, setResponseData] = useState<any>([]);
  const [errMSG, setErrorState] = useState({ flag: false, msg: "" });
  const [totalRecordCount, settotalRecordCount] = useState<any>();
  const [totalPageNumber, setTotalPageNumber] = useState<any>();
  const [pagniationFlag, setPaginationFlag] = useState(true);
  const [addFavFlag, setAddFavFlag] = useState(false);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const getDownloadData = async (pageNumber = 1) => {
    setLoader(true);
    try {
      const response = await fetch(
        `/api/v1/download-history?pageNo=${pageNumber}&pageSize=${TABLEPAGINATION.RowsPerPage}&isTopFive=false`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setLoader(false);
      if (
        data?.message?.isSuccess === true &&
        data?.message?.result?.downloadElasticResponse !== undefined
      ) {
        setResponseData(data?.message?.result?.downloadElasticResponse);

        settotalRecordCount(data?.message?.result?.totalRecords);
        setTotalPageNumber(
          Math.ceil(
            data?.message?.result?.totalRecords / TABLEPAGINATION.RowsPerPage
          )
        );
      } else if (
        data?.message?.isSuccess === true &&
        data?.message?.result?.downloadElasticResponse === undefined
      ) {
        setResponseData([]);
        setTotalPageNumber(0);
      } else if (data?.message?.isSuccess === false) {
        setErrorState({ flag: true, msg: data?.message?.result });
        setTotalPageNumber(0);
      } else {
        setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
        setTotalPageNumber(0);
      }
    } catch (error) {
      setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
      setLoader(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getDownloadData();
    dispatch(
      RequestFormAction.getRequestFormData({
        requestDataObj: "",
      })
    );
  }, []);

  const onChangeErrorModal = () => {
    setErrorState({ flag: false, msg: "sucsess" });
  };

  const parentPageChange = (page) => {
    // setTotalPageNumber(page);
    getDownloadData(page);
  };

  const addFavorite = async (pubid) => {
    try {
      const response = await fetch("/api/v1/favorite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: pubid,
          isTopFavourite: false,
        }),
      });
      if (HTTP_STATUS_CODE.STATUS_200 === response.status) {
        addRemoveFavButtonChange(pubid, true);
        // setAddFav('addfav');
      } else if (response.status === 401) {
      }
    } catch (error) {}
  };
  const addRemoveFavoriteHandler = (pubId, indicator) => {
    indicator == "add" ? addFavorite(pubId) : removeFavorite(pubId);
  };
  const addRemoveFavButtonChange = (pubid, favFlag) => {
    const historyIndex = responseData.findIndex(
      (historyData) => historyData?.pubId == pubid
    );
    const historyDataObj: any = [...responseData];
    const historyRecord = historyDataObj[historyIndex];
    historyRecord["favouriteFlag"] = favFlag;
    historyDataObj[historyIndex] = historyRecord;
    setResponseData(historyDataObj);
  };
  const removeFavorite = async (pubid) => {
    setAddFavFlag(false);
    try {
      const response = await fetch(`/api/v1/favorite/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: pubid,
        }),
      });
      if (
        HTTP_STATUS_CODE.STATUS_200 === response.status ||
        HTTP_STATUS_CODE.STATUS_204 === response.status
      ) {
        addRemoveFavButtonChange(pubid, false);
      } else {
      }
    } catch (error) {}
  };
  return (
    <div>
      <TableComponent
        attributes={DOWNLD_DATA}
        topHeader={TOPHEADERTITLE.DOWNLOADS_TITLE}
        userData={responseData}
        purpose="downloads"
        loaderFlag={loader}
        addfavclass={addFavFlag}
        addRemoveFavoriteHandler={addRemoveFavoriteHandler}
      />
      {totalPageNumber > 1 && pagniationFlag && (
        <TablePaginations
          count={totalPageNumber}
          parentPageChange={parentPageChange}
        />
      )}
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.flag}
        onClose={onChangeErrorModal}
      />
    </div>
  );
};

export default Downloads;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

