import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import "../FilterFacets/FilterFacets.scss";
import {
  APPLY_FILTER,
  FILTER_FACETS,
  RELEASE_DATE_RANGE,
  RELEASE_DATE_VALUES,
  FACET_LABELS,
} from "../../../../helper/constants";

const AppliedFilter = (props) => {
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState([]);
  const appliedFacetArry = props.selectedFacets;
  const list = Object.keys(appliedFacetArry);

  const findLabel = (data) => {
    return FACET_LABELS[data].label;
  };

  const findReleaseLabel = (relaseDateVal) => {
    const relaseDateValueObj = RELEASE_DATE_VALUES.find(
      (rd) => rd.value == relaseDateVal
    );
    return relaseDateValueObj !== undefined
      ? relaseDateValueObj?.label
      : relaseDateVal;
  };

  return (
    <Accordion defaultActiveKey={["0"]} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="facet-section AppliedFacet">
            <span className="heading-c AppliedFilterHeading">
              {t(APPLY_FILTER.APPLY_TITLE)}
            </span>
            <span className="clear-facet clearlink" onClick={props.clearAllFilterFacets}>
              Clear
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {list &&
            list.map(
              (el, index) =>
                appliedFacetArry[el].length > 0 && (
                  // appliedFacetArry[el].map((facets, index) => (
                  <p key={index}>
                    <span className="AppliedFiltercomponent">
                      <span className="Appliedlabel">{`${t(
                        findLabel(el)
                      )}:`}</span>{" "}
                      <span
                        className={`Appliedvalue  ${
                          appliedFacetArry[el].length > 1 ||
                          el == RELEASE_DATE_RANGE
                            ? "multipleSelection"
                            : ""
                        }`}
                      >
                        {el != RELEASE_DATE_RANGE
                          ? appliedFacetArry[el].join(", ")
                          : findReleaseLabel(appliedFacetArry[el][0])}
                      </span>
                    </span>
                    <a
                      className="removeicon"
                      role="group"
                      aria-label="RemoveIcon"
                      onClick={() => {
                        props.facetSelectDeselect(
                          appliedFacetArry[el],
                          "",
                          el,
                          false
                        );
                      }}
                    ></a>
                  </p>
                )
              // ))
            )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AppliedFilter;
