import { createReducer, createSlice } from "@reduxjs/toolkit";

const initialState = {
  deepLevel: "",
};

export const deepfacetFilterReducer = createSlice({
  name: "filterSelectedFacets",
  initialState,
  reducers: {
    getDeepLevelFacets: (state, { payload }) => {
      return {
        deepLevel: payload?.deepLevel,
      };
    },
  },
});

export const DeepFacetFilterActions = deepfacetFilterReducer.actions;
