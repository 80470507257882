import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  DOWNLOAD_PDF,
  REQUEST_DOWNLOAD_MESSAGE,
  REQUEST_DOWNLOAD_ERROR,
  honeywellFAQLink,
} from "../../../helper/constants";
import { FaCloudDownloadAlt } from "react-icons/fa";
import ProgressBar from "react-bootstrap/ProgressBar";

import axios from "axios";
import download from "js-file-download";
import "./FileStreamerModal.scss";
import { useSelector } from "react-redux";

const FileStreamerModal = (props) => {
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [dwnldStarted, setDwnldStarted] = useState(false);
  const [dwnldCompleted, setDwnldCompleted] = useState(false);
  const [cancelDownload, setCancelDownload] = useState(false);
  const [popupBlocker, setPopupBlocker] = useState(false);
  const { t } = useTranslation();

  let percentCompleted;

  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );

  const controller = new AbortController();
  const setCookieInformation = (val) => {
    document.cookie =
      "jwtToken=" + val + ";domain=.honeywell.com;path=/;secure";
  };

  const downloadPDF = async () => {
    //setDwnldStarted(true);
    const jwtTokenCookie = readCookie(configData?.["TECHPUB_TOKEN_ID"]);

    setCookieInformation(props?.jwtToken);

    const windowOpenUrl =
      configData.DMZ_HOST_NAME +
      "?token=" +
      encodeURIComponent(props.pdfToken) +
      "&pubId=" +
      props?.pubId;

    const Dwnld_NewTab = window.open(windowOpenUrl, "_blank");

    if (isPopupBlockerActivated(Dwnld_NewTab)) {
      setPopupBlocker(true);
    } else {
      props.closeErrorPopup();
    }

    const pdfParamToken = encodeURIComponent(props.pdfToken);

    /*await axios({
      url: `/api/v1/download/download-document?token=${pdfParamToken}`,
      //url: "http://localhost:3000/pdf/spring-boot-reference.pdf",
      method: "GET",
      responseType: "blob", // important

      onDownloadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) /
            progressEvent.target.getResponseHeader("content-length")
        );

        setProgress(percentCompleted);
      },
    })
      .then((resp) => {
        const myCookieValue: any = readCookie("cancelDownloadClick");
        if (myCookieValue === "false") {
          setDwnldCompleted(true);
          const fileName =
            resp?.headers["content-disposition"].split("filename=")[1];
          // download(resp.data, "Spring.pdf");
          const file = new Blob([resp.data], {
            type: "application/pdf",
          });
          download(file, fileName, "application/octet-stream");
          const status = "completed";
          props.setSaveApiStatus(status);
          setDwnldStarted(false);
          downloadSave(status, "");
          props.setIsError(false);
          document.cookie = `cancelDownloadClick=${false}`;
        }
      })
      .catch((error) => {
        setDwnldCompleted(false);
        props.setIsError(true);
      });
    console.log(progress);*/
  };

  const downloadSave = async (payload, callBack) => {
    try {
      const response = await fetch(`/api/v1/download-save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pubId: props?.pubId, status: payload }),
      });
      const data = await response.json();
      if (payload === "in progress") {
        callBack();
      }
      if (
        data?.message?.isSuccess === true &&
        data?.message?.result !== undefined
      ) {
        props.setIsError(false);
        props.setSaveApiStatus("in progress");
      } else if (
        data?.message?.isSuccess === true &&
        data?.message?.result === undefined
      ) {
      } else if (data?.message?.isSuccess === false) {
        props.setIsError(true);
      } else {
      }
    } catch (error) {
      props.setIsError(true);
    }
  };
  const cancelDownloadRequest = () => {
    controller.abort(); // This will abort the ongoing request\
    downloadSave("cancelled", "");
    setCancelDownload(true);
  };

  const readCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return cookieValue;
      }
    }
  };

  const isPopupBlockerActivated = (popupWindow) => {
    if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        try {
          popupWindow.focus();
        } catch (e) {
          return true;
        }
      } else {
        popupWindow.onload = function () {
          return popupWindow.innerHeight > 0 === false;
        };
      }
    } else {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (props.pdfToken !== "" && !cancelDownload) {
      downloadPDF();
      //setIsDownloading(true);
      setCancelDownload(true);
      document.cookie = `cancelDownloadClick=${false}`;
    } else {
      if (cancelDownload) {
        setIsDownloading(false);
        setDwnldCompleted(false);
        setProgress(0);
        setCancelDownload(false);
      } else if (props.saveApiStatus === "in progress" && !cancelDownload) {
        downloadSave(props.saveApiStatus, () =>
          props.callDownloadTransferApi()
        );
        setIsDownloading(false);
      }
    }
  }, [props.pdfToken]);

  return (
    <React.Fragment>
      {/* <Modal
        className="favPopup addremovePopup licagreementpopup Popupwrapper"
        aria-label="dialog Preferences"
        show={props.fileStreamer}
      >
        <div className="modalbodycontentlic modalbodycontentPopups  modalbodycontent">
          <Modal.Body className="Modal-body-liccontent quicklinkserrorPopup">
            
            <h4>{t(REQUEST_DOWNLOAD_ERROR.FILE_STREAMER)}</h4>
            <div className="modal-footer">
              <div className="footercontainerlic Popupfooter footercontainer">
                <div className="geocheckbtns">
              <Button
                type="button"
                aria-label="Close Preferences"
                className="submitbtn active btn btn-primary"
                variant="secondary"
                onClick={props.closeErrorPopup}
              >
                {t(REQUEST_DOWNLOAD_ERROR.BACK_BUTTON)}
              </Button>
              </div>
            </div>
            </div>
          </Modal.Body>
        </div>
      </Modal> */}

      <Modal
        className="favPopup addremovePopup licagreementpopup Popupwrapper downloadPopup"
        aria-label="dialog Preferences"
        show={props.fileStreamer}
      >
        {!props.isError && (
          <Modal.Header
            className="modal-h"
            //onHide={hidePopupHandler}
          >
            <Modal.Title
              className={`title-modal ${
                popupBlocker ? "popupBlockedTitle" : "downloadTitle"
              }`}
            >
              <h6>
                <>
                  {popupBlocker
                    ? t(DOWNLOAD_PDF.BLOCKED_TITLE)
                    : t(DOWNLOAD_PDF.TITLE)}
                </>
              </h6>
            </Modal.Title>
            {popupBlocker && (
              <button
                className="close-modal"
                onClick={() => {
                  props.closeErrorPopup();
                  setIsDownloading(false);
                  setDwnldCompleted(false);
                  setProgress(0);
                }}
                aria-label="Close modal window"
              >
                <span className="close-icon"></span>
              </button>
            )}
          </Modal.Header>
        )}
        {props.isError ? (
          <>
            <Modal.Body className="Modal-body-liccontent requestformPopup modal-body">
              <p>{t(DOWNLOAD_PDF.ERROR_MSG)}</p>
            </Modal.Body>
            <Modal.Footer>
              <div className="footercontainerlic Popupfooter footercontainer">
                <div className="geocheckbtns">
                  <Button
                    variant="primary"
                    className="cancelbtn active btn btn-secondary"
                    onClick={() => {
                      props.setIsError(false);
                      setDwnldCompleted(false);
                      setProgress(0);
                      props.setPdfToken("");
                      {
                        (props.fetchApiFailed || props.encryptApiFailed) &&
                          !cancelDownload &&
                          props.callDownloadTransferApi();
                      }
                    }}
                  >
                    {t(DOWNLOAD_PDF.YES)}
                  </Button>
                  <Button
                    variant="primary"
                    className="submitbtn active btn btn-primary btn btn-primary"
                    onClick={() => {
                      props.closeErrorPopup();
                      setIsDownloading(false);
                      setDwnldCompleted(false);
                      setProgress(0);
                    }}
                  >
                    {t(DOWNLOAD_PDF.NO)}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body
              className={`Modal-body-liccontent quicklinkserrorPopup downloadPub ${
                popupBlocker && "heightBlockerPopup"
              }`}
            >
              {popupBlocker ? (
                <span>
                  <p className="popupBlockedMessage">
                    {t(DOWNLOAD_PDF.BLOCKED_MESSAGE)}
                  </p>
                  <a
                    className="popupBlockedLink"
                    target="_blank"
                    href={honeywellFAQLink}
                  >
                    {t(DOWNLOAD_PDF.BLOCKED_LINK_TEXT)}
                  </a>
                </span>
              ) : (
                <span>
                  <div className="body-class">
                    {/* <h6
                // dangerouslySetInnerHTML={{ __html: t(DOWNLOAD_PDF.HEADING) }}
                > */}
                    <div className="info">
                      {" "}
                      {isDownloading && dwnldCompleted
                        ? "We appreciate your patience. The publication has been successfully downloaded."
                        : t(DOWNLOAD_PDF.HEADING)}
                    </div>
                    {isDownloading ? (
                      <>
                        <div className="downloadlingstatus">
                          {dwnldStarted
                            ? "Downloading your publication"
                            : dwnldCompleted
                            ? t(DOWNLOAD_PDF.DWNLD_COMPLETE)
                            : "Downloading your publication"}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="fetchingData">
                          {" "}
                          {t(DOWNLOAD_PDF.FETCHING)}
                          <span className="loader__dot">.</span>
                          <span className="loader__dot">.</span>
                          <span className="loader__dot">.</span>
                        </div>

                        <div className="msgtouser">
                          {progress < 100 && dwnldStarted
                            ? `Please do not close this window until the download has been fully completed.`
                            : progress === 100 && dwnldCompleted
                            ? "You may close this window."
                            : `Please do not close this window until the download has been fully completed.`}
                        </div>
                      </>
                    )}
                    {/* </h6> */}
                    {isDownloading && (
                      <>
                        <div aria-label="progress-window">
                          <ProgressBar
                            now={progress}
                            label={`${progress}%`}
                            className="progress-baar"
                          />
                        </div>
                        <div className="msgtouser">
                          {progress < 100 && dwnldStarted
                            ? `${t(DOWNLOAD_PDF.DOWNLOAD_COMPLETE)}`
                            : progress === 100 && dwnldCompleted
                            ? `${t(DOWNLOAD_PDF.CLOSE_WINDOW)}`
                            : ""}
                        </div>
                      </>
                    )}
                  </div>
                </span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="footercontainerlic Popupfooter footercontainer">
                <div className="geocheckbtns">
                  {progress === 100 ? (
                    <Button
                      type="button"
                      aria-label="Close Preferences"
                      className="submitbtn active btn btn-primary btn btn-primary"
                      variant="secondary"
                      onClick={() => {
                        props.closeErrorPopup();
                        setIsDownloading(false);
                        setDwnldCompleted(false);
                      }}
                    >
                      {t(DOWNLOAD_PDF.CLOSE)}
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      aria-label="Close Preferences"
                      className={` ${
                        popupBlocker
                          ? "submitbtn active btn btn-primary btn btn-primary"
                          : "cancelbtn active btn btn-secondary"
                      }`}
                      variant="secondary"
                      onClick={() => {
                        document.cookie = `cancelDownloadClick=${true}`;
                        props.closeErrorPopup();
                        setIsDownloading(false);
                        setDwnldCompleted(false);
                        props.setIsError(false);
                        cancelDownloadRequest();
                      }}
                    >
                      {popupBlocker
                        ? t(REQUEST_DOWNLOAD_MESSAGE.ACKNOWLEDGE)
                        : t(DOWNLOAD_PDF.CANCEL_DOWNLOAD)}
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default FileStreamerModal;
