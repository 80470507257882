import { useEffect } from "react";

const useOnEnterKeyCalendarInput = (
  inputId: string,
  validDates: boolean,
  callback: () => void,
  setOpenCalendar: (value: boolean) => void,
  keepCalendarDatesActive: () => void
) => {
  useEffect(() => {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && validDates) {
        callback();
        inputElement.blur();
        setOpenCalendar(false);
        keepCalendarDatesActive();
      }
    };

    if (inputElement) {
      inputElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [inputId, callback]);
};

export default useOnEnterKeyCalendarInput;
