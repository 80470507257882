import React from "react";
import "./SecondLevelFilter.scss";

const SecondLevelFilter = (props) => {
  //let secondFilterProps;
  const factType = props.facetType;
  const selectedValue = props.SelectedValue;
  const facetAttr = props.facetAttribute;
  //const FacetDeepLevel = 2;
  const secondFilterProps = props.secondFacetFilter;
  const currentFaceVal = props.currentFacetVal;
  const deepLevelFacets = props.deepLevelFacets;
  const secondLevelCheckboxes = (secondLevelBucket) => {
    return (
      <>
        <div className="secondlevelFacet">
          <p
            // className="second-level-facet"
            className={
              selectedValue[facetAttr.facetSecondLevelField]?.length >
                0 &&
                !selectedValue[facetAttr.facetSecondLevelField].includes(
                  secondLevelBucket?.key
                ) &&
                !facetAttr.secondLevelMultiSelect
                ? "d-none"
                : "second-level-facet"
            }
          >
            <div className="accordionitem">
              <div
                className={
                  selectedValue[
                    facetAttr.facetSecondLevelField
                  ]?.includes(secondLevelBucket?.key)
                    ? "leftcheckbox checked"
                    : "leftcheckbox"
                }
              >
                <input
                  aria-label="filter values"
                  type="checkbox"
                  value={secondLevelBucket.key}
                  onChange={(e) => {
                    props.facetSelectDeselect(
                      secondLevelBucket.key,
                      facetAttr.facetKey,
                      facetAttr.facetSecondLevelField,
                      e.target.checked
                    );
                  }}
                  checked={
                    selectedValue[
                      facetAttr.facetSecondLevelField
                    ]?.includes(secondLevelBucket?.key)
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <label aria-label="pub category">
              {secondLevelBucket?.key}
            </label>
          </p>
        </div>
      </>
    );
  };
 

  return (
    <>
      {(selectedValue[facetAttr.facetFirstLevel] !== undefined &&
        selectedValue[facetAttr.facetFirstLevel] == factType &&
        secondFilterProps && facetAttr.thirdLevel === false &&
        deepLevelFacets[facetAttr.facetKey] !== undefined) ? (deepLevelFacets[facetAttr.facetKey][0]?.[facetAttr.otherAttr]?.[
          facetAttr?.facetSecondLevel
        ]?.buckets.map((secondLevelBucket) => secondLevelCheckboxes(secondLevelBucket)
        )) : (selectedValue[facetAttr.facetFirstLevel] !== undefined &&
          selectedValue[facetAttr.facetFirstLevel] == factType &&
          secondFilterProps && secondFilterProps[facetAttr.facetSecondLevel]?.[facetAttr.otherAttr]?.[
            facetAttr?.facetSecondLevel
          ]?.buckets !== undefined && facetAttr.thirdLevel === true ? (secondFilterProps[facetAttr.facetSecondLevel]?.[facetAttr.otherAttr]?.[
            facetAttr?.facetSecondLevel
          ]?.buckets.map((secondLevelBucket) => secondLevelCheckboxes(secondLevelBucket))) : (''))}
    </>
  );
};

export default SecondLevelFilter;
