import React from "react";
import { Breadcrumb as BreadcrumbReact } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  LABELKEYS_DASHBOARD,
  LOCATION_BREADCRUMB,
  SUPPORT_AND_RESOURCES
} from "../../../helper/constants";
import { TechpubsLinks } from "../TechpubLinks/TechpubLinks";
import "./Breadcrumb.scss";

export const Breadcrumb = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bradcrumb">
        <BreadcrumbReact>
          <BreadcrumbReact.Item active>
            <span className="homeicon"></span>
          </BreadcrumbReact.Item>
          <BreadcrumbReact.Item active>
            <span>{SUPPORT_AND_RESOURCES}</span>
          </BreadcrumbReact.Item>
          <BreadcrumbReact.Item active></BreadcrumbReact.Item>
        </BreadcrumbReact>
      </div>
    </>
  );
};
