import React, { useCallback, useEffect, useState } from "react";
import { STORE_ACTION_TYPES, TABLEPAGINATION } from "../../../helper/constants";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const TablePaginations = (props) => {
  const [controller, setController] = useState({
    page: TABLEPAGINATION.Page,
    rowsPerPage: TABLEPAGINATION.RowsPerPage,
  });
  const [tablePage, setTablePage] = useState(controller.page);

 
  const handlePageChange = (event, newPage) => {
    if (props.Type == "TR") {
      window.scrollTo(0, window.innerHeight);
    } else {
      window.scrollTo(0, window.innerHeight / 3);
    }
    setController({
      ...controller,
      page: newPage,
    });
    setTablePage(newPage);
    props?.parentPageChange(newPage);
  };

  return (
    <Pagination
      className="myfavpagination"
      count={props.count}
      page={props.currentPage}
      onChange={handlePageChange}
      hidePrevButton={false}
      hideNextButton={false}
      showFirstButton={true}
      showLastButton={true}
      boundaryCount={0}
      siblingCount ={4}
      shape={"rounded"}
      color={"primary"}
    />
  );
};

export default TablePaginations;
