import React, { useEffect, useState } from "react";
import "./MyTopFiveFavorite.scss";
import { Link } from "react-router-dom";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  HTTP_STATUS_CODE,
  FAVOURITE_TABLE,
  ERROR_MSG,
} from "../../../helper/constants";
import Loader from "../../common/Loader/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import moment from "moment";
const MyTopFiveFavorite = (props) => {
  const [favroites, setFavroites] = useState<any>([]);
  const [errMSg, setErrMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const { configData, token } = useSelector((state: any) => state.config);
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const [showTopFive, setShowTopFive] = useState(false);
  useEffect(() => {
    getFavroites();
  }, []);

  //get token from reducer
  let tokenValidate: any = "";
  if (token?.token) {
    tokenValidate = token?.token;
  }

  //formating date
  const formatDate = (value) => {
    const date = value.split("T");
    return moment(date[0]).format("DD MMM Y");
    // const date = new Date(Date.parse(value));
    // const day = date.toLocaleString("default", { day: "2-digit" });
    // const month = date.toLocaleString("default", { month: "short" });
    // const year = date.toLocaleString("default", { year: "numeric" });
    // return day + " " + month + " " + year;
  };

  const getFavroites = async () => {
    try {
      setLoader(true);
      const response = await fetch("/api/v1/favorite?isTopFive=true", {
        method: "GET",
        headers: {
          cookie:
            "OptanonAlertBoxClosed=2022-11-10T11:47:36.193Z; 2261-token=`${tokenValidate}`; OptanonConsent=isIABGlobal=false&datestamp=Thu+Nov+24+2022+18^%^3A20^%^3A11+GMT^%^2B0530+(India+Standard+Time)&version=202210.1.0&hosts=&consentId=22d538a6-47b8-49af-bd4b-b1efd51b4763&interactionCount=1&landingPath=NotLandingPage&groups=C0001^%^3A1^%^2CC0003^%^3A1^%^2CC0002^%^3A1^%^2CC0004^%^3A1&geolocation=IN^%^3BMH&AwaitingReconsent=false",
        },
      });
      const data = await response.json();

      if (data?.message?.isSuccess === true) {
        setLoader(false);
        if (data?.message?.result?.totalRecords == 0) {
          setFavroites([]);

        } else {
          setShowTopFive(true);
          setFavroites(
            (data?.message?.result?.favouriteElasticResponse).sort(function (
              a,
              b
            ) {
              if (a.pubTitle.toLowerCase() < b.pubTitle.toLowerCase())
                return -1;
              if (a.pubTitle.toLowerCase() > b.pubTitle.toLowerCase()) return 1;
              return 0;
            })
          );
        }
      } else if (data?.message?.isSuccess === false) {
        setErrMsg(data?.message?.result);
        setErorState({ falg: true, msg: data?.message?.result });
        setLoader(false);
        setFavroites([]);
      } else {
        setErrMsg("Something Went Wrong..");
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
        setLoader(false);
      }
    } catch (error) {
      //setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };

  return (
    <>
      <div className="my_top_fav">
        <div className="tableComponent topfavorites">
          {loader ? (
            <Loader />
          ) : showTopFive && (<>
            <h1>{t(FAVOURITE_TABLE.TOP_5_FAV)}</h1>
            <Card className="tablecontainer mytop5table">
              <Table className="TableWrapper">
                <TableHead>
                  <TableRow>
                    <TableCell>{t(FAVOURITE_TABLE.TITLE)}</TableCell>
                    <TableCell>{t(FAVOURITE_TABLE.PUBLICATION_NO)}</TableCell>
                    <TableCell>
                      {t(FAVOURITE_TABLE.REVISION_DATE_NO)}
                    </TableCell>
                    <TableCell>{t(FAVOURITE_TABLE.TYPE)}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {favroites.length === 0 && (
                    <TableCell colSpan={4} className="addmessage">
                      {favroites.length === 0 ? (
                        <p className="messagetouser">
                          {t(FAVOURITE_TABLE.NO_FAV_ADDED)}
                        </p>
                      ) : (
                        <h1>{errMSg}</h1>
                      )}
                    </TableCell>
                  )}

                  {favroites.map((data, index) => (
                    <TableRow className="datarow" key={index}>
                      <>
                        <TableCell className="pubtitlecell">
                          <Link
                            to="/"
                            aria-label="PubTitle"
                            // state={data.pubNumber}
                            onClick={() =>
                              props.OnSelectTopFiveFav(data.pubNumber)
                            }
                          >
                            {data.pubTitle.length <= 40
                              ? `${data.pubTitle}`
                              : `${data.pubTitle.substring(0, 40) + "..."}`}
                          </Link>
                        </TableCell>
                        <TableCell> {data.pubNumber} </TableCell>
                        <TableCell>
                          {data.revDate === null
                            ? ""
                            : `${formatDate(data.revDate)}`}{" "}
                          / Rev {data.revNumber}
                        </TableCell>
                        <TableCell> {data.pubTypeName}</TableCell>
                      </>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </>)

          }
        </div>
      </div>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};

export default MyTopFiveFavorite;
