import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ERROR_MSG,
  RELATED_PUBLICATION,
  RELATED_PUBS_ATTRIBUTES,
  LABELKEYS_SEARCH_RESULTS,
} from "../../../helper/constants";
import BackToSearch from "../../common/BackToSearch/BackToSearch";
import { useLocation } from "react-router-dom";
import Feature from "../../common/FeatureFlags/Feature/Feature";
import SearchResults from "./SearchResults";
import ErrorModal from "../../common/ErrorModal/ErrorModal";

const RelatedPublication = () => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const [errMSG, setErrorState] = useState({ flag: false, msg: "" });
  const hits: any = [];
  const hitsId = location?.state?.id;
  const windowUrl = window?.location?.pathname;
  const pubId = windowUrl.substring(windowUrl?.lastIndexOf("/") + 1);
  const [relatedPublication, setRelatedPublication] = useState<any>([]);
  const [relatedPubTopData, setRelatedPubTopData] = useState<any>([]);
  const urldata = location?.state?.datares;
  const relsearchResultsData: any = location?.state?.relatedseacrhResults;
  const relatedSearchData = relsearchResultsData?.hits?.hits[hitsId];
  const relatedPublicationData = location?.state?.related_pub;
  hits?.push(relatedSearchData);

  /* Related publication data */

  const getRelatedPublication = async (topData, pubIds) => {
    try {
      const response = await fetch("/api/v1/search/search-results", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubIds: pubIds,
        }),
      });
      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        if (topData === true) {
          const totalValue = data?.message?.result?.hits?.total?.value;
          if (totalValue > 1) {
            const searchDataRes: any = data?.message?.result;
            const singleRecord: any = searchDataRes.hits?.hits[0];
            searchDataRes.hits.hits = [];
            searchDataRes.hits.hits.push(singleRecord);
            searchDataRes.hits.total.value = 1;
            setRelatedPubTopData(searchDataRes);
          } else {
            setRelatedPubTopData(data?.message?.result);
          }
        } else {
          setRelatedPublication(data?.message?.result);
        }
      } else if (data?.message?.isSuccess === false) {
        setErrorState({ flag: true, msg: data?.message?.result });
      } else {
        setErrorState({ flag: true, msg: data?.message?.result });
      }
    } catch (error) {
      setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  useEffect(() => {
    relsearchResultsData.hits.hits = hits;
    getRelatedPublication(true, [pubId]);
    getRelatedPublication(false, relatedPublicationData);
  }, []);

  const onChange = () => {
    setErrorState({ flag: false, msg: "sucsess" });
  };

  return (
    <>
      <BackToSearch data={urldata} />
      <Feature name="Search Results">
        <SearchResults
          dataSearch={relatedPubTopData}
          searchval={urldata}
          Watchlistflag={true}
          showMore={false}
          load={false}
          attributes={RELATED_PUBS_ATTRIBUTES}
          className="ViewcollectionContainer TopmetaData"
        />
      </Feature>
      <h1 className="relatedPubTitle">{t(RELATED_PUBLICATION.TITLE)}</h1>
      {relatedPublication?.hits?.total?.value > 0 ? (
        <Feature name="Search Results">
          <SearchResults
            dataSearch={relatedPublication}
            Watchlistflag={true}
            searchval={urldata}
            showMore={true}
            load={false}
            attributes={RELATED_PUBS_ATTRIBUTES}
            className="ViewcollectionContainer related-publication"
          />
        </Feature>
      ) : (
        <div></div>
      )}
      {relatedPublication?.hits?.total?.value == 0 && (
        <h1 className="erro-msg NodataAvailable">
          {t(LABELKEYS_SEARCH_RESULTS.NO_DATA_AVAILABLE)}
        </h1>
      )}

      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.flag}
        onClose={onChange}
      />
    </>
  );
};

export default RelatedPublication;
