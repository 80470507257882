import React, { useEffect, useState } from "react";
import RequestDownload from "../../common/RequestDownload/RequestDownload";
import { Button, Modal } from "react-bootstrap";

const RequestDownloadModal = (props) => {

  const [requestDownloadClick, setrequestDownloadClick] = useState(false);
  const [requestModalShow, setRequestModalShow] = useState(props.show);
  const requestDownloadCheck = () => {
    setRequestModalShow(false);
    setrequestDownloadClick(true);
  };

  const ClickHandler = (downlodClick) => {
    setrequestDownloadClick(downlodClick);
  };

  // added popup
  return (
    <>
      {requestDownloadClick && (
        <RequestDownload
          ldm={props?.ldm}
          a2a_indicator={props?.a2a2}
          parentClickHandler={ClickHandler}
          closeFileStreamer={props.onCloseModal}
          handleGeoCheckDownload={props?.handleGeoCheckDownload}
          pubNumber={props?.pubNumber}
          stampType={props?.stampType}
          pubsMedia={props.pubsMedia}
          tempRevId={props?.tempRevId}
          locationId={props?.locationId}
          pubId={props?.pubId} 
          tempRevData={props?.tempRevData}
          isLocationID = {props?.isLocationID}
        />
      )}
     <Modal
        className="favPopup addremovePopup licagreementpopup Popupwrapper"
        aria-label="dialog Preferences"
        show={requestModalShow}
        onHide={props.onCloseModal}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>{props.msg}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="footercontainerlic Popupfooter footercontainer">
            <div className="geocheckbtns">
              <Button
                variant="secondary"
                onClick={props.onCloseModal}
                className="cancelbtn active btn btn-secondary"
              >
                No
              </Button>
              <Button
                variant="primary"
                onClick={requestDownloadCheck}
                className="submitbtn active btn btn-primary btn btn-primary"
              >
                yes
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestDownloadModal;