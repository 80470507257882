import { createReducer, createSlice } from "@reduxjs/toolkit";

const initialState = {
  facetObj: "",
  selectedFactes: "",
  techpubLinks: "",
};

export const facetFilterReducer = createSlice({
  name: "filterSelectedFacets",
  initialState,
  reducers: {
    getSelectedFilterData: (state, { payload }) => {
      return {
        facetObj: payload.facetObjectVal,
        selectedFactes: payload?.facetPayloadVal,
        techpubLinks: payload.redirecTechLink
      };
    },
  },
});

// export const { getSelectedFilterData } = facetFilterReducer.actions;
export const FacetFilterActions = facetFilterReducer.actions;
