import React from "react";
import "./ThirdLevelFilter.scss";

const ThirdLevelFilter = (props) => {
  let thirdFacetFilter;
  const firstFacetType = props.firstFacetType;
  const secondFacetType = props.secondFacetType;
  const selectedFacets = props.facetsSelected;
  const facetAttr = props.facetAttribute;
  const deepLevelFacets = props.deepLevelFacets;
  return (
    <>
      {selectedFacets[facetAttr.facetSecondLevelField] !== undefined &&
        selectedFacets[facetAttr.facetSecondLevelField] == secondFacetType &&
         facetAttr.thirdLevel === true && deepLevelFacets[facetAttr.facetKey] !== undefined &&
        deepLevelFacets[facetAttr.facetKey][0]?.[facetAttr.otherAttr]?.[
          facetAttr?.facetThirdLevel
        ]?.buckets.map((thirdLevelBucket) => {
          return (
            <>
              <div className="thirdlevelFacet">
                <p className="third-level-facet">
                  <div className="accordionitem">
                    <div
                      className={
                        selectedFacets[
                          facetAttr.facetThirdLevelField
                        ]?.includes(thirdLevelBucket?.key)
                          ? "leftcheckbox checked"
                          : "leftcheckbox"
                      }
                    >
                      <input
                        aria-label="filter values"
                        type="checkbox"
                        value={thirdLevelBucket.key}
                        onChange={(e) => {
                          props.facetSelectDeselect(
                            thirdLevelBucket.key,
                            facetAttr.facetKey,
                            facetAttr.facetThirdLevelField,
                            e.target.checked
                          );
                        }}
                        checked={
                          selectedFacets[
                            facetAttr.facetThirdLevelField
                          ]?.includes(thirdLevelBucket?.key)
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>

                  <label aria-label="pub category">
                    {thirdLevelBucket?.key}
                  </label>
                </p>
              </div>
            </>
          );
        })}
    </>
  );
};

export default ThirdLevelFilter;
