import { sendAnalyticsdata } from "./analytics";

// Function to get the server name from the window object
function getServerNameFromGlobalScope() {
  const hostname = window.location.hostname;
  const serverName = hostname.split(".")[0];
  return serverName;
}

// Local Function to build the base payload for analytics
const buildAnalyticsBasePayload = (pathname) => {
  const { href, hostname } = window.location;
  const serverName = getServerNameFromGlobalScope();
  const onlyHostName = hostname
    .split(".")
    .find((item) => item.includes("aerotechpubs") && item);

  const basePayload = {
    eVar5: `${onlyHostName}:${pathname}`,
    pageName: `${onlyHostName}:${pathname}`,
    eVar10: `${href}`,
    server: window.location.hostname,
    linkType: "o",
  };

  return basePayload;
};

/**
 * This function is used to send analytics data and reuses the common base payload
 * @param {boolean} fillBasePayload will allow to fill up automatically the base payload : {eVar5, server, eVar10, linkType}
 * @param {object} incommingPayload your analytics payload object
 * @param {string} pathname the current pathname of the page like : /downloads
 */
const utilAnalytics = (fillBasePayload, incommingPayload, pathname) => {
  let payload = incommingPayload;

  if (fillBasePayload) {
    payload = {
      ...buildAnalyticsBasePayload(pathname),
      ...payload,
    };
  }
  sendAnalyticsdata(payload);
};

export { utilAnalytics, getServerNameFromGlobalScope };
