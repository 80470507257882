import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  VIEW_COLLECTION,
} from "../../../helper/constants";
const BackToSearch = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = props.data;
  const handleBackToSearch = () => {
    navigate(-1);
  };
  return (
    <div className="backtosearch">
      <Link
        to=""
        aria-label="PubTitle"
        className="backtosearchlink"
        state={data}
        onContextMenu={(e) => e.preventDefault()}
        onClick={() => handleBackToSearch()}
      >
        {t(VIEW_COLLECTION.BACK_SEARCH)}
      </Link>
    </div>
  );
};
export default BackToSearch;