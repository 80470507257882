import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ERROR_MSG, REV_REQUEST } from "../../../helper/constants";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import "./ReviewRequest.scss";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
const ReviewRequest = () => {
  const { t } = useTranslation();
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const [reqInfo, setReqInfo] = useState<any>([]);
  const { configData, token } = useSelector((state: any) => state.config);
  const navigate = useNavigate();
  let tokenValidate: any = "";
  if (token?.token) {
    tokenValidate = token?.token;
  }

  useEffect(() => {
    getRequestInformation();
  }, []);

  const getRequestInformation = async () => {
    try {
      const response = await fetch("/api/v1/requestor-information", {
        method: "GET",
      });
      const data = await response.json();

      if (data?.message?.isSuccess === true) {
        setReqInfo(data?.message?.result);
      } else if (data?.message?.isSuccess === false) {
        setErorState({ falg: true, msg: data?.message?.result });
        setReqInfo([]);
      } else {
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };

  const navigateToMyLibrary = () => {
    navigate("/subscriptions");
  };

  return (
    <>
      <div className="titleReviewRequest RequestorInfo">
        <h1> {t(REV_REQUEST.TITILE)}</h1>

        <div className="containerReviewRequest">
          <h2>{t(REV_REQUEST.REQUESTOR_INFO)}</h2>
          <Table className="demoTable">
          <TableHead>
          <TableRow>
          <TableCell className="cell-one">
              
                <div>{t(REV_REQUEST.NAME)}</div>
                <div>{reqInfo.name}</div>
              
              </TableCell>
              <TableCell className="cell-two">
              
                <div>{t(REV_REQUEST.EMAIL)}</div>
                <div>{reqInfo.email}</div>
             
            </TableCell>
            <TableCell className="cell-address" rowSpan={2}>
              
                <div>{t(REV_REQUEST.SHIPPING_ADD)}</div>
                <div>
                  {reqInfo.shipping_address === ""
                    ? "N/A"
                    : reqInfo.shipping_address}
                </div>
              
            </TableCell>
            </TableRow>
         
            <TableRow>
            <TableCell className="cell-one">
              
                <div>{t(REV_REQUEST.COMPANY)}</div>
                <div>{reqInfo.company}</div>
              
            </TableCell>
            <TableCell className="cell-two">
              
                <div>{t(REV_REQUEST.PHONE)}</div>
                <div>{reqInfo.phone === "" ? "N/A" : reqInfo.phone}</div>
              
            </TableCell>
          </TableRow>
            </TableHead>
          </Table>

        </div>
        <div className="RequestorInforButtons">
          <>
            <Button
              type="button"
              aria-label="Close Preferences"
              className="backtoLib"
              variant="primary"
              onClick={navigateToMyLibrary}
            >
             {t(REV_REQUEST.BACK_LIB)}
            </Button>
          </>

          <Button
            type="button"
            aria-label="Close Preferences"
            className="Exitbtn disabledbtn"
            variant="secondary"
            disabled={true}
          >
            {t(REV_REQUEST.SUBMIT_REQ)}
          </Button>
        </div>
      </div>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};

export default ReviewRequest;
