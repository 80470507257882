import React from "react";
import "./SearchResultHeader.scss";
import TotalRecordsCounter from "../TotalRecordsCounter/TotalRecordsCounter";
import ExcelExporter from "../../../common/ExcelExporter/ExcelExporter";
import { LABELKEYS_SEARCH_RESULTS } from "../../../../helper/constants";
import { useTranslation } from "react-i18next";

interface SearchResultHeaderProps {
  apiPayload?: any;
  recordsCounter: number;
}

const SearchResultHeader: React.FC<SearchResultHeaderProps> = ({
  apiPayload,
  recordsCounter,
}) => {
  const { t } = useTranslation();

  if (recordsCounter <= 0) {
    return null;
  }

  return (
    <div className="parent">
      <div className="left">
        <TotalRecordsCounter totalRecords={recordsCounter} />
      </div>
      <div className="right">
        <ExcelExporter
          buttonTitle={t(LABELKEYS_SEARCH_RESULTS.EXPORT_SEARCH_RESULT)}
          param="search-result"
          apiPayload={apiPayload}
          recordsCounter={recordsCounter}
        />
      </div>
    </div>
  );
};

export default SearchResultHeader;
