import moment from "moment";
import { RefObject, useEffect } from "react";

const useOutsideCalendarClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  setDates: (value: Array<any> | null) => void,
  validDates: boolean,
  dates: Array<any> | null
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // this if condition is used to close the calendar when user clicks outside the calendar
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
        // this nested condition is used to set the calendar input as empty when user clicks outside the calendar and theres no applied dates
        if (validDates && startDate === null && endDate === null) {
          setDates(null);
        }
        // this nested validation is to set the calendar with the apllied dates when user change the dates and dont apply changes
        //  and clicks outside the calendar
        else if (validDates && startDate && endDate) {
          const [firstselectedDate, lastSelectedDate] = Array.isArray(dates)
            ? dates
            : [0, 0];
          if (
            moment(startDate).toDate() !== moment(firstselectedDate).toDate() &&
            moment(lastSelectedDate).toDate() !== moment(endDate).toDate()
          ) {
            setDates([moment(startDate).toDate(), moment(endDate).toDate()]);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, startDate, endDate, validDates]);
};

export default useOutsideCalendarClick;
