import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/Service";
import { DEFAULT_LANG } from "../../helper/constants";

const initialState = {
    labels: '',
   
};

//loading config data


export const fetchAsyncLabels = createAsyncThunk("techpub/labels", async () => {
  try {
    const res = await callToAPI("/api/v1/config/labels?lang=" + DEFAULT_LANG.EN_US_LANG, "GET", {
      "Content-Type": "application.json",
    });
    const data = await res.json();
    return data;
  } catch (error) {
  }
});

//loading token


export const translationReducer = createSlice({
  name: "labels",
  initialState,
  extraReducers: {
    [fetchAsyncLabels.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAsyncLabels.fulfilled]: (state, { payload }) => {
      return {  labels: payload.message.result || {} };
    },

    [fetchAsyncLabels.rejected]: (state, action) => {
      state.status = "unable to fetch data";
    },
  
  },
 
});
export const configActions = translationReducer.actions;
