import React from "react";
import { Row } from "react-bootstrap";
import "./NoDataAvailable.scss";
import { useTranslation } from "react-i18next";
import { ACCESS_STATUS, ERROR_MESSAGES, URLS, RESTRICT_APPLICATION_TEXT} from "../../../helper/constants";
import { useSelector } from "react-redux";

const NoDataAvailable = () => {
const { t } = useTranslation();
    const configData = useSelector((state:any) => state.config?.configData || {});
  const { tokenValid, isLoadingToken } = useSelector(
    (state: any) => state.config
  );
  const checkStatus = tokenValid?.isSuccess;
  const toolAccess = checkStatus===true ? tokenValid?.result?.KEY_TOOL_ACCESS : '';
  const approvedStatus = ACCESS_STATUS.APPROVED;
 console.log("approved status",configData.TECHPUB_AEM_DETAILS?.AEM_HOST + URLS.REQUEST_TOOL_ACCESS_URL + configData.TECHPUB_APP_ID?.TECHPUB_TOOL_ID);
  if(toolAccess!=approvedStatus && toolAccess!=''){
    location.href= configData.TECHPUB_AEM_DETAILS?.AEM_HOST + URLS.REQUEST_TOOL_ACCESS_URL + configData.TECHPUB_APP_ID?.TECHPUB_TOOL_ID;    
 }
  return (
    <Row>
      <h2 className="no-data">
      { RESTRICT_APPLICATION_TEXT}
      </h2>
    </Row>
  );
};

export default NoDataAvailable;