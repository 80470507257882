import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestData: "",
  selectedQuicklink: "",
};

export const requestFormReducer = createSlice({
  name: "requestForm",
  initialState,
  reducers: {
    getRequestFormData: (state, { payload }) => {
      return {
        requestData: payload.requestDataObj,
        selectedQuicklink:payload.linkSelected,
        tableData:payload.tableData,
        requestToDescription: payload.requestToDescription
      };
    },
  },
});

export const RequestFormAction = requestFormReducer.actions;
