import React, { useEffect, useState } from "react";
import {
  FaDownload,
  FaMailBulk,
  FaRegFolderOpen,
  FaStar,
  FaAngleDown,
} from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import {
  A2A_INDICATOR,
  ADD_TO_FAVOURITE_LEBELS,
  CHINA_COUNTRY_CODE,
  ERROR_MSG,
  LOCATION_BREADCRUMB,
  MODAL_MESSAGE,
  RELATED_PUBLICATION,
  RELATED_PUBLICATION_BUTTON,
  REPORT_DIS,
  REQUEST_DOWNLOAD_ERROR,
  TEMP_REV,
  VIEW_COLLECTION,
  REPORT_DISCREPANCY_OPEN_LINK,
  BLANK_LINK,
  ITEM_URL,
  VIEW_INTERACTIVE_URL,
  PUBLICATION_TYPE_MANUAL,
  PUBLICATION_TYPE_BULLETIN,
  PUBLICATION_TYPE_LETER,
  PUBLICATION_TYPE_OTHERS,
  PUBLICATION_TYPE_INDEX,
  PUBLICATION_TYPE_MARKETING,
  PUBLICATION_MEDIA_TYPE_NAME,
  LICENCED,
  YES_MARK,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import "./QuickLinks.scss";
import {
  ACCESS_STATUS,
  HTTP_STATUS_CODE,
  LABELKEYS_GEOCHECK,
  URLS,
} from "../../../helper/constants";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader/Loader";
import GetEmailNotification from "./GetEmailNotification";
import ViewCollection from "../ViewCollection/ViewCollection";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import RelatedPublication from "./RelatedPublication";
import RequestDownload from "../../common/RequestDownload/RequestDownload";
import RequestDownloadModal from "../RecentlyDownload/RequestDownloadModal";
import moment from "moment";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";
import { ScrollPositionActions } from "../../../storage/reducers/scrollPositionReducer";
import { GeoCheck } from "../GeoCheck/GeoCheck";
import {
  getServerNameFromGlobalScope,
  utilAnalytics,
} from "../../common/analytics/analyticsUtil";

const QuickLinks = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPopup, setshowPopup] = useState(false);
  const [isFavouriteAdd, setisFavouriteAdd] = useState(false);
  const [errMSg, setErrMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [CloseErrorPopup, setCloseErrorPopup] = useState(false);
  const [fileStreamerData, setFileStreamerData] = useState("");
  const [loader, setLoader] = useState(false);
  const [showRelatedPub, setShowRelatedPub] = useState(false);
  const [requestDownloadClick, setrequestDownloadClick] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const [handleGeoCheckDownload, setHandleGeoCheckDownload] = useState();
  const [requestDownloadModalClick, setrequestDownloadModalClick] =
    useState(false);
  const [temporaryRevision, setTemporaryRevision] = useState(false);
  const [chinaCountry, setChinaCountry] = useState("");
  const [scrollPositions, setScrollPositions] = useState({});
  const [requestButton, setRequestButton] = useState<any>("");
  const [requestPublication, setrequestPublication] = useState<any>("");
  const [requestSubscription, setrequestSubscription] = useState<any>("");
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const closeButton = () => {
    setshowPopup(false);
  };
  const { pathname } = useLocation();
  const isViewLocation = pathname.includes("/viewcollection");
  const isTempRevisions = pathname.includes("/temprevisions");
  const isRelatedPub = pathname.includes("/relatedpub");
  const { portalPermissions, loadingPortal, user_details } = useSelector(
    (state: any) => state.config
  );
  const { tokenValid, isLoadingToken } = useSelector(
    (state: any) => state.config
  );
  const approvedStatus = ACCESS_STATUS.APPROVED;
  const checkStatus = tokenValid?.isSuccess;
  const toolAccess =
    checkStatus === true ? tokenValid?.result?.KEY_TOOL_ACCESS : "";
  const { licdata } = useSelector((state: any) => state.licagreement);
  const mediaType = props.data?._source?.pubs_media?.filter(
    (item) =>
      item?.media_type_name === "PARTIAL" || item?.media_type_name === "PDF"
  );

  const { location, resLocationStatus, isLoadingLocation } = useSelector(
    (state: any) => state.location
  );
  const requestData = useSelector(
    (state: any) => state.requestForms.requestData
  );
  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );
  const scrollPositionLevel = useSelector((state: any) => {
    return state?.scrollPositionLevel?.scrollPosition;
  });
  const scrollPositionValid = useSelector((state: any) => {
    return state?.scrollPositionLevel?.scrollValid;
  });
  const { mylibrary } = useSelector((state: any) => state.mylibrary);
  const closePubDelete = () => {
    setDeleteConfirmPopup(false);
  };

  const updateManualRequestButton = (subscriptionFlag, licenseFlag) => {
    setRequestButton("");
    setrequestPublication("");
    setrequestSubscription("");
    switch (true) {
      case subscriptionFlag === true && licenseFlag == "license":
      case subscriptionFlag === false && licenseFlag === "license":
        setRequestButton(
          RELATED_PUBLICATION_BUTTON.find(
            ({ key }) => key === "request_publication"
          )
        );
        setrequestPublication("");
        setrequestSubscription("");
        break;
      case licenseFlag != "license":
        if (portalPermissions?.result?.isInternal === true) {
          setRequestButton(
            RELATED_PUBLICATION_BUTTON.find(
              ({ key }) => key === "request_download"
            )
          );
        } else {
          if (subscriptionFlag === true) {
            setRequestButton(
              RELATED_PUBLICATION_BUTTON.find(
                ({ key }) => key === "request_download"
              )
            );
          } else {
            setrequestSubscription(
              RELATED_PUBLICATION_BUTTON.find(
                ({ key }) => key === "request_subscription"
              )
            );
          }
        }

        setrequestPublication(
          RELATED_PUBLICATION_BUTTON.find(
            ({ key }) => key === "request_publication"
          )
        );
        // setrequestSubscription("");
        break;
      case subscriptionFlag === false && licenseFlag != "license":
        // setRequestButton(
        //   RELATED_PUBLICATION_BUTTON.find(
        //     ({ key }) => key === "request_subscription"
        //   )
        // );
        setRequestButton(
          RELATED_PUBLICATION_BUTTON.find(
            ({ key }) => key === "request_publication"
          )
        );
        setrequestPublication("");
        setrequestSubscription(
          RELATED_PUBLICATION_BUTTON.find(
            ({ key }) => key === "request_subscription"
          )
        );
        break;
      // case subscriptionFlag === false && licenseFlag != "license":
      //   // setRequestButton(
      //   //   RELATED_PUBLICATION_BUTTON.find(
      //   //     ({ key }) => key === "request_subscription"
      //   //   )
      //   // );
      //   setRequestButton(
      //     RELATED_PUBLICATION_BUTTON.find(
      //       ({ key }) => key === "request_publication"
      //     )
      //   );
      //   setrequestPublication("");
      //   setrequestSubscription(
      //     RELATED_PUBLICATION_BUTTON.find(
      //       ({ key }) => key === "request_subscription"
      //     )
      //   );
      //   break;
    }
  };

  const updateCollectionRequestButton = (subscriptionFlag, licenseFlag) => {
    setRequestButton("");
    setrequestPublication("");
    setrequestSubscription("");
    switch (true) {
      case subscriptionFlag === true && licenseFlag == "license":
      case subscriptionFlag === false && licenseFlag === "license":
        // setRequestButton(
        //   RELATED_PUBLICATION_BUTTON.find(
        //     ({ key }) => key === "request_collection"
        //   )
        // );
        setRequestButton("");
        setrequestPublication("");
        setrequestSubscription("");
        break;
      case subscriptionFlag === true && licenseFlag != "license":
        setRequestButton("");
        setrequestPublication("");
        setrequestSubscription("");
        break;
      case subscriptionFlag === false && licenseFlag != "license":
        setRequestButton("");
        setrequestPublication("");
        setrequestSubscription(
          RELATED_PUBLICATION_BUTTON.find(
            ({ key }) => key === "request_subscription"
          )
        );
        break;
    }
  };

  const setCookieInformation = (pubDataJson) => {
    document.cookie =
      "publication=" + pubDataJson + ";domain=.honeywell.com;path=/;secure";
  };
  const openDiscrepnacyApp = async () => {
    const pubData = {
      Org: props?.data?._source?.org_desc,
      pub_type: props?.data?._source?.publication_type_name,
      pub_status: props?.data?._source?.publication_status,
      lic: props?.data?._source?.export_lic_description,
      vol: props?.data?._source?.publication_volume,
      export_code: props?.data?._source?.export_ctrl_code,
      pub_number: props?.data?._source?.pub_number,
      pub_id: props?.data?._source?.pub_uuid,
      ata: props?.data?._source?.ata,
      rev_no: props?.data?._source?.version_number,
      rev_date: moment(props?.data?._source?.revision_date).format("DD MMM Y"),
      type: "discrepancy",
      userName: user_details.name,
      userCompanyName: user_details.company,
      email: user_details.email,
      userUuid: mylibrary?.result?.userProfileId,
    };
    const dataResponse = await encryptApiCall(pubData);
    const pubDataJson = JSON.stringify(pubData);
    if (dataResponse != false && dataResponse != undefined) {
      setCookieInformation(dataResponse);

      props.data._source.export_lic_description == "License"
        ? window.open(configData.REPORT_DISCREPANCY_LINK, t(BLANK_LINK))
        : window.open(
            configData.REPORT_DISCREPANCY_LINK_REPORT_NONLIC,
            t(BLANK_LINK)
          );
    }
  };

  const viewItemUrl = () => {
    window.open(ITEM_URL, t(BLANK_LINK));
  };

  const encryptApiCall = async (pubData) => {
    try {
      const resp: any = await fetch("/api/v1/encrypt", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pubData),
      });
      const data = await resp.json();

      if (data.message?.isSuccess === true) {
        setJwtTokenDetail(data?.message?.jwtToken);
        //setJwtToken(data?.message?.jwtToken);
        return data?.message?.result?.data;
      } else if (data.status === 401) {
        setErrMsg("Something Went Wrong..");
        setErorState({ falg: true, msg: t(ERROR_MSG.UNATHORIZED) });
        return false;
      } else {
        setErorState({ falg: true, msg: t(ERROR_MSG.UNATHORIZED) });
        return false;
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      return false;
    }
  };

  const setJwtTokenDetail = (jwtToken) => {
    document.cookie =
      "jwtToken=" + jwtToken + ";domain=.honeywell.com;path=/;secure";
  };

  const ClickHandler = (downlodClick) => {
    setrequestDownloadClick(downlodClick);
  };

  const requestDownloadModalShow = (reqDownloadModal) => {
    setrequestDownloadModalClick(true);
  };
  const onClickRequestForm = (valueSelected) => {
    if (valueSelected === "request_publication") {
      requestPubAnalytics();
    }
    dispatch(
      RequestFormAction.getRequestFormData({
        requestDataObj: props?.data?._source,
        linkSelected: valueSelected,
      })
    );
  };

  useEffect(() => {
    props?.data?._source?.publication_category_name !== null &&
    props?.data?._source?.publication_category_name?.[0]
      ?.publication_category !== undefined &&
    props?.data?._source?.related_publications !== null &&
    props?.data?._source?.related_publications !== undefined &&
    props?.data?._source?.related_publications?.length > 0 &&
    (props?.data?._source?.publication_category_name?.[0]?.publication_category?.toLowerCase() ===
      PUBLICATION_TYPE_MANUAL ||
      PUBLICATION_TYPE_BULLETIN ||
      PUBLICATION_TYPE_LETER ||
      PUBLICATION_TYPE_OTHERS ||
      PUBLICATION_TYPE_INDEX ||
      (PUBLICATION_TYPE_MARKETING &&
        props.data._source.related_publications.length >= 1))
      ? setShowRelatedPub(true)
      : setShowRelatedPub(false);

    if (
      props?.data?._source?.publication_category_name !== null &&
      props?.data?._source?.publication_category_name?.[0]?.publication_category?.toLowerCase() ==
        "manual" &&
      props?.data?._source?.publication_type_name?.toLowerCase() !==
        "collection"
    ) {
      updateManualRequestButton(
        props.parentSubscriptionFlag !== undefined
          ? props.parentSubscriptionFlag
          : props?.data?._source?.is_subscription_flag,
        props?.data?._source?.export_lic_description.toLowerCase()
      );
    }

    if (
      props?.data?._source?.export_lic_description?.toLowerCase() ===
        "license" &&
      props?.data?._source?.publication_category_name[0]?.publication_category.toLowerCase() !==
        "manual"
    ) {
      setRequestButton("");
      setrequestPublication(
        RELATED_PUBLICATION_BUTTON.find(
          ({ key }) => key === "request_publication"
        )
      );
      setrequestSubscription("");
    }

    if (
      props?.data?._source?.publication_category_name !== null &&
      props?.data?._source?.publication_category_name?.[0]
        ?.publication_category !== undefined &&
      props?.data?._source?.export_lic_description?.toLowerCase() !==
        "license" &&
      props?.data?._source?.publication_category_name?.[0]?.publication_category.toLowerCase() !==
        "manual"
    ) {
      setRequestButton(
        RELATED_PUBLICATION_BUTTON.find(({ key }) => key === "request_download")
      );
      setrequestPublication(
        RELATED_PUBLICATION_BUTTON.find(
          ({ key }) => key === "request_publication"
        )
      );
      setrequestSubscription("");
    }

    if (
      props?.data?._source?.publication_category_name != null &&
      props?.data?._source?.publication_category_name?.[0]?.publication_category.toLowerCase() ==
        "manual" &&
      props?.data?._source?.publication_type_name.toLowerCase() == "collection"
    ) {
      updateCollectionRequestButton(
        props.parentSubscriptionFlag !== undefined
          ? props.parentSubscriptionFlag
          : props?.data?._source?.is_subscription_flag,
        props?.data?._source?.export_lic_description.toLowerCase()
      );
    }
    props?.data?._source?.publication_category_name !== null &&
    props?.data?._source?.publication_category_name?.[0]?.publication_category.toLowerCase() ==
      "manual" &&
    props.data._source.temp_rev_ids !== null &&
    props?.data._source?.temp_rev_ids?.length >= 1
      ? setTemporaryRevision(true)
      : setTemporaryRevision(false);
  });

  const addToFavouriteShow = async () => {
    try {
      const response = await fetch("/api/v1/favorite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: props.data._source.pub_uuid,
          isTopFavourite: false,
        }),
      });

      if (HTTP_STATUS_CODE.STATUS_200 === response.status) {
        setisFavouriteAdd(true);
        setshowPopup(true);
        props.data._source.is_favourite_flag = true;
      } else if (response.status === 401) {
        setErrMsg("Something Went Wrong..");
        setErorState({ falg: true, msg: t(ERROR_MSG.UNATHORIZED) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const removeToFavourite = () => {
    setshowPopup(true);
    setisFavouriteAdd(false);
  };

  const confirmRemove = async () => {
    setDisabled(true);
    try {
      setLoader(true);
      const response = await fetch(`/api/v1/favorite/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: props.data._source.pub_uuid,
        }),
      });
      if (
        HTTP_STATUS_CODE.STATUS_200 === response.status ||
        HTTP_STATUS_CODE.STATUS_204 === response.status
      ) {
        setLoader(false);
        setisFavouriteAdd(true);
        setshowPopup(false);
        setDisabled(false);
        setDeleteConfirmPopup(true);
        props.data._source.is_favourite_flag = false;
      } else {
        setErrMsg("Something Went Wrong..");
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const onCloseModal = () => {
    setrequestDownloadModalClick(false);
  };
  const removeFavourite = () => {
    confirmRemove();
  };
  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };
  const handleScrollPosition = () => {
    const scrollPos = { [pathname]: window.scrollY };
    const isScrollValid = { [pathname]: true };
    dispatch(
      ScrollPositionActions?.getScrollPosition({
        scrollPosition: { ...scrollPositionLevel, ...scrollPos },
        scrollValid: { ...scrollPositionValid, ...isScrollValid },
      })
    );
  };
  const handleGeoCheck = (flag) => {
    setHandleGeoCheckDownload(flag);
  };
  const onCloseModalGeoCheck = () => {
    setrequestDownloadClick(false);
    setrequestDownloadModalClick(false);
  };
  const checkIfGeoCheckCompleted = () => {
    return (
      tokenValid &&
      isLoadingToken === false &&
      isLoadingLocation === false &&
      checkStatus === true &&
      toolAccess != "" &&
      toolAccess == approvedStatus &&
      resLocationStatus === 204 &&
      licdata?.licdata !== 204 &&
      (props?.data?._source["2a2a_indicator"] === YES_MARK ||
        props?.data?._source?.export_lic_description === LICENCED)
    );
  };

  const quickLinksSendAnalytics = (path, linkName) => {
    const { pub_uuid, title, publication_type_name } = props?.data?._source;
    const servername = getServerNameFromGlobalScope();
    const { protocol, host, hostname } = window.location;
    const onlyHostName = hostname
      .split(".")
      .find((item) => item.includes("aerotechpubs") && item);
    utilAnalytics(
      true,
      {
        eVar5: `${onlyHostName}:${path}${props?.data?._source?.pub_uuid}`,
        pageName: `${onlyHostName}:${path}${props?.data?._source?.pub_uuid}`,
        eVar10: `${protocol}//` + host + path + props?.data?._source?.pub_uuid,
        eVar114: `${pub_uuid} || ${title} || ${publication_type_name}`,
        linkName,
      },
      path
    );
  };

  const requestPubAnalytics = () => {
    const { pub_uuid, title, publication_type_name } = props?.data?._source;
    const onlyHostName = window.location.hostname
      .split(".")
      .find((item) => item.includes("aerotechpubs") && item);
    utilAnalytics(
      false,
      {
        eVar5: `${onlyHostName}:/subscription_request/${pub_uuid}`,
        pageName: `${onlyHostName}:/subscription_request/${pub_uuid}`,
        eVar114: `${pub_uuid} || ${title} || ${publication_type_name}`,
        linkType: "o",
        linkName: "Request Publication",
      },
      "/subscription_request"
    );
  };

  return (
    <>
      {requestDownloadModalClick && checkIfGeoCheckCompleted() ? (
        <GeoCheck
          handleGeoCheck={handleGeoCheck}
          onCloseModalGeoCheck={onCloseModalGeoCheck}
        />
      ) : (
        requestDownloadModalClick && (
          <RequestDownloadModal
            pubNumber={props?.type == "TR" ? props?.data?._source?.pub_uuid : 0}
            tempRevId={props?.type == "TR" ? props?.data?._source?.Id : 0}
            stampType={
              props?.data?._source?.stamp_type_id
                ? props?.data?._source?.stamp_type_id
                : 0
            }
            ldm={props?.data?._source?.limited_distribution_number}
            pubsMedia={props?.data?._source?.trMedia}
            title=""
            a2a2={props?.data?._source["2a2a_indicator"]}
            show={true}
            onCloseModal={onCloseModal}
            pubId={props?.data?._source?.pub_uuid}
            msg={t(MODAL_MESSAGE.TEMP_REVISION_MSG)}
            tempRevData={props?.data?._source}
            isLocationID={props?.type == "TR" ? true : false}
          />
        )
      )}
      {props.type == "TR" ? (
        props?.data?._source?.trMedia?.length > 0 &&
        props?.data?._source?.trMedia?.find(
          (item) =>
            item?.media_type_name?.toLowerCase() === PUBLICATION_MEDIA_TYPE_NAME
        ) && (
          <div className="rightpanel">
            <button
              aria-label="dialog Preferences"
              type="button"
              className="downloadTR favlinks"
              onClick={() => {
                requestDownloadModalShow(true);
              }}
            >
              {t(TEMP_REV.DOWNLOAD_TR)}
            </button>
          </div>
        )
      ) : (
        <>
          {props.data._source.is_favourite_flag == true ? (
            <button
              aria-label="dialog Preferences"
              type="button"
              className="removefav favlinks"
              onClick={removeToFavourite}
            >
              {t(ADD_TO_FAVOURITE_LEBELS.REMOVEFAVOURITE_TITLE)}
            </button>
          ) : (
            <button
              aria-label="dialog Preferences"
              type="button"
              className="addfav favlinks"
              onClick={addToFavouriteShow}
            >
              {t(ADD_TO_FAVOURITE_LEBELS.TITLE)}
            </button>
          )}
          <Modal
            className="favPopup addremovePopup licagreementpopup Popupwrapper"
            aria-label="dialog Preferences"
            show={showPopup}
          >
            <div className="modalbodycontentlic modalbodycontentPopups  modalbodycontent">
              {loader && <Loader />}
              <div className="Modal-body-liccontent modal-body">
                <div className="popupcontent">
                  {isFavouriteAdd ? (
                    <p className="popupmsg popupmessageSuccess">
                      {t(ADD_TO_FAVOURITE_LEBELS.ADDTOFAV_MSG)}
                    </p>
                  ) : (
                    <p className="popupmsg">
                      {t(ADD_TO_FAVOURITE_LEBELS.REMOVETOFAV_MSG)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Modal.Footer>
              <div className="footercontainerlic Popupfooter footercontainer">
                <div className="geocheckbtns">
                  {isFavouriteAdd ? (
                    <>
                      <Link
                        to="/favorites"
                        className="myfavlink managefavlink cancelbtn"
                      >
                        {t(LOCATION_BREADCRUMB.MY_FAVORITES)}
                      </Link>
                    </>
                  ) : (
                    <Button
                      type="button"
                      aria-label="Close Preferences"
                      className="cancelbtn active"
                      variant="secondary"
                      onClick={removeFavourite}
                    >
                      {t(ADD_TO_FAVOURITE_LEBELS.REMOVE)}
                    </Button>
                  )}

                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="submitbtn active btn btn-primary"
                    variant="primary"
                    disabled={disabled}
                    onClick={closeButton}
                  >
                    {t(ADD_TO_FAVOURITE_LEBELS.CLOSE)}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            className="favPopup addremovePopup licagreementpopup Popupwrapper"
            aria-label="dialog Preferences"
            show={deleteConfirmPopup}
          >
            <div className="modalbodycontentlic modalbodycontentPopups  modalbodycontent">
              <Modal.Body className="Modal-body-liccontent">
                <p>{t(ADD_TO_FAVOURITE_LEBELS.PUB_DELETE_MSG)}</p>
              </Modal.Body>
              <Modal.Footer>
                <div className="footercontainerlic Popupfooter footercontainer">
                  <div className="geocheckbtns">
                    <div className="rightbtn">
                      <Button
                        type="button"
                        aria-label="Close Preferences"
                        className="submitbtn active btn btn-primary"
                        variant="secondary"
                        onClick={closePubDelete}
                      >
                        {t(ADD_TO_FAVOURITE_LEBELS.CLOSE)}
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </div>
          </Modal>
          {/* error Popup*/}
          {requestDownloadClick && checkIfGeoCheckCompleted() ? (
            <GeoCheck
              handleGeoCheck={handleGeoCheck}
              onCloseModalGeoCheck={onCloseModalGeoCheck}
            />
          ) : (
            requestDownloadClick && (
              <RequestDownload
                ldm={props?.data?._source?.limited_distribution_number}
                a2a_indicator={props?.data?._source[A2A_INDICATOR]}
                parentClickHandler={ClickHandler}
                handleGeoCheckDownload={handleGeoCheckDownload}
                pubsMedia={props?.data?._source?.pubs_media}
                pubNumber={props?.data?._source?.pub_uuid}
                stampType={props?.data?._source?.stamp_type_id}
                source={props?.data?._source}
                pubId={props?.data?._source?.pub_uuid}
                tempRevData={props?.data?._source}
              />
            )
          )}

          {/* Request Download */}

          {requestButton && requestButton.key !== "request_download" ? (
            <Link
              aria-label="Request Form"
              className={`${requestButton.class} favlinks`}
              onClick={() => onClickRequestForm(requestButton.key)}
              onContextMenu={(e) => e.preventDefault()}
              // onContextMenu={(e) => e.preventDefault()}
              to={"/subscription_request/" + props?.data?._source?.pub_uuid}
            >
              {requestButton.label}
            </Link>
          ) : (
            requestButton &&
            mediaType !== undefined &&
            mediaType.length > 0 && (
              <button
                aria-label="Request Download"
                className={`favlinks ${requestButton.class}`}
                onContextMenu={(e) => e.preventDefault()}
                onClick={() => ClickHandler(true)}
              >
                {requestButton.label}
              </button>
            )
          )}
          {requestPublication !== "" &&
            requestPublication.key == "request_publication" && (
              <Link
                aria-label="Request Form"
                className={`${requestPublication.class} favlinks`}
                onClick={() => {
                  onClickRequestForm("request_publication");
                }}
                onContextMenu={(e) => e.preventDefault()}
                // onContextMenu={(e) => e.preventDefault()}
                to={"/subscription_request/" + props?.data?._source?.pub_uuid}
              >
                {requestPublication.label}
              </Link>
            )}

          {requestSubscription != "" &&
            requestSubscription.key == "request_subscription" && (
              <Link
                aria-label="Request Form"
                className={`${requestSubscription.class} favlinks`}
                onClick={() => {
                  onClickRequestForm("request_subscription");
                  const { pub_uuid, title, publication_type_name } =
                    props?.data?._source;
                  const { protocol, host, hostname } = window?.location;
                  const onlyHostName = hostname
                    .split(".")
                    .find((item) => item.includes("aerotechpubs") && item);
                  utilAnalytics(
                    true,
                    {
                      eVar5: `${onlyHostName}:/subscription_request/${pub_uuid}`,
                      pageName: `${onlyHostName}:/subscription_request/${pub_uuid}`,
                      eVar10:
                        `${protocol}//` +
                        host +
                        "/subscription_request/" +
                        props?.data?._source?.pub_uuid,
                      eVar114: `${pub_uuid} || ${title} || ${publication_type_name}`,
                      linkName: "Request Subscription",
                    },
                    "/subscription_request"
                  );
                }}
                onContextMenu={(e) => e.preventDefault()}
                // onContextMenu={(e) => e.preventDefault()}
                to={"/subscription_request/" + props?.data?._source?.pub_uuid}
              >
                {requestSubscription.label}
              </Link>
            )}
          {props.data._source.publication_type_name == "COLLECTION" &&
          !isViewLocation &&
          props.data._source.publication_type_name !== null ? (
            <>
              <Link
                aria-label="View Collection"
                className="viewcollection favlinks"
                onContextMenu={(e) => e.preventDefault()}
                to={"/viewcollection/" + props.data._source.pub_uuid}
                state={{
                  searchVal: props.seacrhVal,
                  datares: props.data,
                  id: props.id,
                  seacrhResults: props.searchResults,
                }}
                onClick={() => {
                  handleScrollPosition();
                  quickLinksSendAnalytics(
                    "/viewcollection/",
                    "View Collections"
                  );
                }}
              >
                {t(VIEW_COLLECTION.VIEW_COLLECTION_TITLE)}
              </Link>
            </>
          ) : (
            " "
          )}
          {temporaryRevision && !isTempRevisions && (
            <>
              <Link
                aria-label="Temporary Revision"
                className="Temprevision favlinks"
                onContextMenu={(e) => e.preventDefault()}
                to={"/temprevisions/" + props.data._source.pub_uuid}
                state={{
                  revids: props.data._source.temp_rev_ids,
                  searchVal: props.seacrhVal,
                  datares: props.searchResults,
                  id: props.id,
                  pubid: props.data._source.pub_uuid,
                }}
                onClick={() => {
                  handleScrollPosition();
                  quickLinksSendAnalytics(
                    "/temprevisions/",
                    "Temporary Revisions Path"
                  );
                }}
              >
                {t(TEMP_REV.TITLE)}
              </Link>
            </>
          )}
          {props?.data?._source?.publication_category_name !== null ? (
            props?.data?._source?.publication_category_name?.[0]
              ?.publication_category === "Manual" ? (
              <GetEmailNotification data={props}></GetEmailNotification>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {/* Related Publication */}
          {showRelatedPub && !isRelatedPub && (
            <Link
              aria-label="Related Publication"
              className="RelatedPub favlinks"
              onContextMenu={(e) => e.preventDefault()}
              to={"/relatedpub/" + props.data._source.pub_uuid}
              state={{
                datares: props.seacrhVal,
                id: props.id,
                relatedseacrhResults: props.searchResults,
                related_pub: props.data._source.related_publications,
              }}
              onClick={() => {
                handleScrollPosition();
                quickLinksSendAnalytics(
                  "/relatedpub/",
                  "Related Publications Path"
                );
              }}
            >
              {t(RELATED_PUBLICATION.TITLE)}
            </Link>
          )}
          {/*Reoport Discrepancy*/}
          <Link
            aria-label="Reoport Discrepancy"
            className="reportdiscripency favlinks"
            onClick={openDiscrepnacyApp}
            onContextMenu={(e) => e.preventDefault()}
            to={pathname}
          >
            {t(REPORT_DIS.REPORT_TITLE)}
          </Link>

          {/*view interactive manula*/}
          {props?.data?._source?.publication_category_name !== null &&
            props?.data?._source?.publication_category_name?.[0]?.publication_category?.toLowerCase() ===
              "manual" &&
            props?.data?._source?.ietmFlag?.toLowerCase() == "y" && (
              <Link
                aria-label="view interactive url"
                className="interactivemanual favlinks"
                onClick={viewItemUrl}
                onContextMenu={(e) => e.preventDefault()}
                to=""
              >
                {t(VIEW_INTERACTIVE_URL.INTERACTIVE_TITLE)}
              </Link>
            )}
        </>
      )}
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};

export default QuickLinks;
