import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COMMON_LABELS } from "../../../../helper/constants";
import "./TotalRecordsCounter.scss";

type Props = {
  totalRecords: number;
};

const TotalRecordsCounter: React.FC<Props> = ({ totalRecords }) => {
  const [viewResult, setViewResult] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (totalRecords > 0) {
      const itemText =
        totalRecords === 1
          ? t(COMMON_LABELS.LABEL_ITEM)
          : t(COMMON_LABELS.LABEL_ITEMS);
      setViewResult(`${totalRecords} ${itemText}`);
    }
  }, [totalRecords]);

  return (
    <div className="TotalRecordsCount">
      {viewResult !== "" && <p>{viewResult}</p>}
    </div>
  );
};

export default TotalRecordsCounter;
