import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { headerFooterDataReducer } from "../storage/reducers/HeaderFooterDataReducer";
import { configReducer } from "../storage/reducers/configReducer";
import { contractDetailsReducer } from "../storage/reducers/contractDetailsReducer";
import { translationReducer } from "../storage/reducers/translationReducer";
import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import { configApi } from "../services/configurations";
import thunk from "redux-thunk";
import { locationReducer } from "../storage/reducers/locationReducer";
import { licAgreementReducer } from "../storage/reducers/licAgreementReducer";
import { myLibraryReducer } from "../storage/reducers/myLibraryReducer";
import { facetFilterReducer } from "../storage/reducers/facetFilterReducer";
import { deepfacetFilterReducer } from "../storage/reducers/deepFilterFacets";
import { requestFormReducer } from "../storage/reducers/requestFormReducer";
import { scrollPositionReducer } from "../storage/reducers/scrollPositionReducer";
import { scrollPageReducer } from "../storage/reducers/scrollPageReducer";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  headerFooterData: headerFooterDataReducer.reducer,
  [configApi.reducerPath]: configApi.reducer,
  config: configReducer.reducer,
  contracts: contractDetailsReducer.reducer,
  labels: translationReducer.reducer,
  location: locationReducer.reducer,
  licagreement: licAgreementReducer.reducer,
  mylibrary: myLibraryReducer.reducer,
  filteredFacets: facetFilterReducer.reducer,
  deepFacetLevels: deepfacetFilterReducer.reducer,
  requestForms: requestFormReducer.reducer,
  scrollPositionLevel: scrollPositionReducer.reducer,
  scrollPagePositionLevel: scrollPageReducer.reducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
