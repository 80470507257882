import React, { useEffect, useState } from "react";
import {
  FaDownload,
  FaMailBulk,
  FaRegFolderOpen,
  FaStar,
  FaAngleDown,
} from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import {
  A2A_INDICATOR,
  ADD_TO_FAVOURITE_LEBELS,
  CHINA_COUNTRY_CODE,
  ERROR_MSG,
  LOCATION_BREADCRUMB,
  RELATED_PUBLICATION,
  RELATED_PUBLICATION_BUTTON,
  REPORT_DIS,
  REQUEST_DOWNLOAD_ERROR,
  REQUEST_DOWNLOAD_MESSAGE,
  TEMP_REV,
  VIEW_COLLECTION,
  PARTIAL_PUBLICATION_VALUES,
  DOWNLOAD_OPTIONS,
  PARTIAL_DOWNLOAD_CONTENT,
  NULL_CHECK,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FileStreamerModal from "./FileStreamerModal";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";
import { useLocation, useNavigate } from "react-router-dom";
import "./RequestDownload.scss";
import { utilAnalytics } from "../analytics/analyticsUtil";

const RequestDownload = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showPopup, setshowPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [requestViewSFDC, setRequestViewSFDC] = useState("");
  const [fileStreamer, setFileStreamer] = useState(false);
  const [saveApiStatus, setSaveApiStatus] = useState("in progress");
  const [fetchApiFailed, setFetchApiFailed] = useState(false);
  const [encryptApiFailed, setEncryptApiFailed] = useState(false);

  const [pdfToken, setPdfToken] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [isError, setIsError] = useState(false);
  const [partialDownloadMedia, setPartialDownloadMedia] = useState("");
  const [downloadMediaFlag, setDownloadMediaFlag] = useState(false);
  const [disableButtonFlag, setDisableButtonFlag] = useState(true);
  const [requestToView, setRequestView] = useState("");
  const [isRemarks, setRemarks] = useState(true);
  const [isMaskedCountryUser, setIsMaskedCountryUser] = useState(false);

  const { mylibrary } = useSelector((state: any) => state.mylibrary);
  const { portalPermissions, loadingPortal, user_details } = useSelector(
    (state: any) => state.config
  );

  const { location, resLocationStatus, isLoadingLocation } = useSelector(
    (state: any) => state.location
  );
  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );
  const checkGeoCheckUpFlow = (remarks, showError, isMaskedCountry) => {
    setRemarks(remarks);
    setErrorPopup(showError);
    setIsMaskedCountryUser(isMaskedCountry);
  };

  const checkCountryFlow = (country, LDM_Result) => {
    const locationCountry =
      props?.handleGeoCheckDownload === undefined
        ? location?.result
        : props?.handleGeoCheckDownload;
    country === 1
      ? locationCountry?.countryCode == CHINA_COUNTRY_CODE
        ? (setRequestView(`${t(REQUEST_DOWNLOAD_MESSAGE.NON_LICENCED_VIEW)}`),
          checkGeoCheckUpFlow(false, true, true))
        : checkLDI(LDM_Result)
      : (setRequestView(`${t(REQUEST_DOWNLOAD_MESSAGE.NON_LICENCED_VIEW)}`),
        checkGeoCheckUpFlow(false, true, true));
  };
  const checkLDI = (LDM_Result) => {
    if (LDM_Result === 1) {
      checkGeoCheckUpFlow(true, true, false);
      setRequestViewSFDC(
        `${t(REQUEST_DOWNLOAD_MESSAGE.INTERNAL_MANUAL_VIEW_SFDC)}`
      );
      setRequestView(`${t(REQUEST_DOWNLOAD_MESSAGE.LIMITED_IN_DISTRIBUTION)}`);
    } else if (LDM_Result === 0) {
      checkGeoCheckUpFlow(true, false, false);
    } else if (LDM_Result === 2) {
      if (portalPermissions.result.isInternal === true) {
        checkGeoCheckUpFlow(true, false, false);
      } else {
        setRequestViewSFDC(
          `${t(REQUEST_DOWNLOAD_MESSAGE.EXTERNAL_USER_VIEW_SFDC)}`
        );
        setRequestView(
          `${t(REQUEST_DOWNLOAD_MESSAGE.LIMITED_IN_DISTRIBUTION)}`
        );
        checkGeoCheckUpFlow(true, true, false);
      }
    }
  };
  const errorPopupLdmZero: any = (LDM_Result, A2A_indicators) => {
    switch (true) {
      case A2A_indicators === "N" || A2A_indicators == "0":
        checkLDI(LDM_Result);
        break;
      case A2A_indicators === "Y":
        checkCountryFlow(portalPermissions?.result?.maskedCountry, LDM_Result);
        break;
    }
  };

  const closeErrorPopup = () => {
    setErrorPopup(false);
    setFileStreamer(false);
    props.parentClickHandler(false);
    props.closeFileStreamer();
  };
  const OnClickStartReqBtn = () => {
    navigate("/subscription_request/" + props?.pubId);
    dispatch(
      RequestFormAction.getRequestFormData({
        requestDataObj: props.source ? props.source : [],
        linkSelected: "request_download",

        tableData: props?.tempRevData ? props?.tempRevData : [],
        requestToDescription: requestViewSFDC,
      })
    );
  };
  const ClickHandler = () => {
    errorPopupLdmZero(props?.ldm, props?.a2a_indicator);
  };
  const downloadHandleChange = (e) => {
    setPartialDownloadMedia(e.target.value);

    if (e.target.value === "partialPublication") {
      setDisableButtonFlag(false);
    } else if (e.target.value === "fullPublication") {
      setDisableButtonFlag(false);
    }
  };

  useEffect(() => {
    ClickHandler();
    partialDownloadModal();
    if (fileStreamer) {
      analyticsDownloadPublication();
    }
  }, [fileStreamer]);

  // analytics
  const analyticsDownloadPublication = () => {
    let eVar114Value = `${props?.pubId} || ${props?.source?.title} || ${props?.source?.document_type}`;
    let linkNameValue = "Technical Publications Download";

    if (pathname.includes("/temprevisions")) {
      console.log("props temprevisions", props);
      eVar114Value = `${props?.pubId} || ${props?.tempRevData?.title} || ${props?.tempRevData?.publication_type_name}`;
      linkNameValue = "Download TR";
    }
    utilAnalytics(
      true,
      {
        eVar114: eVar114Value,
        linkType: "d",
        linkName: linkNameValue,
      },
      pathname
    );
  };

  const partialDownloadModal = () => {
    if (props.pubsMedia !== undefined) {
      props.pubsMedia.forEach((item) => {
        if (
          item.media_type_name === "PDF" ||
          item.media_type_name === "PARTIAL"
        ) {
          setshowPopup(false);
          setDownloadMediaFlag(false);
          setFileStreamer(true);
        }
      });
      const mediaTypeFilter = props.pubsMedia.filter(
        (item) =>
          item.media_type_name === "PDF" || item.media_type_name === "PARTIAL"
      );
      const mediaTypeArray: string[] = [];
      const mediaTypeItems = mediaTypeFilter.map((item, i) => {
        const mediaTypeNames = item.media_type_name;
        const mediaTypePartial = mediaTypeNames;
        mediaTypeArray.push(mediaTypePartial);
        if (
          mediaTypeArray.includes("PDF") &&
          mediaTypeArray.includes("PARTIAL")
        ) {
          setDownloadMediaFlag(true);
          setshowPopup(true);
        }
      });

      /**/
    }
  };

  const getMediaType = (partialDownloadMedia) => {
    if (props.pubsMedia !== undefined) {
      if (partialDownloadMedia === "partialPublication") {
        const mediaType = props?.pubsMedia.find(
          (item) => item?.media_type_name === "PARTIAL"
        );

        return mediaType === undefined ? null : mediaType?.media_type_uuid;
      } else if (partialDownloadMedia === "fullPublication") {
        const mediaType = props?.pubsMedia.find(
          (item) => item?.media_type_name === "PDF"
        );

        return mediaType === undefined ? null : mediaType?.media_type_uuid;
      } else {
        const mediaType = props?.pubsMedia.find(
          (item) =>
            item?.media_type_name === "PARTIAL" ||
            item?.media_type_name === "PDF"
        );
        return mediaType === undefined ? null : mediaType?.media_type_uuid;
      }
    } else return null;
  };

  const closeButtonChange = () => {
    setshowPopup(false);
    setFileStreamer(false);
    props.parentClickHandler(false);
  };
  const downloadButtonChange = () => {
    if (partialDownloadMedia === "partialPublication") {
      setshowPopup(false);
      setDownloadMediaFlag(false);
      setFileStreamer(true);
    }
    if (partialDownloadMedia === "fullPublication") {
      setshowPopup(false);
      setDownloadMediaFlag(false);
      setFileStreamer(true);
    }
  };

  const getMediaId = (partialDownloadMedia) => {
    if (props.pubsMedia !== undefined) {
      if (partialDownloadMedia === "partialPublication") {
        const mediaType = props?.pubsMedia.find(
          (item) => item?.media_type_name === "PARTIAL"
        );

        return mediaType === undefined
          ? null
          : mediaType?.mediaid
          ? parseInt(mediaType?.mediaid)
          : null;
      } else if (partialDownloadMedia === "fullPublication") {
        const mediaType = props?.pubsMedia.find(
          (item) => item?.media_type_name === "PDF"
        );

        return mediaType === undefined
          ? null
          : mediaType?.mediaid
          ? parseInt(mediaType?.mediaid)
          : null;
      } else {
        const mediaType = props?.pubsMedia.find(
          (item) =>
            item?.media_type_name === "PDF" ||
            item?.media_type_name === "PARTIAL"
        );
        return mediaType === undefined
          ? null
          : mediaType?.mediaid
          ? parseInt(mediaType?.mediaid)
          : null;
      }
    } else return null;
  };

  const getTempRevsionId = () => {
    if (props.tempRevId !== undefined) {
      return parseInt(props.tempRevId);
    } else return null;
  };

  const payload: any = {
    tempRevId: getTempRevsionId(),
    publicationId: props.pubNumber,
    userProfile: mylibrary?.result?.userProfileId
      ? mylibrary?.result?.userProfileId
      : null,
    stampType: props?.stampType ? props?.stampType : 0,
    companyName:
      user_details?.company && user_details?.company != ""
        ? user_details?.company
        : user_details?.accountName
        ? user_details?.accountName
        : "",
    mediaType: getMediaType(partialDownloadMedia),
    locationId: props?.isLocationID
      ? null
      : props?.locationId
      ? props?.locationId
      : getMediaId(partialDownloadMedia),
    type: "watermarking",
  };

  const callDownloadTransferApi = async () => {
    try {
      const response = await fetch(`/api/v1/encrypt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (
        data?.message?.isSuccess === true &&
        data?.message?.result !== undefined
      ) {
        const encryptResponse = data?.message?.result?.data;
        setCookieInformation(data?.message?.jwtToken);
        fetchDocumentApiCall(encryptResponse);
        setIsError(false);
        setFetchApiFailed(false);
        setEncryptApiFailed(false);
      } else if (
        data?.message?.isSuccess === true &&
        data?.message?.result === undefined
      ) {
      } else if (data?.message?.isSuccess === false) {
        setIsError(true);
      } else {
      }
    } catch (error) {
      setIsError(true);
      setFetchApiFailed(false);
      setEncryptApiFailed(true);
    }
  };

  const svgIconDisclaimer = () => {
    return (
      <div className="cls-related-download-disclaimer">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.18" cx="24" cy="24" r="24" fill="#C2510A" />
          <g clip-path="url(#clip0_61872_138569)">
            <path
              d="M24.0001 37.3334C16.6361 37.3334 10.6667 31.364 10.6667 24C10.6667 16.636 16.6361 10.6667 24.0001 10.6667C31.3641 10.6667 37.3334 16.636 37.3334 24C37.3334 31.364 31.3641 37.3334 24.0001 37.3334ZM24.0001 34.6667C26.8291 34.6667 29.5422 33.5429 31.5426 31.5425C33.5429 29.5421 34.6667 26.829 34.6667 24C34.6667 21.171 33.5429 18.4579 31.5426 16.4575C29.5422 14.4572 26.8291 13.3334 24.0001 13.3334C21.1711 13.3334 18.458 14.4572 16.4576 16.4575C14.4572 18.4579 13.3334 21.171 13.3334 24C13.3334 26.829 14.4572 29.5421 16.4576 31.5425C18.458 33.5429 21.1711 34.6667 24.0001 34.6667ZM22.6667 28H25.3334V30.6667H22.6667V28ZM22.6667 17.3334H25.3334V25.3334H22.6667V17.3334Z"
              fill="#C2510A"
            />
          </g>
          <defs>
            <clipPath id="clip0_61872_138569">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(8 8)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  };

  const svgIconPartialMediaDownload = () => {
    return (
      <div className="cls-partial-download">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.18" cx="24" cy="24" r="24" fill="#A0A0A0" />
          <g clip-path="url(#clip0_63212_3028)">
            <path
              d="M12 33.3334H36V36H12V33.3334ZM25.3333 25.5627L33.428 17.4667L35.3133 19.352L24 30.6667L12.6867 19.3534L14.572 17.4667L22.6667 25.56V10.6667H25.3333V25.5627Z"
              fill="#A0A0A0"
            />
          </g>
          <defs>
            <clipPath id="clip0_63212_3028">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(8 8)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  };

  const svgIconClose = () => {
    return (
      <div className="cls-request-download-icon" onClick={closeErrorPopup}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_61872_138568)">
            <path
              d="M16 14.1146L22.6 7.51465L24.4853 9.39998L17.8853 16L24.4853 22.6L22.6 24.4853L16 17.8853L9.39998 24.4853L7.51465 22.6L14.1146 16L7.51465 9.39998L9.39998 7.51465L16 14.1146Z"
              fill="#303030"
            />
          </g>
          <defs>
            <clipPath id="clip0_61872_138568">
              <rect width="28" height="28" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  };
  const setCookieInformation = (val) => {
    document.cookie =
      "jwtToken=" + val + ";domain=.honeywell.com;path=/;secure";
  };
  const fetchDocumentApiCall = async (payload) => {
    try {
      const response = await fetch(`/api/v1/download/fetch-document`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: payload }),
      });
      const data = await response.json();

      if (
        data?.message?.isSuccess === true &&
        data?.message?.result !== undefined
      ) {
        const token = data?.message?.result?.message?.result?.token;
        const jwtToken = data?.message?.jwtToken;
        setPdfToken(token);
        setJwtToken(jwtToken);
        setIsError(false);
        setFetchApiFailed(false);
      } else if (
        data?.message?.isSuccess === true &&
        data?.message?.result === undefined
      ) {
      } else if (data?.message?.isSuccess === false) {
        setIsError(true);
        setFetchApiFailed(true);
      } else {
      }
    } catch (error) {
      setIsError(true);
      //setErrorPopup(true);
      setPdfToken("");
      setJwtToken("");
      setFetchApiFailed(true);
    }
  };

  return (
    <>
      {errorPopup ? (
        <Modal
          className="cls-relatedModalPopUp addremovePopup licagreementpopup Popupwrapper"
          aria-label="dialog Preferences"
          show={errorPopup}
        >
          <div className="modalbodycontentlic modalbodycontentPopups  modalbodycontent">
            <Modal.Body className="Modal-body-liccontent quicklinkserrorPopup">
              <div className="cls-related-download-content">
                <div className="cls-icondisclaimer">{svgIconDisclaimer()}</div>
                <div className="cls-reqpubcontainer">
                  <div className="cls-iconclose">{svgIconClose()}</div>
                  <div className="cls-reqpubcont">
                    <div className="cls-request-download-text">
                      {t(REQUEST_DOWNLOAD_MESSAGE.HEADLINE)}
                    </div>
                    <div className="cls-related-downoad-message">
                      {requestToView}
                    </div>
                  </div>
                  {isRemarks &&
                    (props?.source?.remarks?.length > 0 ||
                      props?.tempRevData?.remarks?.length > 0) && (
                      <div className="cls-related-downoad-remarks">
                        <span className="cls-related-downoad-remarks-title">
                          {" "}
                          {t(REQUEST_DOWNLOAD_MESSAGE.REMARKS)}
                        </span>
                        <textarea
                          aria-label="Remarks-Content"
                          className="cls-related-downoad-remarks-description"
                          id="remarks-description"
                          name="related-downoad-remarks-description"
                          rows={4}
                          cols={56}
                          readOnly
                          value={
                            props?.source?.remarks ||
                            props?.tempRevData?.remarks ||
                            ""
                          }
                        />
                      </div>
                    )}
                </div>

                <div className="modal-footer">
                  <div className="footercontainerlic Popupfooter footercontainer">
                    <div className="geocheckbtns">
                      {!isMaskedCountryUser ? (
                        <>
                          <Button
                            type="button"
                            aria-label="Close Preferences"
                            className="cancelbtn active btn btn-secondary"
                            variant="secondary"
                            onClick={closeErrorPopup}
                          >
                            {t(REQUEST_DOWNLOAD_MESSAGE.CANCEL)}
                          </Button>
                          <Button
                            type="button"
                            aria-label="Close Preferences"
                            className="submitbtn active btn btn-primary"
                            variant="secondary"
                            onClick={OnClickStartReqBtn}
                          >
                            {t(REQUEST_DOWNLOAD_MESSAGE.BUTTON)}
                          </Button>
                        </>
                      ) : (
                        <Button
                          type="button"
                          aria-label="Close Preferences"
                          className="acknowledgebtn active btn btn-secondary"
                          variant="secondary"
                          onClick={closeErrorPopup}
                        >
                          {t(REQUEST_DOWNLOAD_MESSAGE.ACKNOWLEDGE)}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {downloadMediaFlag && showPopup && !errorPopup ? (
        <Modal
          show={showPopup}
          className="cls-partialdownload-popup"
          aria-labelledby={props.arialabel}
          contentClassName={props.contentClassName}
          backdropClassName={props.backdropClassName}
          centered={props.centered}
        >
          <Modal.Header
            closeButton
            className="cls-closebutton"
            onHide={closeButtonChange}
          >
            <Modal.Title className="cls-partialdownload-heading">
              <h4> {DOWNLOAD_OPTIONS} </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="cls-partialmodal-body">
            <>
              <p className="cls-partialdownload-content">
                {PARTIAL_DOWNLOAD_CONTENT}
              </p>
              <div className="cls-partialdownload-radioContent">
                {PARTIAL_PUBLICATION_VALUES.map((item) => (
                  <>
                    <span className="cls-partialdownload-inputype">
                      <input
                        type="radio"
                        aria-label="partialDownload"
                        name="publication"
                        value={item.value}
                        onChange={downloadHandleChange}
                      />
                      <span className="cls-label">
                        <label>{item.label}</label>
                      </span>
                    </span>
                  </>
                ))}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className="cls-partialdownload-buttonContent">
              <Button
                className="cls-partialdownload-cancelButton"
                onClick={closeButtonChange}
              >
                Cancel
              </Button>
              <Button
                className="cls-partialdownload-button"
                disabled={disableButtonFlag}
                onClick={downloadButtonChange}
              >
                Download
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <>
          {fileStreamer && !errorPopup && (
            <FileStreamerModal
              isError={isError}
              setIsError={setIsError}
              errorPopup={errorPopup}
              saveApiStatus={saveApiStatus}
              setSaveApiStatus={setSaveApiStatus}
              pdfToken={pdfToken}
              setPdfToken={setPdfToken}
              jwtToken={jwtToken}
              fetchApiFailed={fetchApiFailed}
              setFetchApiFailed={setFetchApiFailed}
              encryptApiFailed={encryptApiFailed}
              setEncryptApiFailed={setEncryptApiFailed}
              pubId={
                props?.pubId
                  ? props?.pubId
                  : props?.pubNumber
                  ? props?.pubNumber
                  : 0
              }
              fileStreamer={fileStreamer}
              partialDownloadMedia={partialDownloadMedia}
              setPartialDownloadMedia={setPartialDownloadMedia}
              closeErrorPopup={closeErrorPopup}
              callDownloadTransferApi={callDownloadTransferApi}
            />
          )}
        </>
      )}
    </>
  );
};

export default RequestDownload;
