import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import {
  REQUEST_FORM_DATA,
  TOPHEADERTITLE,
  REQUEST_FORM_TEXT,
  REQUEST_FORM,
  ERROR_MSG,
  REQUEST_FORM_APPLICATIONS,PUBID_ERROR_MSG,AIRCRAFT_DETAILS,OTHER_DETAILS,APPLICATION_DETAILS
} from "../../../helper/constants";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import "./RequestForm.scss";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import RequestFormModal from "./RequestFormModal";
import { Button } from "react-bootstrap";
import RequestFormSubmitModal from "./RequestFormSubmitModal";
import { useLocation, useNavigate } from "react-router-dom";
import { event } from "jquery";
import Loader from "../../common/Loader/Loader";
import RequestFormErrorHandling from "./RequestFormErrorHandling";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";

const RequestForm = () => {
  const [checked, setChecked] = useState("");
  const [textVal, setTextVal] = useState<any>({
    regNo: "",
    serialNo: "",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [makeSelected, setMakeSelected] = useState<any>("");
  const [modelSelected, setModelSelected] = useState<any>("");
  const [textAreaVal, setTextAreaVal] = useState<any>("");
  const [disableSelect, setDisableSelect] = useState(false);
  const [disableModel, setDisableModel] = useState(true);
  const [disableMake, setDisableMake] = useState(false);
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const [disableRegNo, setDisableRegNo] = useState(true);
  const [disableSerialNo, setDisableSerialNo] = useState(true);
  const [requestFromModal, setRequestFormModal] = useState(false);
  const [radioVal, setRadioVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const [requestFormSubmit, setRequestFormSubmit] = useState(false);
  const [requestFormAttributes, setRequestFormAttributes] = useState([]);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [caseNumber, setCaseNumber] = useState();
  const [loader, setLoader] = useState(false);
  const [requestFormErrorPopup, setRequestFormErrorPopup] = useState(false);
  const [submmitingForm, SetSubmmitingForm] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [manualNoLicence, setManualNoLicence] = useState(false);
  const [applicationOptions,setApplicationOptions]= useState<any[]>([]);
  const[applicationMake,setApplicationMake]=useState();
  const [appData,setAppData]=useState();
  const url = window.location.pathname;
  const pubId = url.substring(url.lastIndexOf("/") + 1);
  const [makeModelEnabled,setMakeModelEnabled]=useState(false);
  const [selectButtonEnable,setSelectButtonEnable]=useState(false);
  const [disableSelectButton,setDisableSelectButton]=useState(false);
  const [applicationValue,setApplicationValue]=useState("");
  const[pubData,setPubData]=useState([]);
  

  
  const navigate = useNavigate();

  const { user_details } = useSelector((state: any) => state.config);
  const requestData = useSelector(
    (state: any) => state?.requestForms?.requestData
  );
  const tableDataDetails = useSelector((state: any) => state?.requestForms?.tableData);
  const requestToDescription = useSelector((state: any) => state?.requestForms?.requestToDescription);
  const buttonSelected = useSelector(
    (state: any) => state?.requestForms?.selectedQuicklink
  );
 useEffect(() => {
    window.scrollTo(0, 0);
    updateTableData(t(REQUEST_FORM_TEXT.UNASSIGNED));
    setRequestFormAttributes(REQUEST_FORM_DATA);
    changeAssignedAirCraftClass("unassigned-aircraft");
    getPubIdData(pubId);
  }, []);

  const customFilter = (option, rawInput) => {
    const inputValue = rawInput.toLowerCase();
   if (!inputValue) {
      return true;
    }
  if (option.data.fixed) {
      return true;
    }
    return (
      option.label.toLowerCase().includes(inputValue) ||
      option.value.toLowerCase().includes(inputValue)
    );
  };

  const getApplicationDetails =(e,appData)=>{
    setMakeModelEnabled(true);
    setApplicationValue(e?.value);
    if(appData!==undefined && appData.length>0){
    appData?.map((item)=>{
      if(e?.value===item?.application_name){
       const applicationChangeMake=item?.aircraft_oem;
        const applicationChangeModel=item?.application_name;
        setMakeSelected(applicationChangeMake);
        setModelSelected(applicationChangeModel);
        setTextVal({ regNo: "", serialNo: "" });
        setDisableMake(true);
        setDisableModel(true);
        setDisableSelectButton(true);
        setSelectButtonEnable(true);
       }
      else if(e?.value == OTHER_DETAILS){
        setMakeSelected("");
        setModelSelected("");
        setTextVal({ regNo: "", serialNo: "" });
        setDisableSelectButton(false);
        setSelectButtonEnable(true);

    } else if(e?.value === undefined){
        setMakeSelected("");
        setModelSelected("");
        setTextVal({ regNo: "", serialNo: "" });
        setDisableSelectButton(false);
      }
    });} else {
      setMakeModelEnabled(true);
      setSelectButtonEnable(true);
      setMakeSelected("");
      setModelSelected("");
      setTextVal({ regNo: "", serialNo: "" });
    }


  };

  const getPubIdData = async (pubId) => {
     setLoader(true);
    try {
      const response = await fetch("/api/v1/search/search-results", {

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubIds: [pubId],
        }),
      });
      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        setLoader(false);
        const totalValue = data?.message?.result?.hits?.total?.value;
        const applicationData = data?.message?.result?.hits?.hits[0]?._source.applications;
        setAppData(applicationData);
       const appApplicationOptions:any[]=[];
       const otherApplicationOptions = [{value:OTHER_DETAILS,label:OTHER_DETAILS,fixed: true}];
        if(applicationData!==undefined && applicationData?.length > 0){
              applicationData.map((item)=>{
                   const appOptions=item?.application_name;
                   const applicationMakeValue=item?.aircraft_oem;
                   setApplicationMake(applicationMakeValue);
                    appApplicationOptions.push({value:item.application_name,label:item.application_name});
                   });
                 const appOpt= otherApplicationOptions.concat(appApplicationOptions);
                  setApplicationOptions(appOpt);
                }
       else {
        setApplicationOptions(otherApplicationOptions);
        setMakeModelEnabled(true);
        setSelectButtonEnable(true);
        }
       
      } 
    } catch (error) {
      console.error(PUBID_ERROR_MSG,error);
     
    }
  };


  const getDefaultData=(appData)=>{
    if(appData==undefined){
    return OTHER_DETAILS;
     }else{
      return APPLICATION_DETAILS;
    }
  };


  const updateTableData = (assignedAirCraft) => {
    const data: any = [
      {
        title: requestData?.title,
        pub_number: requestData?.pub_number,
        ata: requestData?.ata,
        revision_date: requestData?.revision_date,
        versionNumber: requestData?.version_number,
        unassigned_aircraft: assignedAirCraft,
      },
    ];
    setTableData(data);
  };

  const radioButtons = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const closeRequestFormModal = () => {
    setRequestFormModal(false);
  };

  const changeCheckBoxVal = (val = "") => {
    setRequestFormModal(false);
    enableDisableFormFields(val == "" ? radioVal : val);
  };

  const enableDisableFormFields = (val) => {
    setChecked(val);
    if (val === "no") {
      setDisableSelect(true);
      changeAssignedAirCraftClass("unassigned-aircraft");
      updateTableData(t(REQUEST_FORM_TEXT.UNASSIGNED));
      setTextAreaVal("");
      disableConfirmButton();
      setModelSelected("");
      setMakeSelected("");
    } else {
      disableConfirmButton();
      setDisableSelect(false);
      setDisableModel(true);
      setDisableRegNo(true);
      setDisableSerialNo(true);
      changeAssignedAirCraftClass("assigned-checkmark");
      setTextVal({ regNo: "", serialNo: "" });
      updateTableData("");
      setTextAreaVal("");
      setModelSelected("");
      setMakeSelected("");
    }
  };

  const changeAssignedAirCraftClass = (classDetail) => {
    const objIndex = REQUEST_FORM_DATA.findIndex(
      (obj) => obj.data == "unassigned_aircraft"
    );
    REQUEST_FORM_DATA[objIndex].classname = classDetail;
    setRequestFormAttributes(REQUEST_FORM_DATA);
  };

  const checkRequestFormenableDisable = (val) => {
    const objData = REQUEST_FORM_DATA.find(
      (obj) => obj.data == "unassigned_aircraft"
    );

    // objData.classname == "assigned-checkmark"
    //   ? setRequestFormModal(true)
    //   : changeCheckBoxVal(val);

    //objData.classname == "assigned-checkmark";

    changeCheckBoxVal(val);
  };

  // const OnClickRadioButton = (val) => {
  //   setRadioVal(val);
  //   radioVal != ""
  //     ? val == "yes"
  //       ? checkRequestFormenableDisable(val)
  //       : setRequestFormModal(true)
  //     : enableDisableFormFields(val);
  // };

  const OnClickRadioButton = (val) => {
    setRadioVal(val);
    radioVal != ""
      ? val == "yes"
        ? checkRequestFormenableDisable(val)
        : (changeCheckBoxVal(val), setDisableSubmitBtn(true))
      : enableDisableFormFields(val);
  };

  const SelectButtons = [
    { label: "Make", disable: disableMake },
    { label: "Model", disable: disableModel },
  ];
  const TextBoxButtons = [
    {
      label: "Serial Number",
      value: textVal.serialNo,
      name: "serialNo",
      disable: disableSerialNo,
    },
    {
      label: "Registration Number",
      value: textVal.regNo,
      name: "regNo",
      disable: disableRegNo,
    },
  ];
  const OnChangeInputButton = (e, label) => {
    e.preventDefault();

    setTextVal({
      ...textVal,
      //[e.target.name]: e.target.value.replace(/[\n\r\s\t]+/g, ""),
      [e.target.name]: e.target.value.replace(/[<>()?\\|/+_=]/g, ""),
     
      //[e.target.name]: e.target.value.trim(),
    });

    if (label === "serialNo" && textVal.serialNo !== "") {
      //setDisableRegNo(false);
    }
  };
  const aggregationsSorting = (values) => {
    values.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    return values;
  };

  const SelectMakeOption =
    requestData && requestData?.manufacturers !== undefined
      ? requestData?.manufacturers?.map((item, index) => {
          return { label: item?.aircraft_oem };
        })
      : [];

  const SelectModelOption =
    requestData && requestData?.applications !== undefined
      ? requestData?.applications
          ?.filter((item, index) => item.aircraft_oem === makeSelected?.label)
          .map((data) => {
            return {
              label: data?.application_name,
              value: data?.application_name,
            };
          })
      : [];

  const OnChangeSelectButton = (opt, label) => {
    let sanitizedValue;
    // if (!/^[^<>(){}%&\\/!$'"@+]*$/.test(opt)) {
    //   sanitizedValue = opt
    //     .replaceAll(/[&\/\\^!+$%&/\\@'"‘“<>(){}]/g, "")
    //     .trim(" ");
    // }
    if (/[<>()?\\|/+_=]/.test(opt)) {
      sanitizedValue = opt
        .replaceAll(/[<>()?\\|/+_=]/g, "")
        .trim(" ");
    }
    setDisableModel(false);
    if (label == "Model") {
      setDisableSerialNo(false);
      setModelSelected(sanitizedValue != undefined ? sanitizedValue : opt);
      disableConfirmButton();
    } else {
      setMakeSelected(sanitizedValue != undefined ? sanitizedValue : opt);
      setDisableSubmitBtn(true);
      if (opt === "" || sanitizedValue === "") {
        setModelSelected("");
        setDisableModel(true);
      }
    }

    // disableConfirmButton();
  };
  const OnChangeReasonRequest = (e) => {
    //setTextAreaVal(e.target.value);
    setTextAreaVal(e.target.value.replace(/[<>()?\\|/+_=]/g, "") );
  };
  /*const disableConfirmButton = () => {
    if (checked == "yes") {
      if (
        textAreaVal === "" &&
        requestData.export_lic_description === "No License" &&
        requestData?.publication_category_name[0]?.publication_category !==
          "manual"
      ) {
        setDisableSubmitBtn(true);
      } else if (
        requestData.export_lic_description === "License" &&
        textAreaVal === ""
      ) {
        setDisableSubmitBtn(true);
      } else {
        setDisableSubmitBtn(false);
      }
    } else if (checked == "no") {
      if (requestData?.export_lic_description === "License") {
        if (
          textVal.regNo === "" ||
          textVal.serialNo === "" ||
          makeSelected === "" ||
          modelSelected === ""
        ) {
          setDisableSubmitBtn(true);
        } else if (textAreaVal === "") setDisableSubmitBtn(true);
        // else if (model === false) setDisableSubmitBtn(false);
        else setDisableSubmitBtn(false);
      } else {
        if (
          textVal.regNo === "" ||
          textVal.serialNo === "" ||
          modelSelected === "" ||
          makeSelected === ""
        ) {
          setDisableSubmitBtn(true);
        } else if (buttonSelected == "request_subscription")
          setDisableSubmitBtn(false);
        else if (textAreaVal === "") setDisableSubmitBtn(true);
        else setDisableSubmitBtn(false);
      }
    }
    if (
      checked === "no" &&
      buttonSelected == "request_download" &&
      textVal.regNo != "" &&
      textVal.serialNo != "" &&
      modelSelected != "" &&
      makeSelected != ""
    ) {
      setDisableSubmitBtn(false);
    }
  };*/


  const disableConfirmButton = () =>{
    if (
      textVal.regNo === "" ||
      textVal.serialNo === "" ||
      modelSelected === "" ||
      makeSelected === ""
    ) {
      setDisableSubmitBtn(true);
    }  
       else if (buttonSelected == "request_subscription")
          setDisableSubmitBtn(false);
        else if (textAreaVal === "")
         setDisableSubmitBtn(true);
         else setDisableSubmitBtn(false); 

         if (

          buttonSelected == "request_download" &&
          textVal.regNo != "" &&
          textVal.serialNo != "" &&
          modelSelected != "" &&
          makeSelected != ""
        ) {
          setDisableSubmitBtn(false);
        }

  };

  const setRequestFormSubmitRedirect = () => {
    setRequestFormSubmit(false);
    navigate("/");
  };

  useEffect(() => {
    if (checked === "yes" && buttonSelected == "request_subscription") {
      setDisableSubmitBtn(false);
    } else if (checked === "yes" && buttonSelected == "request_download") {
      setDisableSubmitBtn(false);
    } else if (checked === "yes" && textAreaVal === "") {
      setDisableSubmitBtn(true);
    }

    if (
      checked === "no" &&
      textVal?.serialNo === "" &&
      disableRegNo === false &&
      disableSerialNo === false
    ) {
      // if (
      //   checked === "yes" &&
      //   requestData.export_lic_description === "No License"
      // ) {
      //   // disableConfirmButton(true);
      //   setDisableSubmitBtn(false);
      // }

      setTextVal({ regNo: "" });
      setDisableRegNo(true);
      updateTableData(t(REQUEST_FORM_TEXT.UNASSIGNED)),
        changeAssignedAirCraftClass("unassigned-aircraft");
    }
  });

  const getSubmitBtnModal = async () => {
    setDisableSubmitBtn(true);
    setRequestFormSubmit(true);
    SetSubmmitingForm(true);
    //setLoader(true);
    try {
      const response = await fetch("/api/v1/request-form/case-create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pub_number: tableDataDetails?.pub_number?.length > 0 ? tableDataDetails.pub_number : requestData.pub_number,
          ata_number: tableDataDetails?.ata?.length > 0 ? tableDataDetails.ata : requestData.ata,
          rev_number: tableDataDetails?.version_number?.length > 0 ? tableDataDetails.version_number : requestData.version_number,
          email: user_details.email,
          aircraft_make: makeSelected,
          aircraft_model: modelSelected,
          serial_number: textVal.serialNo,
          registration_number: textVal.regNo,
          rev_date: tableDataDetails?.revision_date?.length > 0 ? tableDataDetails.revision_date : requestData.revision_date,
          reason_for_request: textAreaVal?.length > 0 ? textAreaVal: '',
          additional_web_form_info__c: requestToDescription?.length > 0 ? requestToDescription : ''
        }),
      });
      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        setCaseNumber(data?.message?.result);
        setLoader(false);
        setRequestFormErrorPopup(false);
        SetSubmmitingForm(false);
        dispatch(
          RequestFormAction.getRequestFormData({
            tableData : requestData?.ata?.length > 0 ? requestData : tableDataDetails,
            linkSelected: buttonSelected
          }));
      } else {
        setErorState({ falg: true, msg: data?.message?.result });
        setLoader(false);
        SetSubmmitingForm(false);
        setRequestFormSubmit(false);
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      setRequestFormErrorPopup(true);
      setLoader(false);
      SetSubmmitingForm(false);
      setRequestFormSubmit(false);
    }
  };

  const submitFormHandler = () => {
    getSubmitBtnModal();
    disableFormFields();
  };

  const disableFormFields = () => {
    setDisableFields(true);
  };

  const retryBtnHandler = () => {
    getSubmitBtnModal();
  };
  const onAircraftKeydown = (val) => {
    // if (!/^[^<>(){}%&\\/!$'"@+]*$/.test(val.key)) {
    //   val.preventDefault();
    // }
    if(/[<>()?\\|/+_=]/.test(val.key)){
      val.preventDefault();
    }
  };

  return (
    <div>
      <RequestFormSubmitModal
        show={requestFormSubmit}
        setRequestFormSubmit={setRequestFormSubmitRedirect}
        tableData={requestData?.ata?.length > 0 ? requestData : tableDataDetails}
        reasonForReq={textAreaVal}
        caseNumbers={caseNumber}
        registration_number= {textVal.regNo}
        submmitingFormHandler={submmitingForm}
      />
      <RequestFormModal
        show={requestFromModal}
        closeRequestFormModal={closeRequestFormModal}
        changeCheckBoxVal={changeCheckBoxVal}
        setDisableSubmitBtn={setDisableSubmitBtn}
      />
      <RequestFormErrorHandling
        showPopup={requestFormErrorPopup}
        setRequestFormErrorPopup={setRequestFormSubmitRedirect}
        retryBtn={retryBtnHandler}
      />
      <TableComponent
        className="requestForm"
        attributes={requestFormAttributes}
        topHeader={TOPHEADERTITLE.REQUEST_FORM_TITLE}
        userData={requestData !== "" ? tableData : []}
        purpose="request_form"
        tableDetailsData = {tableDataDetails}
      />
      <div className="request-information">
       
        
        <div className="radioDiv">
        <div className="aircraftdetailsTitle">
                <label>{AIRCRAFT_DETAILS}</label>
              </div>
          <p className="requestorquery">
            {/*{" "}
            {t(REQUEST_FORM_TEXT.ACTIVE_SERVICE_AGREEMENT)}*/}
             {REQUEST_FORM_APPLICATIONS}
          </p>
          {/*radioButtons.map((radio, index) => (
            <>
              <div className="radioLeft">
                <input
                  aria-label="info-radio values"
                  type="radio"
                  value={radio.value}
                  name={radio.value}
                  disabled={disableFields}
                  onChange={() => OnClickRadioButton(radio.value)}
                  checked={checked === radio.value}
                />
                <label>{radio.label}</label>
              </div>
            </>
          ))*/
        }
        </div>

        <div className="cls-applicationDropdown">
        <Select 
         onChange={(e)=>getApplicationDetails(e,appData)}
         options={applicationOptions}
         isSearchable
         filterOption={customFilter}
         placeholder={getDefaultData(appData)}
         aria-label="application_details"
         />
         </div>
       
        {/*disableSelect && (
          <>
            <div className="aircraftdetails">
              <div className="aircraftdetailsTitle">
                <label>Aircraft Details</label>
              </div>

              {SelectButtons.map(
                (items, index) => (
                  <input
                    key={index}
                    aria-label="info-radio values"
                    type="text"
                    maxLength={20}
                    value={items.label == "Make" ? makeSelected : modelSelected}
                    onChange={(opt) =>
                      OnChangeSelectButton(opt.target.value, items.label)
                    }
                    onKeyDown={(vall) => onAircraftKeydown(vall)}
                    placeholder={items.label}
                    name={items.label}
                   // disabled={disableFields ? true : items.disable}
                    onBlur={() => disableConfirmButton()}
                  />
                )

                // <Select
                //   key={items.label}
                //   aria-label="aircraft"
                //   className="aircraft-items selection"
                //   options={
                //     items.label == "Make"
                //       ? aggregationsSorting(SelectMakeOption)
                //       : aggregationsSorting(SelectModelOption)
                //   }
                //   value={items.label == "Make" ? makeSelected : modelSelected}
                //   onChange={(opt) => OnChangeSelectButton(opt, items.label)}
                //   isDisabled={disableFields ? true : items.disable}
                //   placeholder={items.label}
                //   onBlur={() => disableConfirmButton()}
                // />
              )}
              {TextBoxButtons.map((box, index) => (
                <>
                  <input
                    key={index}
                    aria-label="info-radio values"
                    type="text"
                    maxLength={12}
                    value={box.value}
                    name={box.name}
                    onChange={(e) => OnChangeInputButton(e, box.name)}
                    placeholder={box.label}
                  //  disabled={disableFields ? true : box.disable}
                    onBlur={() => {
                      box.value == ""
                        ? (updateTableData(t(REQUEST_FORM_TEXT.UNASSIGNED)),
                          changeAssignedAirCraftClass("unassigned-aircraft"))
                        : box.name == "regNo"
                        ? (updateTableData(box.value),
                          changeAssignedAirCraftClass("assigned-checkmark"))
                        : "",
                        disableConfirmButton();
                    }}
                    onFocus={() =>
                      box.name == "regNo" &&
                      box.value === "" &&
                      setDisableSubmitBtn(true)
                    }
                  />
                </>
              ))}
            </div>
          </>
                  )*/}
<>
            <div className="aircraftdetails">
              

              {makeModelEnabled  && SelectButtons.map(
                (items, index) => (
                  <input
                    key={index}
                    aria-label="info-radio values"
                    type="text"
                    maxLength={20}
                    value={items.label == "Make" ? makeSelected : modelSelected}
                    onChange={(opt) =>
                      OnChangeSelectButton(opt.target.value, items.label)
                    }
                    onKeyDown={(vall) => onAircraftKeydown(vall)}
                    placeholder={items.label}
                    name={items.label}
                   // disabled={disableFields ? true : items.disable}
                    onBlur={() => disableConfirmButton()}
                    disabled={disableSelectButton}
                  />
                )

                // <Select
                //   key={items.label}
                //   aria-label="aircraft"
                //   className="aircraft-items selection"
                //   options={
                //     items.label == "Make"
                //       ? aggregationsSorting(SelectMakeOption)
                //       : aggregationsSorting(SelectModelOption)
                //   }
                //   value={items.label == "Make" ? makeSelected : modelSelected}
                //   onChange={(opt) => OnChangeSelectButton(opt, items.label)}
                //   isDisabled={disableFields ? true : items.disable}
                //   placeholder={items.label}
                //   onBlur={() => disableConfirmButton()}
                // />
              )}
              {selectButtonEnable  && TextBoxButtons.map((box, index) => (
                <>
                  <input
                    key={index}
                    aria-label="info-radio values"
                    type="text"
                    maxLength={12}
                    value={box.value}
                    name={box.name}
                    onChange={(e) => OnChangeInputButton(e, box.name)}
                    placeholder={box.label}
                  //  disabled={disableFields ? true : box.disable}
                    onBlur={() => {
                      box.value == ""
                        ? (updateTableData(t(REQUEST_FORM_TEXT.UNASSIGNED)),
                          changeAssignedAirCraftClass("unassigned-aircraft"))
                        : box.name == "regNo"
                        ? (updateTableData(box.value),
                          changeAssignedAirCraftClass("assigned-checkmark"))
                        : "",
                        disableConfirmButton();
                    }}
                    onFocus={() =>
                      box.name == "regNo" &&
                      box.value === "" &&
                      setDisableSubmitBtn(true)
                    }
                  />
                </>
              ))}
            </div>
          </>


        <div className="TCcontent">
          {buttonSelected == "request_publication" && (
            <div className="reason-request">
              <label>Reason for Request</label>

              <div>
                {" "}
                <textarea
                  aria-label="info-radio values"
                  rows={4}
                  cols={70}
                  maxLength={150}
                  value={textAreaVal}
                  onChange={(e) => OnChangeReasonRequest(e)}
                  onKeyDown={(vall) => onAircraftKeydown(vall)}
                  placeholder={"Type here..."}
                  disabled={disableFields}
                  onBlur={() => disableConfirmButton()}
                />
              </div>
            </div>
          )}
          <div
            className="tandc"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                t(REQUEST_FORM_TEXT.TERMS_AND_CONIDTIONS)
              ),
            }}
          ></div>
        </div>
        {/* {loader ? (<Loader />) : (<div className="requestformSubmitbtn">
          <Button disabled={disableSubmitBtn} onClick={submitFormHandler}>
            {t(REQUEST_FORM.SUBMIT_FORM)}
          </Button>
        </div>) } */}

        <div className="requestformSubmitbtn">
          <Button disabled={disableSubmitBtn} onClick={submitFormHandler}>
            {t(REQUEST_FORM.SUBMIT_FORM)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RequestForm;