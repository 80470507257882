import React, { MouseEvent, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as DownloadIcon } from "../../pages/SearchResults/images/requestdownloadicon.svg";
import "./ExcelExporter.scss";
import { LABELKEYS_SEARCH_RESULTS } from "../../../helper/constants";
import { useTranslation } from "react-i18next";

interface ExcelExporterProps {
  buttonTitle: string;
  param: string;
  apiPayload: any;
  recordsCounter: number;
}

const ExcelExporter: React.FC<ExcelExporterProps> = ({
  buttonTitle,
  param,
  apiPayload,
  recordsCounter,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const upperLimit: boolean =
    param === "search-result" && !!recordsCounter && recordsCounter > 3000;

  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoading(true);
    handleDownload();
  };

  const handleDownload = async () => {
    try {
      const response: any = await fetch(
        "/api/v1/excel-download/download-excel-file",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            customParameter: param,
          },
          body: JSON.stringify(apiPayload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the Excel file.");
      }

      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;

      link.download = `${param}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
      //alert("Error downloading file. Please try later.");
    }
    setLoading(false);
  };

  return (
    <div className="excelDownload">
      <Tooltip
        title={
          upperLimit
            ? `${t(LABELKEYS_SEARCH_RESULTS.LIMIT_EXCEEDED_TO_DOWNLOAD)}`
            : ""
        }
      >
        <button
          className="btnDownload"
          onClick={handleOnClick}
          disabled={loading || !apiPayload || upperLimit}
        >
          <DownloadIcon className="downloadIcon" style={{ marginRight: 8 }} />
          <p className="text">
            {buttonTitle ? (loading ? "Downloading..." : buttonTitle) : ""}
          </p>
        </button>
      </Tooltip>
    </div>
  );
};

export default ExcelExporter;
