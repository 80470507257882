import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/Service";
import { API_PATHS, ERROR_MESSAGES, DEFAULT_LANG } from "../../helper/constants";

const initialState = {
  configData: '',
  token: "",
  tokenValid: false,
  soldToNumbers: [],
  isLoadingToken: false,
  isHoneywellEmployee: false,
  hid: "",
  portalPermissions: ""
};

//loading config data
export const fetchAsyncConfig = createAsyncThunk("techpub/config", async () => {
  try {
    const res = await callToAPI("/api/v1/config/configurations", "GET", {
      "Content-Type": "application.json",
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
});


//loading token
export const fetchAsyncToken = createAsyncThunk("/techpub/token", async () => {
  try {
    const res = await callToAPI("/api/v1/auth/get-token", "GET", {
      "Content-Type": "application.json",
    });
    const data = await res.json();
    
    return data;
  } catch (error) {
    console.log(error);
  }
});

//validating token
export const fetchAsyncTokenValidation = createAsyncThunk("/techpub/tokenvalidation",
  async (tokenValidate: any) => {
    try {
      const res = await callToAPI('/api/v1/auth/check-access', 'GET', { 'authorization': `Bearer ${tokenValidate}` });
      const data = await res.json();
      if (res.status === 200) {
        return { tokenAccess: data?.message, honeywellEmployee: data?.message?.result?.isInternalUser, hid: data?.message?.result?.KEY_SUB };
      } else {
        return { tokenAccess: false, honeyWellEmployee: false };
      }
    } catch (error) {
      console.log(error);
    }
  }
);
export const fetchAsyncPortalPermissions = createAsyncThunk("/techpub/portal-permissions",
  async (hid: any) => {
    try {
      const res = await fetch('/api/v1/portal-permission', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hid: hid,
        }),
      });
      const data = await res.json();
      if (res.status === 200) {
        return {
          portalPermission: data?.message
        };
      } else {
        return { resStatus: res.status, portalPermission: "" };
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchdownloadHistoryTotal = createAsyncThunk("/techpub/download-history",
  async () => {
    try {
      const res = await callToAPI('/api/v1/download-history?isTopFive=false', 'GET');
      const data = await res.json();
      if (res.status === 200) {
        return { download_totalRecords: data?.message?.result?.totalRecords ? data?.message?.result?.totalRecords : 0 , download_loader:false};
      } else {
        return {  download_totalRecords:0};
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchRequestorInformation = createAsyncThunk("/techpub/requestor-infromation",
  async () => {
    try {
      const res = await callToAPI('/api/v1/requestor-information', 'GET');
      const data = await res.json();
      if (res.status === 200) {
        return { user_details: data?.message?.result , requestorinfo_loader:false};
      } else {
        return {  user_details:''};
      }
    } catch (error) {
      console.log(error);
    }
  }
);
// loading sold to values
export const fetchAsyncSoldToValues = createAsyncThunk(
  "/msp/soldtonumbers",
  async (token_techpub) => {
    try {
      const res = await callToAPI("/api/v1/auth/get-sold-to-number", "GET", {
        authorization: `Bearer ${token_techpub}`,
      });
      const data = await res.json();
      return data?.message?.result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const configReducer = createSlice({
  name: "configdata",
  initialState,
  extraReducers: {
    [fetchAsyncConfig.fulfilled]: (state, { payload }) => {
      return { ...state, configData: payload.message.result };
    },
    [fetchAsyncToken.fulfilled]: (state, { payload }) => {
  
      return { ...state, token: payload, };
    },
    [fetchAsyncTokenValidation.pending]: (state, { payload }) => {
      return { ...state, isLoadingToken: true };
    },
    [fetchAsyncTokenValidation.fulfilled]: (state, { payload }) => {

      return { ...state, tokenValid: payload.tokenAccess, isHoneywellEmployee: false, isLoadingToken: false, hid: payload?.hid };
    },
    [fetchAsyncTokenValidation.rejected]: (state, { payload }) => {
      return { ...state, isLoadingToken: false, tokenValid: false, isHoneywellEmployee: false };
    },
    [fetchAsyncPortalPermissions.pending]: (state, { payload }) => {
      return { ...state, portalPermissions: "", loadingPortal: true };
    },
    [fetchAsyncPortalPermissions.fulfilled]: (state, { payload }) => {
      return { ...state, portalPermissions: payload?.portalPermission, loadingPortal: false };
    },
    [fetchdownloadHistoryTotal.pending]: (state, { payload }) => {
      return { ...state, download_totalRecords: "", loadingDownload: true };
    },
    [fetchdownloadHistoryTotal.fulfilled]: (state, { payload }) => {
      return { ...state, download_totalRecords: payload?.download_totalRecords, loadingDownload: false };
    },
    [fetchRequestorInformation.pending]: (state, { payload }) => {
      return { ...state, user_details: "", loadingRequestorInfo: true };
    },
    [fetchRequestorInformation.fulfilled]: (state, { payload }) => {
      return { ...state, user_details: payload?.user_details, loadingRequestorInfo: false };
    }
    
  },

});
export const configActions = configReducer.actions;
