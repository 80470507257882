import React, { useEffect, useState } from "react";
import {
  ERROR_MSG,
  LABELKEYS_SEARCH_RESULTS,
  UPDATES,
  VIEW_COLLECTION,
  COLLECTION_OPERATION,
  COLLECTION_ATTRIBUTES,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./ViewCollection.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SearchResults from "../SearchResults/SearchResults";
import Feature from "../../common/FeatureFlags/Feature/Feature";
import Select from "react-select";
import Loader from "../../common/Loader/Loader";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import BackToSearch from "../../common/BackToSearch/BackToSearch";
import CollectionTabData from "../../common/CollectionTabData/CollectionTabData";
const ViewCollection = (props) => {
  const { t } = useTranslation();
  const location: any = useLocation();

  const urldata = location?.state?.searchVal;

  const searchResultsData: any = location?.state?.seacrhResults;
  const hits: any = [];
  const hitsId = location?.state?.id;
  const windowUrl = window.location.pathname;
  const pubId = windowUrl.substring(windowUrl.lastIndexOf("/") + 1);
  const [searchdata, setSearchData] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [errMSg, setErrorMsg] = useState("");
  const [manualDataLength, setManualDataLength] = useState<any>(0);
  const [payload, setPayload] = useState<any>({});
  const [collectionLabel, setCollectionLabel] = useState([]);
  const [updateLabel, setUpdateLabel] = useState([]);
  const [updateManualDropdown, setUpdateManualDropdown] = useState<any>([]);
  const [manualDropdown, setManualDropdown] = useState<any>([]);
  const [tempRevIds, setTempRevIds] = useState<any>([]);
  const [relatedPubIds, setRelatedPubIds] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("manuals");
  const [errMSG, setErrorState] = useState({ falg: false, msg: "" });
  const [viewCollectionTopData, setViewCollectionTopData] = useState<any>([]);
  const [subscriptionFlag, updateSubscriptionFlag] = useState(false);
  const [fileStreamerData, setFileStreamerData] = useState("");
  const [tempRevData, setTempRevData] = useState();
  const pubsInCollection =
    location?.state?.datares?._source.pubs_in_collection !== "undefined"
      ? location?.state?.datares?._source.pubs_in_collection
      : [];

  const tempRevisionsData = searchResultsData?.hits?.hits[hitsId];
  hits.push(tempRevisionsData);

  const watlichselectedoptions: any = [
    { label: "All Manuals", value: "" },
    { label: "Collection Manuals", value: "Y" },
    { label: "Supporting Manuals", value: "N" },
  ];

  const updateSelectedOption: any = [
    { label: "Manuals", value: "MANUALS" },
    { label: "Temporary Revisions", value: "TR" },
    { label: "Related Pubs", value: "RELATED_PUB" },
  ];
  let seleted_option = watlichselectedoptions[0];
  let seleted_option_updates = updateSelectedOption[0];
  useEffect(() => {
    getSearchValue(pubId);
    // const mainManualData =
    //   pubsInCollection !== undefined && pubsInCollection.length > 0
    //     ? pubsInCollection.filter((pc) => pc.main_manual_indicator == "Y")
    //     : [];
  }, [pubsInCollection, activeTab]);

  const getSearchValue = async (pubId) => {
    setLoader(true);
    try {
      const response = await fetch("/api/v1/search/search-results", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubIds: [pubId],
        }),
      });
      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        setLoader(false);
        const totalValue = data?.message?.result?.hits?.total?.value;
        if (totalValue > 1) {
          const searchDataRes: any = data?.message?.result;
          const singleRecord: any = searchDataRes.hits?.hits[0];
          searchDataRes.hits.hits = [];
          searchDataRes.hits.hits.push(singleRecord);
          searchDataRes.hits.total.value = 1;
          updateSubscriptionFlag(singleRecord?._source?.is_subscription_flag);
          if (activeTab != "updates") {
            collectionManualsData(singleRecord);
          }
          updateFileStreamerComponentData(singleRecord);
          setViewCollectionTopData(searchDataRes);
        } else {
          setViewCollectionTopData(data?.message?.result);
          if (activeTab != "updates") {
            collectionManualsData(data?.message?.result);
          }
          updateFileStreamerComponentData(data?.message?.result?.hits?.hits[0]);
          updateSubscriptionFlag(
            data?.message?.result?.hits?.hits[0]?._source?.is_subscription_flag
          );
        }
      } else if (data?.message?.isSuccess === false) {
        setLoader(false);
        setErrorState({ falg: true, msg: data?.message?.result });
      } else {
        setLoader(false);
        setErrorState({ falg: true, msg: data?.message?.result });
      }
    } catch (error) {
      setLoader(false);
      setErrorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const collectionManualsData = (searchData) => {
    const searchedPubsCollection = searchData?.hits?.hits[0]._source
      ?.pubs_in_collection
      ? searchData?.hits?.hits[0]._source?.pubs_in_collection
      : [];
    setManualDropdown(seleted_option);
    setCollectionLabel(watlichselectedoptions[0].label);
    const tempdata =
      pubsInCollection !== undefined && pubsInCollection.length > 0
        ? location.state.datares._source.pubs_in_collection.filter(
            (planet) =>
              planet.main_manual_indicator === "Y" ||
              planet.main_manual_indicator === "N"
          )
        : searchedPubsCollection.length > 0
        ? searchedPubsCollection.filter(
            (planet) =>
              planet.main_manual_indicator === "Y" ||
              planet.main_manual_indicator === "N"
          )
        : [];
    if (
      (pubsInCollection !== undefined && pubsInCollection.length > 0) ||
      (searchedPubsCollection !== undefined &&
        searchedPubsCollection.length > 0)
    ) {
      const manIDS = tempdata.map(function (item) {
        return item.manualscollection_pub_uuid;
      });

      setManualPayload(manIDS, "");
    }
    const manualDataLengthVal =
      pubsInCollection !== undefined && pubsInCollection.length > 0
        ? pubsInCollection?.length
        : searchedPubsCollection !== undefined &&
          searchedPubsCollection.length > 0
        ? searchedPubsCollection.length
        : 0;
    setManualDataLength(manualDataLengthVal);
  };

  const updateFileStreamerComponentData = (record) => {
    const fileStreamerParms: any = {};
    fileStreamerParms["a2a2_indicator"] = record?._source["2a2a_indicator"];
    fileStreamerParms["ldm"] = record?._source["limited_distribution_number"];
    setFileStreamerData(fileStreamerParms);
  };

  const udateManuals = (val) => {
    let manID = [];
    if (pubsInCollection !== undefined && pubsInCollection.length > 0) {
      const tempdata =
        val == ""
          ? location.state.datares._source.pubs_in_collection
          : location.state.datares._source.pubs_in_collection.filter(
              (planet) => planet.main_manual_indicator === val
            );

      manID = tempdata.map(function (item) {
        return item.manualscollection_pub_uuid;
      });
      setManualPayload(manID, val);
    } else {
      const tempdata =
        val == ""
          ? viewCollectionTopData?.hits?.hits[0]?._source?.pubs_in_collection
          : viewCollectionTopData?.hits?.hits[0]?._source?.pubs_in_collection.filter(
              (planet) => planet.main_manual_indicator === val
            );

      manID = tempdata.map(function (item) {
        return item.manualscollection_pub_uuid;
      });
      setManualPayload(manID, val);
    }
    getCollectionLabel(val);
    setManualDataLength(manID.length);
  };

  const getCollectionLabel = (val) => {
    const findCollectionlabel: any = watlichselectedoptions.find(
      (collection) => collection.value == val
    );
    setCollectionLabel(findCollectionlabel?.label);
  };

  const setUpdateLable = (val) => {
    const findUpdatelabel: any = updateSelectedOption.find(
      (updateLabelText) => updateLabelText.value == val
    );
    setUpdateLabel(findUpdatelabel?.label);
  };
  const setUpdatesValue = (val) => {
    let updateManualids: any = [];

    switch (val) {
      case "MANUALS":
        updateManualids =
          pubsInCollection !== undefined && pubsInCollection.length > 0
            ? pubsInCollection.map(function (item) {
                return item.manualscollection_pub_uuid;
              })
            : viewCollectionTopData?.hits?.hits[0]?._source?.pubs_in_collection
            ? viewCollectionTopData?.hits?.hits[0]?._source?.pubs_in_collection.map(
                function (item) {
                  return item.manualscollection_pub_uuid;
                }
              )
            : [];
        break;
      case "TR":
        updateManualids = tempRevIds;
        break;
      case "RELATED_PUB":
        updateManualids = relatedPubIds;
        break;
    }

    setUpdateLable(val);
    updateManualids.length > 0
      ? setUpdatePayload(updateManualids, val)
      : setSearchData([]);
    setManualDataLength(updateManualids.length);
  };

  const setUpdatePayload = (manIds, pubType) => {
    const updateDate =
      location?.state?.datares !== undefined
        ? location?.state?.datares?._source?.revision_date !== undefined &&
          location?.state?.datares?._source?.revision_date !== null &&
          location?.state?.datares?._source?.revision_date !== ""
          ? formatDate(location.state.datares._source.revision_date)
          : formatDate(location.state.datares._source.original_date)
        : viewCollectionTopData?.hits?.hits[0]?._source?.revision_date !==
            undefined &&
          viewCollectionTopData?.hits?.hits[0]?._source?.revision_date !==
            null &&
          viewCollectionTopData?.hits?.hits[0]?._source?.revision_date !== ""
        ? formatDate(
            viewCollectionTopData?.hits?.hits[0]?._source?.revision_date
          )
        : formatDate(
            viewCollectionTopData?.hits?.hits[0]?._source?.original_date
          );

    const payloadObj = {
      manual_id: manIds,
      pubType: pubType,
      operation: COLLECTION_OPERATION,
      updatedDate: updateDate,
    };

    getManuals(payloadObj);
  };

  const setManualPayload = async (manIds, indicator) => {
    const manualPayloadObj = {
      manual_id: manIds,
      main_manual_indicator: indicator,
    };
    // let allManuals = false;
    const allManuals = indicator == "" ? true : false;
    getManuals(manualPayloadObj, allManuals);
  };

  const setSelectedManual = (lable, val) => {
    seleted_option = {
      label: lable,
      value: val,
    };

    setManualDropdown(seleted_option);
    udateManuals(val);
  };

  const setSelectedUpdates = (lable, val) => {
    seleted_option_updates = {
      label: lable,
      value: val,
    };

    setUpdateManualDropdown(seleted_option_updates);
    setUpdatesValue(val);
  };

  const formatDate = (value) => {
    const date = new Date(value).toISOString().slice(0, 10);
    return date;
  };

  const updateRelatedTempRevArr = (dataResult) => {
    let relatedPubsArr: any = [];
    let temmpRevArr: any = [];
    dataResult?.hits?.hits.map((data: any, index) => {
      if (
        data._source.related_publications !== undefined &&
        data._source.related_publications !== null
      ) {
        relatedPubsArr.push(...data._source.related_publications);
      }
      if (
        data._source.temp_rev_ids !== undefined &&
        data._source.temp_rev_ids !== null
      ) {
        temmpRevArr.push(...data._source.temp_rev_ids);
      }
    });
    relatedPubsArr = removeDuplicates(relatedPubsArr);
    temmpRevArr = removeDuplicates(temmpRevArr);
    setRelatedPubIds(relatedPubsArr);
    setTempRevIds(temmpRevArr);
  };

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const handleClick = (e) => {
    setActiveTab(e);
    e == "manuals"
      ? setSelectedManual(
          watlichselectedoptions[0].label,
          watlichselectedoptions[0].value
        )
      : setSelectedUpdates(
          updateSelectedOption[0].label,
          updateSelectedOption[0].value
        );
  };
  const getManuals = async (payloadObj, allManuals = false) => {
    try {
      setLoader(true);
      const response = await fetch("/api/v1/collections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadObj),
      });
      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        if (allManuals === true) {
          updateRelatedTempRevArr(data?.message?.result);
        }

        setSearchData(data?.message?.result);
        setErrorMsg("");

        if (
          data?.message?.result?.hits?.hits === null ||
          data?.message?.result?.hits?.hits.length === 0
        ) {
          setErrorMsg("No Records Found!");
        }
        setLoader(false);
      } else if (data?.message?.isSuccess === false) {
        setLoader(false);
        setSearchData("");
        setErrorMsg(data?.message?.result);
        setErrorState({ falg: true, msg: data?.message?.result });
      } else {
        setLoader(false);
      }
    } catch (error) {
      setErrorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const onChange = () => {
    setErrorState({ falg: false, msg: "sucsess" });
  };
  return (
    <>
      <BackToSearch data={urldata} />
      <Feature name="Search Results">
        <SearchResults
          dataSearch={viewCollectionTopData}
          Watchlistflag={true}
          searchval={location?.state?.searchVal}
          showMore={false}
          load={false}
          attributes={COLLECTION_ATTRIBUTES}
          className="ViewcollectionContainer TopmetaData"
        />
      </Feature>
      <div className="viewcollectiontab">
        <div className="collectionTabcontent">
          <Tabs
            activeKey={activeTab}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(t: any) => handleClick(t)}
          >
            <Tab eventKey="manuals" title="Manuals" className="manualstab">
              {loader ? (
                ""
              ) : (
                <>
                  <div>
                    <h3 className="manualstitle">
                      {collectionLabel} (
                      {manualDataLength !== "" && manualDataLength !== undefined
                        ? manualDataLength
                        : 0}
                      )
                    </h3>
                    <Select
                      aria-label="Search Sorting"
                      className="sortby-relevance Collectiondropdown"
                      options={watlichselectedoptions}
                      onChange={(opt) =>
                        setSelectedManual(opt?.label, opt?.value)
                      }
                      value={manualDropdown}
                    />
                  </div>
                  {!loader && (
                    <CollectionTabData
                      subscriptionFlag={subscriptionFlag}
                      seacrhval={location?.state?.searchVal}
                      dataLength={manualDataLength}
                      searchData={searchdata}
                      loader={loader}
                      error={errMSg}
                    />
                  )}
                </>
              )}
            </Tab>
            <Tab eventKey="updates" title="Updates" className="manualstab">
              {loader ? (
                ""
              ) : (
                <>
                  <div>
                    <h3 className="manualstitle">
                      {updateLabel} (
                      {searchdata?.hits?.total?.value
                        ? searchdata?.hits?.total.value
                        : 0}
                      )
                    </h3>
                    <Select
                      aria-label="Search Sorting"
                      className="sortby-relevance Collectiondropdown"
                      options={updateSelectedOption}
                      onChange={(opt: any) =>
                        setSelectedUpdates(opt?.label, opt?.value)
                      }
                      value={updateManualDropdown}
                    />
                  </div>
                  <CollectionTabData
                    fileStreamerParams={fileStreamerData}
                    subscriptionFlag={subscriptionFlag}
                    seacrhval={location?.state?.searchVal}
                    updates={true}
                    dataLength={searchdata?.hits?.total?.value}
                    searchData={searchdata}
                    error={errMSg}
                    type={updateManualDropdown.value}
                  />
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};

export default ViewCollection;
