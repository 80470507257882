export const FeatureFlags = [
  {
    name: "Geo Check",
    description: "Get Country List Component",
    active: true,
  },
  {
    name: "Search Bar",
    description: "Search Bar Component",
    active: true,
  },

  {
    name: "Search Results",
    description: "Search Resluts Component",
    active: true,
  },
];
