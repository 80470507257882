import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/Service";
import { HTTP_STATUS_CODE } from "../../helper/constants";

const initialState = {
  licdata: "",
  isLoadingLic: false,
  resLicStatus: "",
};

//loading config data
export const fetchAsyncLicAgreement = createAsyncThunk(
  "techpub/licagreement",
  async () => {
    try {
      const res = await callToAPI("/api/v1/lic-agreement/get", "GET", {
        "Content-Type": "application.json",
      });

      if (res.status === HTTP_STATUS_CODE.STATUS_200) {
        const data = await res.json();

        return {
          resStatus: HTTP_STATUS_CODE.STATUS_200,
          licdata: data?.message?.isAccepted,
        };
      } else {
        return { resStatus: res.status, licdata: "" };
      }
    } catch (error) {
    }
  }
);

// loading sold to values

export const licAgreementReducer = createSlice({
  name: "licData",
  initialState,
  extraReducers: {
    [fetchAsyncLicAgreement.pending]: (state, action) => {
      return { ...state, isLoadingLic: true };
    },
    [fetchAsyncLicAgreement.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        licdata: payload,
        resLicStatus: payload?.resStatus,
        isLoadingLic: false,
      };
    },
    [fetchAsyncLicAgreement.rejected]: (state, action) => {
      return {
        ...state,
        isLoadingLic: false,
        resLicStatus: payload?.resStatus,
      };
    },
  },
});
export const configActions = licAgreementReducer.actions;