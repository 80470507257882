import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageNo : 1
};

export const scrollPageReducer = createSlice({
  name: "scrollPageReducer",
  initialState,
  reducers: {
    getScrollPagePosition: (state, { payload }) => {
      return {
        pageNo : payload?.pageNo
      };
    },
  },
});

export const ScrollPageActions = scrollPageReducer.actions;