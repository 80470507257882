/****************************************************
App.tsx - Root Page of the application
*****************************************************/
import React, { useEffect, useState } from "react";
import { useGetConfigurationsQuery } from "./services/configurations";
import ErrorFallback from "./components/masterpage/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
import "./App.scss";
import { API_PATHS, DEFAULT_LANG, ERROR_MESSAGES } from "./helper/constants";
import { setConfig } from "./store/actions";
import { fetchAsyncData } from "./storage/reducers/HeaderFooterDataReducer";
import {
  fetchAsyncConfig,
  fetchAsyncToken,
  fetchAsyncTokenValidation,
  //fetchAsyncSoldToValues,
  fetchAsyncPortalPermissions,
  fetchdownloadHistoryTotal,
  fetchRequestorInformation,
} from "./storage/reducers/configReducer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/pages/Layout";
import { useTranslation } from "react-i18next";
import Loader from "./components/common/Loader/Loader";
import {
  fetchAsyncLocation,
  // locationReducer,
} from "./storage/reducers/locationReducer";
import { fetchAsyncLicAgreement } from "./storage/reducers/licAgreementReducer";
import { fetchAsyncMyLibrary } from "./storage/reducers/myLibraryReducer";
import { utilAnalytics } from "./components/common/analytics/analyticsUtil";

const App = () => {
  // const [errorMessage, setErrorMessage] = useState("");
  const { i18n } = useTranslation();
  const dispatch = useDispatch<any>();
  const storeData = useSelector((state: any) => state.headerFooterData);
  const { configData, token, isHoneywellEmployee, hid } = useSelector(
    (state: any) => state.config
  );
  const sessionData = sessionStorage.getItem("persist:root");

  let tokenValidate: any = "";
  if (token?.token) {
    tokenValidate = token?.token;
  }
  //init store
  useEffect(() => {
    dispatch(fetchAsyncData());
    dispatch(fetchAsyncToken());
  }, [dispatch]);

  useEffect(() => {
    if (tokenValidate && tokenValidate != "") {
      dispatch(fetchAsyncConfig());
      dispatch(fetchAsyncTokenValidation(tokenValidate));
      if (hid != "") {
        dispatch(fetchAsyncPortalPermissions(hid));
      }
      dispatch(fetchAsyncLicAgreement());
      dispatch(fetchdownloadHistoryTotal());
      dispatch(fetchAsyncLocation());
      dispatch(fetchAsyncMyLibrary());
      dispatch(fetchRequestorInformation());
    }
  }, [tokenValidate, hid]);

  useEffect(() => {
    const interval = setInterval(() => {
      const analyticsScript = document.getElementById("analyticsUrl");
      if (analyticsScript && analyticsScript.getAttribute("src")) {
        sendInitialAnalytics();
        clearInterval(interval);
      }
    }, 2600);

    return () => clearInterval(interval);
  }, []);

  const sendInitialAnalytics = () => {
    setTimeout(() => {
      const countryNameElement = document.querySelector(".countryName");
      const countryName = countryNameElement
        ? countryNameElement.textContent
        : "";
      const { pathname, hostname, href } = window?.location;
      const onlyHostName = hostname
        .split(".")
        .find((item) => item.includes("aerotechpubs") && item);
      utilAnalytics(
        false,
        {
          eVar5: `${onlyHostName}:${pathname}`,
          pageName: `${onlyHostName}:${pathname}`,
          eVar10: `${href}`,
          eVar35: countryName ? countryName : "",
        },
        ""
      );
    }, 5000);
  };

  return (
    <>
      <React.Fragment>
        {storeData.status === "loading" ? (
          <Loader />
        ) : (
          <>
            <Router>
              <Layout></Layout>
            </Router>
          </>
        )}
      </React.Fragment>
      {/* {<ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="App">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {errorMessage?.length > 0 ? <>{errorMessage}</> : <Layout />}
          </ErrorBoundary>
        </div></ErrorBoundary> } */}
    </>
  );
};
export default App;
