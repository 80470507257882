import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  REQUEST_FORM,
  REQUEST_FORM_DATA,
  REQUEST_FORM_TEXT,
  TOPHEADERTITLE,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import TableComponent from "../TableComponent/TableComponent";
import moment from "moment";

const RequestFormSubmitModal = (props) => {
  const { t } = useTranslation();
  const buttonSelected = useSelector(
    (state: any) => state?.requestForms?.selectedQuicklink
  );
  const todayDate = new Date().toISOString().slice(0, 10);
  const data: any = [
    {
      ata : props?.tableData?.ata,
      pub_number : props?.tableData?.pub_number,
      revision_date : props?.tableData?.revision_date,
      title : props?.tableData?.title,
      unassigned_aircraft: props?.registration_number?.length > 0 ? props?.registration_number : '',
      versionNumber : props?.tableData?.version_number
    },
  ];
  return (
    <>
      <Modal
        className="favPopup addremovePopup licagreementpopup Popupwrapper submitPopup"
        aria-label="dialog Preferences"
        show={props.show}
      >
        {props.submmitingFormHandler ? (
          <div className="fetchingData-loading">
            <div className="loadermessage">
              Please wait while your request is being submitted. <br />This could take
              a few seconds.
            </div>
            {/* {" "}
                      Submmiting Form
                      <span className="loader__dot">.</span>
                      <span className="loader__dot">.</span>
                      <span className="loader__dot">.</span> */}
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>{t(REQUEST_FORM.REQUEST_SUBMITTED)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="Modal-body-liccontent">
              <div className="casedetails">
                <div>
                  <span>Case Number</span>
                  <span>
                    {props?.caseNumbers &&
                      props?.caseNumbers?.replace("-", "/")}
                  </span>
                </div>
                <div>
                  <span>{t(REQUEST_FORM.REQUEST_DATE)}</span>
                  <span> {moment(todayDate).format("YYYY/MM/DD")}</span>
                </div>
              </div>
              <TableComponent
                attributes={REQUEST_FORM_DATA}
                userData={data}
                purpose="request_form_submit"
              />
              <div className="request-information popup">
                <div className="TCcontent">
                  {buttonSelected == "request_publication" && (
                    <>
                      <h2 className="reason-request">
                        <label>{t(REQUEST_FORM.REASON_REQ)}</label>
                      </h2>
                      <span>
                        {props.reasonForReq !== ""
                          ? props.reasonForReq
                          : t(REQUEST_FORM.REQUEST_DES)}
                      </span>
                    </>
                  )}

                  <div
                    className="tandc"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        t(REQUEST_FORM_TEXT.TERMS_AND_CONIDTIONS)
                      ),
                    }}
                  ></div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="footercontainerlic Popupfooter footercontainer">
                <div className="geocheckbtns">
                  <Button
                    variant="secondary"
                    onClick={() => props.setRequestFormSubmit(false)}
                    className="cancelbtn active btn btn-secondary"
                  >
                    {t(REQUEST_FORM.CLOSE_BTN)}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default RequestFormSubmitModal;
