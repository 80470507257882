import React, { useEffect, useState } from "react";
import { ADMIN_SITE, ERROR_MSG, HTTP_STATUS_CODE, LIC_DISCREPANCY, NONLIC_DISCREPANCY, PUBLICATION_TITLE } from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import "./AdminSite.scss";
import { useSelector } from "react-redux";

const AdminSite = () => {
  const { t } = useTranslation();
  const [adminSiteData, setAdminSiteData] = useState<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [jwtToken, setJwtoken] = useState("");
  const [isLicDis, setIsLicDis] = useState<any>();

  const [errMSG, setErrorState] = useState({ falg: false, msg: "" });
  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );

  const getAccesssToken = async () => {
    try {
      const response = await fetch("/api/v1/admin-details", {
        method: "GET",
      });
      const data = await response.json();

      if (HTTP_STATUS_CODE.STATUS_200 === response.status) {
        setAdminSiteData(data.message.result);
        setJwtoken(data.message.jwtToken);
        data.message.result.licDiscrepancy === false && data.message.result.nonLicDiscrepancy === false && data.message.result.publishing === false ? setIsLicDis(false) : setIsLicDis(true);

      } else if (response.status === 204) {
        setIsLicDis(false);
      }
    } catch (error) {
      setErrorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };


  useEffect(() => {

    getAccesssToken();
  }, []);


  const getAdminSite = () => {
    if (adminSiteData.licDiscrepancy || adminSiteData.nonLicDiscrepancy || adminSiteData.publishing) {
      setShowDropdown(!showDropdown);
    }

  };

  const licDiscrepancyRedirect = (licDiscRedirect) => {
    setCookieInformation(jwtToken);
    const windowOpenUrl = licDiscRedirect;
    window.open(windowOpenUrl);
  };

  const setCookieInformation = (jwtToken) => {
    document.cookie =
      "jwtToken=" + jwtToken + ";domain=.honeywell.com;path=/;secure";
  };

  return (
    <>

      {isLicDis &&
        <div className="AdminLinks"><a onClick={getAdminSite} className={showDropdown ? "open" : 'close'}>{t(ADMIN_SITE.TITLE)}</a>
          {showDropdown &&
            <div className="AdminSiteCls">
              {adminSiteData.licDiscrepancy && <div className="licDiscrepancy"><a href="#" onClick={(e) => licDiscrepancyRedirect(configData.REPORT_DISCREPANCY_LINK_ADMIN_LIC)}>{t(ADMIN_SITE.LIC_DIS)}</a></div>}
              {adminSiteData.nonLicDiscrepancy && <div><a href="#" onClick={(e) => licDiscrepancyRedirect(configData.REPORT_DISCREPANCY_LINK_ADMIN_NONLIC)}>{t(ADMIN_SITE.LIC_NONDIS)}</a></div>}
              {adminSiteData.publishing && <div><a href="#"  onClick={(e) => licDiscrepancyRedirect(configData.PUBLICATION_TITLE_LINK)}>{t(ADMIN_SITE.PUBLICATION_TITLE)}</a></div>}
            </div>
          }
        </div>
      }


    </>
  );

};

export default AdminSite;