import React, { useEffect, useState } from "react";
import "./MyWatchList.scss";
import {
  ADD_TO_FAVOURITE_LEBELS,
  ERROR_MSG,
  FAVOURITE_TABLE,
  TABLEPAGINATION,
  WATCHLIST_POPUP,
  WATCHLIST_TABLE,
  RECORDS_PER_PAGE_SEARCH_RESULTS,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { event } from "jquery";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../common/Loader/Loader";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import TablePaginations from "../TablePaginations/TablePaginations";
import { FacetFilterActions } from "../../../storage/reducers/facetFilterReducer";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";
import moment from "moment";
import ExcelExporter from "../../common/ExcelExporter/ExcelExporter";

const MyWatchList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { configData, token } = useSelector((state: any) => state.config);
  const [watchList, setWatchList] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [showwatclistremove, setWatchlistRemove] = useState(false);
  const [loader, setLoader] = useState(false);
  const [removedPubId, setRemovedPubID] = useState();
  const [confrimFavRemove, setConfirmRemove] = useState(false);
  const [errMSgRv, setErrMsgForRv] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [totalPageNumber, setTotalPageNumber] = useState<any>([]);
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const recordsPerPage = RECORDS_PER_PAGE_SEARCH_RESULTS;

  let tokenValidate: any = "";
  if (token?.token) {
    tokenValidate = token?.token;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getWatchlist();
    dispatch(
      RequestFormAction.getRequestFormData({
        requestDataObj: "",
      })
    );
  }, []);

  const getWatchlist = async (pageNumber = 1) => {
    try {
      setLoader(true);
      const response = await fetch(
        `/api/v1/watch-list?pageNo=${pageNumber}&pageSize=${TABLEPAGINATION.RowsPerPage}`,
        {
          method: "GET",
          headers: {
            cookie:
              "OptanonAlertBoxClosed=2022-11-10T11:47:36.193Z; 2261-token=`${tokenValidate}`; OptanonConsent=isIABGlobal=false&datestamp=Thu+Nov+24+2022+18^%^3A20^%^3A11+GMT^%^2B0530+(India+Standard+Time)&version=202210.1.0&hosts=&consentId=22d538a6-47b8-49af-bd4b-b1efd51b4763&interactionCount=1&landingPath=NotLandingPage&groups=C0001^%^3A1^%^2CC0003^%^3A1^%^2CC0002^%^3A1^%^2CC0004^%^3A1&geolocation=IN^%^3BMH&AwaitingReconsent=false",
          },
        }
      );
      const data = await response.json();

      if (data?.message?.isSuccess === true) {
        setLoader(false);
        data.message.result?.watchListElasticResponse !== undefined
          ? setWatchList(data.message.result?.watchListElasticResponse)
          : setWatchList([]);

        setTotalPageNumber(
          Math.ceil(
            data?.message?.result.totalRecords / TABLEPAGINATION.RowsPerPage
          )
        );
      } else if (data?.message?.isSuccess === false) {
        setLoader(false);
        setErorState({ falg: true, msg: data?.message?.result });
      } else {
        setLoader(false);
        setErorState({ falg: true, msg: "Something Went Wrong" });
      }
    } catch (error) {
      setLoader(false);
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const addManualPublication = async (datapub, event) => {
    try {
      if (event.target.checked === false && datapub.relatedInd === false) {
        setShow(true);
      } else {
        const response = await fetch("/api/v1/watch-list", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pubId: datapub.pubId,
            isManual: event.target.checked,
            isRelated: datapub.relatedInd,
            publicationCategory: "Collection",
          }),
        });
        const data = await response.json();

        if (data?.message?.isSuccess === true) {
          const currentTodoIndex = watchList.findIndex(
            (item) => item.pubId === datapub.pubId
          );
          const updatedWT = Object.assign({}, watchList[currentTodoIndex]);
          updatedWT.manualInd = event.target.checked ? false : true;
          const updatedWatchlist = watchList.slice();
          updatedWatchlist[currentTodoIndex] = updatedWT;
          setWatchList(updatedWatchlist);
        } else if (data?.message?.isSuccess === false) {
          setErorState({ falg: true, msg: data?.message?.result });
        } else {
          setErorState({ falg: true, msg: "Something Went Wrong" });
        }
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const addReletedPublication = async (datapub, events) => {
    try {
      if (events.target.checked === false && datapub.manualInd === false) {
        setShow(true);
      } else {
        const response = await fetch("/api/v1/watch-list", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pubId: datapub.pubId,
            isManual: datapub.manualInd,
            isRelated: events.target.checked,
            publicationCategory: "Collection",
          }),
        });
        const data = await response.json();

        if (data?.message?.isSuccess === true) {
          const currentTodoIndex = watchList.findIndex(
            (item) => item.pubId === datapub.pubId
          );

          const updatedWT = Object.assign({}, watchList[currentTodoIndex]);
          updatedWT.relatedInd = events.target.checked ? false : true;

          const updatedWatchlist = watchList.slice();
          updatedWatchlist[currentTodoIndex] = updatedWT;
          setWatchList(updatedWatchlist);
        } else if (data?.message?.isSuccess === false) {
          setErorState({ falg: true, msg: data?.message?.result });
        } else {
          setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
        }
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const formatDate = (value) => {
    const date = value.split("T");
    return moment(date[0]).format("DD MMM Y");
    // const date = new Date(Date.parse(value));
    // const day = date.toLocaleString("default", { day: "2-digit" });
    // const month = date.toLocaleString("default", { month: "short" });
    // const year = date.toLocaleString("default", { year: "numeric" });
    // return day + " " + month + " " + year;
  };

  const closeErrorPopup = () => {
    setShow(false);
  };

  const confirmRemove = async () => {
    setDisabled(true);
    try {
      setLoader(true);
      const response = await fetch("/api/v1/watch-list/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: removedPubId,
        }),
      });
      const data = await response;

      if (data === undefined) {
        const dataTemp = await response.json();
        if (dataTemp !== undefined && dataTemp?.message?.isSuccess === false) {
          setErrMsgForRv("Oops! Something Went Wrong");
          setErorState({ falg: true, msg: dataTemp?.message?.result });
          setLoader(false);
        }
      }
      if (response.status === 200) {
        setLoader(false);
        setDisabled(false);
        setConfirmRemove(true);
        const changePgae =
          watchList.length == "1" && currentPage != "1"
            ? currentPage - 1
            : currentPage;
        setCurrentPage(changePgae);
        getWatchlist(changePgae);
        setWatchlistRemove(false);
      } else if (response.status === 204) {
        setLoader(false);
        setDisabled(false);
        setConfirmRemove(false);
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      } else if (response.status === 401) {
        setErorState({ falg: true, msg: t(ERROR_MSG.UNATHORIZED) });
        setLoader(false);
        setDisabled(false);
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      setLoader(false);
      setDisabled(false);
    }
  };
  const handleClose = () => {
    setConfirmRemove(false);
    setWatchlistRemove(false);
  };
  const openRemovePopup = (data) => {
    setRemovedPubID(data.pubId);
    setWatchlistRemove(true);
  };
  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };
  const parentPageChange = (page) => {
    // setTotalPageNumber(page);
    setCurrentPage(page);
    getWatchlist(page);
  };
  const OnClickOfLableLink = (data) => {
    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: "",
        facetPayloadVal: {
          queryString: data,
          pageable: {
            pageNo: 1,
            pageSize: recordsPerPage.toString(),
            sortBy: "",
            sortDir: "",
          },
        },
      })
    );
  };
  return (
    <>
      <div className="my_top_fav">
        <div className="tableComponent topfavorites mywatchlist">
          <h1>{t(WATCHLIST_TABLE.TITLE)}</h1>
          <p>{t(WATCHLIST_TABLE.DESCRIPTION)}</p>

          <div
            className={
              watchList.length > 0 ? "ExcelExporterWrapper" : "emptyWrapper"
            }
          >
            {watchList.length > 0 && (
              <ExcelExporter
                buttonTitle={t(WATCHLIST_TABLE.EXPORT_MY_NOTIFICATIONS)}
                param="my-notification"
                apiPayload={{
                  pageable: {
                    pageNo: currentPage.toString(),
                    pageSize: "5000",
                    sortBy: "",
                    sortDir: "",
                  },
                }}
                recordsCounter={recordsPerPage}
              />
            )}
          </div>

          <Card className="tablecontainer mywatchlisttable">
            <Table className="TableWrapper">
              <TableHead>
                <TableRow>
                  <TableCell>{t(FAVOURITE_TABLE.TITLE)}</TableCell>
                  <TableCell>{t(FAVOURITE_TABLE.PUBLICATION_NO)}</TableCell>
                  <TableCell>{t(WATCHLIST_TABLE.ATA_NO)}</TableCell>
                  <TableCell>{t(WATCHLIST_TABLE.REVISION_NO)}</TableCell>
                  <TableCell>{t(FAVOURITE_TABLE.TYPE)}</TableCell>
                  <TableCell>{t(WATCHLIST_TABLE.LAST_CHANGE_DATE)}</TableCell>
                  <TableCell>{t(WATCHLIST_TABLE.MANUALS)}</TableCell>
                  <TableCell>{t(WATCHLIST_TABLE.RELATED_PUBS)}</TableCell>
                  <TableCell>{t(WATCHLIST_TABLE.DELETE)}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!loader && watchList?.length === 0 && (
                  <TableCell colSpan={9}>
                    {watchList.length === 0 ? (
                      <h1>{t(FAVOURITE_TABLE.NO_RECORD_FOUND)}</h1>
                    ) : (
                      <h1>{errMSG.msg}</h1>
                    )}
                  </TableCell>
                )}
                {watchList.map((data, index) => (
                  <TableRow className="datarow" key={index}>
                    <>
                      <TableCell className="pubtitlecell">
                        <Link
                          to="/"
                          aria-label="PubTitle"
                          // state={data.pubNumber}
                          onClick={() => {
                            OnClickOfLableLink(data.pubNumber);
                          }}
                        >
                          {" "}
                          {data.pubTitle.length <= 40
                            ? `${data.pubTitle}`
                            : `${data.pubTitle.substring(0, 40) + "..."}`}
                        </Link>
                      </TableCell>
                      <TableCell>{data.pubNumber}</TableCell>
                      <TableCell>{data.ataNumber}</TableCell>
                      <TableCell> {data.revNumber}</TableCell>
                      <TableCell>{data.pubTypeName}</TableCell>
                      <TableCell className="dateformat">
                        {formatDate(data.updatedDate)}
                      </TableCell>
                      <TableCell className="checkboxcell">
                        <div
                          className={
                            data.manualInd
                              ? "leftcheckbox checked"
                              : "leftcheckbox"
                          }
                        >
                          <input
                            aria-label="Type Preferences"
                            type="checkbox"
                            onChange={(e) => addManualPublication(data, e)}
                            checked={data.manualInd ? true : false}
                          />
                        </div>
                      </TableCell>
                      <TableCell className="checkboxcell">
                        <div
                          className={
                            data.relatedInd
                              ? "leftcheckbox checked"
                              : "leftcheckbox"
                          }
                        >
                          <input
                            aria-label="Type Preferences"
                            type="checkbox"
                            onClick={(e) => addReletedPublication(data, e)}
                            checked={data.relatedInd ? true : false}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <a
                          href="#"
                          className="removeicon"
                          aria-label="RemoveIcon"
                          onClick={() => openRemovePopup(data)}
                        ></a>
                      </TableCell>
                    </>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
        {totalPageNumber > 1 && (
          <TablePaginations
            count={totalPageNumber}
            currentPage={currentPage}
            parentPageChange={parentPageChange}
          />
        )}
      </div>
      <>
        <ErrorModal
          errorMsg={errMSG.msg}
          errorFlag={errMSG.falg}
          onClose={onChange}
        />
      </>
      <Modal
        className="favPopup addremovePopup licagreementpopup Popupwrapper"
        aria-label="dialog Preferences"
        show={show}
      >
        <div className="modalbodycontentlic modalbodycontentPopups  modalbodycontent">
          <Modal.Body className="Modal-body-liccontent">
            <p>{t(WATCHLIST_POPUP.ERROR_WATCHLIST_MSG)}</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footercontainerlic Popupfooter footercontainer">
              <div className="geocheckbtns">
                <div className="rightbtn">
                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="submitbtn active btn btn-primary"
                    variant="secondary"
                    onClick={closeErrorPopup}
                  >
                    {t(ADD_TO_FAVOURITE_LEBELS.CLOSE)}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        className="modal-wrapper favPopup addremovePopup licagreementpopup Popupwrapper"
        aria-label="lic poppup"
        show={showwatclistremove}
        id="lic_popup"
      >
        {loader && <Loader />}
        <div className="modalbodycontent modalforwatchlist">
          <Modal.Header>
            <Modal.Title className="">
              <div className="removewatchlistTitle">
                {t(WATCHLIST_POPUP.REMOVE_WATCHLIST)}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="stopemailbody">
              {t(WATCHLIST_POPUP.REMOVEWATCHLISTBODY)}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="footercontainerlic Popupfooter footercontainer">
              <div className="geocheckbtns">
                <div className="rightbtn">
                  <>
                    <Button
                      type="button"
                      aria-label="Close Preferences"
                      className="cancelbtn active btn btn-secondary"
                      variant="secondary"
                      onClick={confirmRemove}
                    >
                      {t(WATCHLIST_POPUP.REMOVE_WATCHLIST_BTN)}
                    </Button>
                  </>

                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="submitbtn active btn btn-primary"
                    variant="secondary"
                    onClick={handleClose}
                    disabled={disabled ? true : false}
                  >
                    {t(WATCHLIST_POPUP.BACK_BUTTON)}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default MyWatchList;
