//Configurations api calls 
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const configApi = createApi({
    reducerPath: 'configApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '',
    }),
    endpoints: (builder) => ({
        getConfigurations: builder.query<{ response?: any, isSuccess: any,result: any }, any>({
            query: (type) => `${type}`,
            transformResponse: (response: { message: any }) => response.message,
        }),
    }),

});

export const { useGetConfigurationsQuery } = configApi;