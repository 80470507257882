import React from "react";
import "./CalendarFooter.scss";
import { LABELKEYS_SEARCH_RESULTS } from "../../../../helper/constants";
import { useTranslation } from "react-i18next";

interface CalendarFooterProps {
  onToday: () => void;
  onCancel: () => void;
  onApply: () => void;
  validDates: boolean;
  closeCalendar: (value: boolean) => void;
}

const CalendarFooter: React.FC<CalendarFooterProps> = ({
  onToday,
  onCancel,
  onApply,
  validDates,
  closeCalendar,
}) => {
  const { t } = useTranslation();

  const handleOnApply = () => {
    onApply();
    closeCalendar(false);
  };

  return (
    <div className="CalendarFooter">
      <div className="todaySection">
        <button className="today todayBtn" onClick={onToday}>
          {t(LABELKEYS_SEARCH_RESULTS.CALENDAR_TODAY)}
        </button>
      </div>
      <div className="applyAndCancelSection">
        <button className="cancelBtn" onClick={onCancel}>
          {t(LABELKEYS_SEARCH_RESULTS.CALENDAR_CANCEL)}
        </button>
        <button
          className="p-button p-button-text p-button-outlined applyBtn"
          onClick={handleOnApply}
          disabled={!validDates}
        >
          {t(LABELKEYS_SEARCH_RESULTS.CALENDAR_APPLY)}
        </button>
      </div>
    </div>
  );
};

export default CalendarFooter;
