import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  contracts: [],
  isContractsLoading: false,
};

export const fetchAsyncContractsDetails = createAsyncThunk(
  "Contract/Details",
  async (msp_token) => {
    try {
      const response = await fetch(
        "/api/v1/contract/details",

        {
          method: "POST",
          headers: {
            authorization: `Bearer ${msp_token}`,
            "Content-Type": "application/json",
          },
          body: "",
        }
      );

      const data = await response.json();
      if (response.status === 200) {
        return data?.message?.result?.results;
      }
    } catch (error) {
    }
  }
);

export const contractDetailsReducer = createSlice({
  name: "contracts",
  initialState,
  extraReducers: {
    [fetchAsyncContractsDetails.pending]: (state, action) => {
      state.isContractsLoading = true;
    },
    [fetchAsyncContractsDetails.fulfilled]: (state, { payload }) => {
      return { ...state, contracts: payload, isContractsLoading: false };
    },
    [fetchAsyncContractsDetails.rejected]: (state, action) => {
      state.isContractsLoading = false;
    },
  },
});

export const contractDetailsActions = contractDetailsReducer.actions;
