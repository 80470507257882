import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import {
  ERROR_MSG,
  HTTP_STATUS_CODE,
  MY_LIB_DATA,
  RECENT_DOWNLOAD,
  TOPHEADERTITLE,
  RECENT_DOWNLOAD_VIEW_SHOW_COUNT
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import "./RecentlyDownload.scss";
import moment from "moment";
import RequestDownloadModal from "./RequestDownloadModal";

const RecentlyDownload = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [loader, setLoader] = useState(true);
  const [downloadHistoryData, setdownloadHistoryData] = useState<any>([]);
  const [totalRecords,setTotalRecords] = useState(0);
  const [addfav, setAddFav] = useState("");
  const [addFavFlag, setAddFavFlag] = useState(false);
  const [errMSG, setErrorState] = useState({ flag: false, msg: "" });
 

  const getRecentlyDownload = async () => {
    setLoader(true);
    try {
      const response = await fetch("/api/v1/download-history?isTopFive=true", {
        method: "GET",
      });
      const data = await response.json();
      setLoader(false);
      if (data?.message?.isSuccess === true) {
        if(data?.message?.result?.downloadElasticResponse){
          setdownloadHistoryData(
            data?.message?.result?.downloadElasticResponse
          );
          setTotalRecords(data?.message?.result?.totalRecords);
        }else{
          setdownloadHistoryData([]);
          setTotalRecords(0);
        }
         
      } else if (data?.message?.isSuccess === false) {
        setErrorState({ flag: true, msg: data?.message?.result });
      } else {
        setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setLoader(false);
      //setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const addFavorite = async (pubid) => {
    // setAddFav('addfav');
    try {
      const response = await fetch("/api/v1/favorite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: pubid,
          isTopFavourite: false,
        }),
      });

      if (HTTP_STATUS_CODE.STATUS_200 === response.status) {
        addRemoveFavButtonChange(pubid, true);
      } else if (response.status === 401) {
      }
    } catch (error) { }
  };

  const addRemoveFavButtonChange = (pubid, favFlag) => {
    const historyIndex = downloadHistoryData.findIndex(
      (historyData) => historyData.pubId == pubid
    );
    const historyDataObj: any = [...downloadHistoryData];
    const historyRecord = historyDataObj[historyIndex];
    historyRecord["favouriteFlag"] = favFlag;
    historyDataObj[historyIndex] = historyRecord;
    setdownloadHistoryData(historyDataObj);
  };

  const confirmRemove = async (pubid) => {
    //setAddFav('removefav');
    setAddFavFlag(false);
    try {
      const response = await fetch(`/api/v1/favorite/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: pubid,
        }),
      });
      if (
        HTTP_STATUS_CODE.STATUS_200 === response.status ||
        HTTP_STATUS_CODE.STATUS_204 === response.status
      ) {
        addRemoveFavButtonChange(pubid, false);
      } else {
      }
    } catch (error) { }
  };
  const addRemoveFavoriteHandler = (pubId, indicator) => {
    indicator == "add" ? addFavorite(pubId) : confirmRemove(pubId);
  };

  useEffect(() => {
    getRecentlyDownload();
  }, []);

  const onChange = () => {
    setErrorState({ flag: false, msg: "success" });
  };

  return (
    <>
      {downloadHistoryData?.length > 0 && (<TableComponent
        attributes={RECENT_DOWNLOAD}
        topHeader={TOPHEADERTITLE.RECENT_DOWNLOAD_TITLE}
        userData={downloadHistoryData}
        loaderFlag={loader}
        addfavclass={addfav}
        viewAll={totalRecords > RECENT_DOWNLOAD_VIEW_SHOW_COUNT ? true : false}
        recentDowloadPopup={true}
        addRemoveFavoriteHandler={(pubid, favIndicator) =>
          addRemoveFavoriteHandler(pubid, favIndicator)
        }
      />)}

      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.flag}
        onClose={onChange}
      />
    </>
  );
};

export default RecentlyDownload;
