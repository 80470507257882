import React, { useState, useEffect } from "react";
import moment from "moment";
import "./CalendarInput.scss";
import calendarIcon from "../FilterFacets/images/calendericon.svg";
import {
  formatInputValueWhenUserTypes,
  isValidDateRange,
} from "./CalendarActions";

interface IInputProps {
  setOpenCalendar: (value: (prevState: boolean) => boolean) => void;
  dates: Array<any> | null;
  validDates: boolean;
  setDates: (value: Array<any> | null) => void;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

const CalendarInput: React.FC<IInputProps> = ({
  setOpenCalendar,
  dates,
  validDates,
  setDates,
  startDate,
  endDate,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [validInputValue, setValidInputValue] = useState<boolean>(false);
  const [externalPlaceHolderVisible, setExternalPlaceHolderVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (validInputValue) {
      setDates([
        moment(inputValue.split(" - ")[0]).toDate(),
        moment(inputValue.split(" - ")[1]).toDate(),
      ]);
    }
  }, [validInputValue]);

  useEffect(() => {
    if (dates === null) {
      setInputValue("");
    } else if (validDates) {
      setInputValue(
        `${moment(dates[0]).format("YYYY-MM-DD")} - ${moment(dates[1]).format(
          "YYYY-MM-DD"
        )}`
      );
      setValidInputValue(true);
    }
  }, [dates, validDates]);

  const handleOnChange = (event: any) => {
    const rawValue = event.target.value;

    if (event.nativeEvent.inputType === "deleteContentBackward") {
      setInputValue(rawValue);
      setValidInputValue(false);
      return;
    }

    const formattedValue = formatInputValueWhenUserTypes(rawValue);
    setInputValue(formattedValue);

    const isValidFormat = /^\d{4}-\d{2}-\d{2} - \d{4}-\d{2}-\d{2}$/.test(
      formattedValue
    );
    if (isValidFormat) {
      const [startDateStr, endDateStr] = formattedValue.split(" - ");
      const isValidDates = isValidDateRange(startDateStr, endDateStr);
      setValidInputValue(isValidDates);
    } else {
      setValidInputValue(false);
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setExternalPlaceHolderVisible(false);
    if (inputValue === "" && validDates && dates !== null) {
      setInputValue(
        `${moment(dates[0]).format("YYYY-MM-DD")} - ${moment(dates[1]).format(
          "YYYY-MM-DD"
        )}`
      );
      setValidInputValue(true);
    } else if (inputValue.length < 23 && validDates && dates !== null) {
      setInputValue(
        `${moment(dates[0]).format("YYYY-MM-DD")} - ${moment(dates[1]).format(
          "YYYY-MM-DD"
        )}`
      );
      setValidInputValue(true);
    } else if (
      inputValue !== "" &&
      dates === null &&
      startDate === null &&
      endDate === null
    ) {
      setInputValue("");
    }
  };

  return (
    <div
      className="CalendarInputWrapper"
      onClick={() => setOpenCalendar((prevState: boolean) => !prevState)}
    >
      <input
        readOnly
        id="calendarCustomInput"
        className={`CalendarInput ${
          !validInputValue && inputValue && "invalid"
        }`}
        value={inputValue}
        placeholder="YYYY-MM-DD - YYYY-MM-DD"
        autoComplete="off"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={() => {
          setExternalPlaceHolderVisible(true);
        }}
      />
      <img src={calendarIcon} alt="CalendarIcon" className="CalendarIcon" />
    </div>
  );
};

export default CalendarInput;
