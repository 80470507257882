import React, { useEffect, useState } from "react";
import {
  ERROR_MSG,
  MY_LIBRARY,
  MY_LIB_DATA,
  TABLEPAGINATION,
  TOPHEADERTITLE,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import TableComponent from "../TableComponent/TableComponent";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import "./MyLibrary.scss";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, TablePagination } from "@mui/material";
import TablePaginations from "../TablePaginations/TablePaginations";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";

const MyLibrary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [post, setPost] = useState<any>([]);
  const [totalRecordCount, settotalRecordCount] = useState<any>([]);
  const [totalPageNumber, setTotalPageNumber] = useState<any>([]);
  const [errMSG, setErrorState] = useState({ flag: false, msg: "" });
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [pagniationFlag, setPaginationFlag] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  // API CALL
  const fetchData = async (pageNumber = 1) => {
    setLoader(true);
    try {
      const response = await fetch(`/api/v1/library`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pageNo: pageNumber,
          pageSize: TABLEPAGINATION.RowsPerPage,
          searchParameter: searchValue,
        }),
      });
      const data = await response.json();
      setLoader(false);
      if (
        data?.message?.isSuccess === true &&
        data?.message?.result?.result !== undefined
      ) {
        setPost(data?.message?.result?.result);
        settotalRecordCount(data?.message?.result);
        setTotalPageNumber(
          Math.ceil(
            data?.message?.result.totalRecordsFromBMS /
              TABLEPAGINATION.RowsPerPage
          )
        );
      } else if (
        data?.message?.isSuccess === true &&
        data?.message?.result?.result === undefined
      ) {
        setPost([]);
        setTotalPageNumber(0);
      } else if (data?.message?.isSuccess === false) {
        setErrorState({ flag: true, msg: data?.message?.result });
        setTotalPageNumber(0);
      } else {
        setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
        setTotalPageNumber(0);
      }
    } catch (error) {
      setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
      setLoader(false);
    }
  };

  const onChange = () => {
    setErrorState({ flag: false, msg: "sucsess" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    dispatch(
      RequestFormAction.getRequestFormData({
        requestDataObj: "",
      })
    );
  }, []);

  const parentPageChange = (page) => {
    // setTotalPageNumber(page);
    fetchData(page);
    setCurrentPage(page);
  };

  const handleChange = async (e) => {
    setSearchValue(e.target.value);
    setInputValue(e.target.value);
    setCurrentPage(1);
    if (e.target.value === "") {
    }
    if (e.key === "Enter") {
      await fetchData();
      e.target.value === "" ? setPaginationFlag(true) : setPaginationFlag(true);
    }
  };

  return (
    <>
      <div>
        <TableComponent
          attributes={MY_LIB_DATA}
          topHeader={TOPHEADERTITLE.MY_LIB_TITLE}
          userData={post}
          isSearch={true}
          inputValue={inputValue}
          searchHandleChange={handleChange}
          loaderFlag={loader}
          purpose="my_subscription"
        />
        {totalPageNumber > 1 && pagniationFlag && (
          <TablePaginations
            count={totalPageNumber}
            parentPageChange={parentPageChange}
            currentPage={currentPage}
          />
          // <Pagination
          //   className="myfavpagination"
          //   count={totalPageNumber}
          //   page={controller.page}
          //   onChange={handlePageChange}
          //   hidePrevButton={false}
          //   hideNextButton={false}
          //   showFirstButton={true}
          //   showLastButton={true}
          //   boundaryCount={0}
          //   shape={"rounded"}
          //   color={"primary"}
          // />
        )}
      </div>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.flag}
        onClose={onChange}
      />
    </>
  );
};

export default MyLibrary;
