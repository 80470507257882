import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FilterFacets.scss";
import Accordion from "react-bootstrap/Accordion";
import {
  RELEASE_DATE_VALUES,
  ERROR_MSG,
  APPLY_FILTER,
  RELEASE_DATE_KEY,
  RELEASE_DATE_RANGE,
  FACET_LABELS,
  RELEASE_START_DATE,
  RELEASE_END_DATE,
  DATE_RANGE,
  PUB_CATEGORY_DISABLE_ARR,
  RECORDS_PER_PAGE_SEARCH_RESULTS,
} from "../../../../helper/constants";
import ErrorModal from "../../../common/ErrorModal/ErrorModal";
import AppliedFilter from "../AppliedFilter/AppliedFilter";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FacetFilterActions } from "../../../../storage/reducers/facetFilterReducer";
import { DeepFacetFilterActions } from "../../../../storage/reducers/deepFilterFacets";
import SecondLevelFilter from "../SecondLevelFilter/SecondLevelFilter";
import ThirdLevelFilter from "../ThirdLevelFilter/ThirdLevelFilter";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { start } from "repl";
import Calendar from "../CalendarFilter/Calendar";

/*eslint-disable*/

const FilterFacets = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [listproduct, setListProduct] = useState<any>([]);
  const [errMSG, setErrorState] = useState({ falg: false, msg: "" });
  const [selectedFacets, setSelectedFacets] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [secondLevelData, SetSecondLevelData] = useState<any>([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [currentFacet, setCurrentFacet] = useState<any>({});
  const [dates, setDates] = useState<any>([]);
  const [deepLevelFacets, setDeepLvelFacets] = useState<any>([]);
  const [disableRadio, setDisbableRadio] = useState(false);
  const [disableCalender, setDisableCalender] = useState<boolean>(false);
  const [defaultPage, setDefaultPage] = useState(1);
  const recordsPerPage: any = RECORDS_PER_PAGE_SEARCH_RESULTS;
  const [pubCategoryDisable, setPubCategoryDisable] = useState(false);
  const facetObjStateVal = useSelector((state: any) => {
    return state.filteredFacets.facetObj;
  });
  const selectedFactesStateVal = useSelector((state: any) => {
    return state.filteredFacets.selectedFactes;
  });
  const deepFacetLevells = useSelector((state: any) => {
    return state.deepFacetLevels.deepLevel;
  });

  const todaysDate = new Date();

  useEffect(() => {
    setSelectedFacets({});
    setDisabled(true);

    setDates(new Date().toISOString().split("T")[0]);
    if (facetObjStateVal !== "" && facetObjStateVal !== undefined) {
      setSelectedFacets(facetObjStateVal);
      //enableDisablePubCategory(facetObjStateVal);
      Object.values(facetObjStateVal).flat(1).length > 0
        ? setDisabled(false)
        : setDisabled(true);
      // CheckboxFilterApi(selectedFactesStateVal);
      SetSecondLevelData(props.filterFacetValue);
    }

    if (deepFacetLevells != "" && deepFacetLevells !== undefined) {
      setDeepLvelFacets(deepFacetLevells);
    }
  }, [props.filterFacetValue, facetObjStateVal, deepFacetLevells]);

  const facetSelectDeselect = (value, facetType, level, checked) => {
    props.resetToDefaultPage();
    var facetObj = { ...selectedFacets };
    var currentFacetObj = { ...currentFacet };
    var deepLevelFacetObj = { ...deepLevelFacets };
    if (facetObj[level] === undefined || facetType == RELEASE_DATE_KEY) {
      facetObj[level] = [];
    }
    const facetConstObjArr = findByFacetKey(FACET_LABELS[level].facetKey);
    if (checked === true) {
      if (facetType != RELEASE_DATE_KEY) {
        currentFacetObj = {
          facetType: facetType,
          facetLevelKey: level,
          levelValue: FACET_LABELS[level].level,
          deelpevel: facetConstObjArr.deepLevel,
          deepLevelKey: facetConstObjArr.deepLevelKey,
        };
        setCurrentFacet(currentFacetObj);
      }
      const pushFacetArray = [...facetObj[level]];
      pushFacetArray.push(value);
      facetObj[level] = pushFacetArray;
    } else {
      currentFacetObj = "";
      setCurrentFacet(currentFacetObj);
      if (Array.isArray(value)) {
        if (value.length > 0) {
          for (var i = 0; i < value.length; i++) {
            facetObj = removeFacetValue(value[i], facetObj, level);
          }
        }
      } else {
        facetObj = removeFacetValue(value, facetObj, level);
      }
    }

    if (facetObj[level].length == 0) {
      props?.filterFacetAttributes?.map((attribute, index) => {
        if (attribute.facetFirstLevel == level) {
          delete facetObj[attribute.facetSecondLevelField];
          if (attribute.facetKey != RELEASE_DATE_KEY) {
            delete deepLevelFacetObj[facetConstObjArr.facetKey];
          }
          delete facetObj[attribute.facetThirdLevelField];
        }
        if (attribute.facetSecondLevelField == level) {
          delete facetObj[attribute.facetThirdLevelField];
          if (
            attribute.facetKey != RELEASE_DATE_KEY &&
            attribute.deepLevel == "3"
          ) {
            delete deepLevelFacetObj[facetConstObjArr.facetKey];
          }
        }
        //pub category disable commit as per feedback of PO
        // if (PUB_CATEGORY_DISABLE_ARR.includes(level)) {
        //   if (
        //     (facetObj[PUB_CATEGORY_DISABLE_ARR[0]]?.length === 0 || facetObj[PUB_CATEGORY_DISABLE_ARR[0]]===undefined) &&
        //     (facetObj[PUB_CATEGORY_DISABLE_ARR[1]]?.length === 0 ||  facetObj[PUB_CATEGORY_DISABLE_ARR[1]]===undefined)
        //   ) {
        //     let pubCatObj = props?.filterFacetAttributes.find((category) => category.defaultDisabled === true);
        //    // setPubCategoryDisable(true);
        //     delete facetObj[pubCatObj.facetFirstLevel];
        //     delete facetObj[pubCatObj.facetSecondLevelField];
        //     delete deepLevelFacetObj[pubCatObj.facetKey];
        //   }
        // }
      });
    }

    // enable disable publication category facets
    //enableDisablePubCategory(facetObj);
    setSelectedFacets(facetObj);
    setDeepLvelFacets(deepLevelFacetObj);
    //Disabling and enabling apply button
    Object.values(facetObj).flat(1).length > 0
      ? setDisabled(false)
      : setDisabled(true);

    getApplyFilterPayload(facetObj, currentFacetObj, deepLevelFacetObj);
  };

  const enableDisablePubCategory = (facets) => {
    const facetDetails = Object.keys(facets);
    setPubCategoryDisable(true);
    PUB_CATEGORY_DISABLE_ARR.forEach(function (currentElement) {
      if (
        facetDetails.includes(currentElement) &&
        facets[currentElement]?.length > 0
      ) {
        setPubCategoryDisable(false);
      }
    });
  };

  const getCalenderDateRange = ({ startDate, endDate }) => {
    setDisbableRadio(true);
    setStartDate(startDate);
    setEndDate(endDate);
    const releaseDateFacetObj = findByFacetKey(RELEASE_DATE_KEY);

    if (
      startDate !== "" &&
      startDate !== undefined &&
      endDate !== "" &&
      endDate !== undefined &&
      endDate !== null &&
      startDate !== null
    ) {
      startDate = moment(startDate).format("YYYY-MM-DD");
      endDate = moment(endDate).format("YYYY-MM-DD");
      facetSelectDeselect(
        startDate + " to " + endDate,
        RELEASE_DATE_KEY,
        releaseDateFacetObj.facetFirstLevel,
        true
      );
    }
  };

  const removeFacetValue = (value, facetObj, level) => {
    if (level == RELEASE_DATE_RANGE) {
      setDisableCalender(false);
      setDisbableRadio(false);
      setStartDate(null);
      setEndDate(null);
    }

    const index = facetObj[level].indexOf(value);

    if (index > -1) {
      // only splice array when item is found
      const SplicedValue = [...facetObj[level]];

      SplicedValue.splice(index, 1); // 2nd parameter means remove one item only
      facetObj[level] = SplicedValue;
    }

    return facetObj;
  };

  const releaseDateRangeChange = (e) => {
    setDisableCalender(true);
    const releaseDateFacetObj = findByFacetKey(RELEASE_DATE_KEY);
    facetSelectDeselect(
      parseInt(e.target.value),
      RELEASE_DATE_KEY,
      releaseDateFacetObj.facetFirstLevel,
      true
    );
  };

  const findFacetName = (data) => {
    var facetDetail: any = {};
    let level = FACET_LABELS[data].level;
    switch (level) {
      case 1:
        facetDetail = props.filterFacetAttributes.find(
          (item) => item.facetFirstLevel === data
        );
        return {
          facetName: facetDetail.facetKey,
          facetField: facetDetail.facetFirstLevel,
        };
        break;
      case 2:
        facetDetail = props.filterFacetAttributes.find(
          (item) => item.facetSecondLevelField === data
        );
        return {
          facetName: facetDetail.facetSecondLevel,
          facetField: facetDetail.facetSecondLevelField,
        };
        break;
      case 3:
        facetDetail = props.filterFacetAttributes.find(
          (item) => item.facetThirdLevelField === data
        );
        return {
          facetName: facetDetail.facetThirdLevel,
          facetField: facetDetail.facetThirdLevelField,
        };
        break;
    }

    return facetDetail;
  };

  const findByFacetKey = (type) => {
    const facetConstObj = props?.filterFacetAttributes?.find(
      (facets) => facets.facetKey === type
    );

    return facetConstObj;
  };
  //Payload for Apply filter
  const getApplyFilterPayload = async (
    facetObj,
    currentFacetObj,
    deepLevelFacetObj
  ) => {
    const generatePayload: any = [];
    const payload = Object.keys(facetObj).map((el) => {
      if (el != RELEASE_DATE_RANGE && facetObj[el].length > 0) {
        let applyFilterPaylodObj = getSelectedFacetObj(
          findFacetName(el),
          facetObj[el]
        );
        generatePayload.push(applyFilterPaylodObj);
      }
    });

    const facetPayload = getPayloadData(generatePayload, facetObj);
    let responseData: any = await CheckboxFilterApi(
      facetPayload,
      currentFacetObj,
      deepLevelFacetObj
    );
    props.getFilteredSearchData(responseData);
    const dispatchFacetObj = { ...facetObj };

    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: facetObj,
        facetPayloadVal: facetPayload,
      })
    );
    return facetPayload;
  };

  const getSelectedFacetObj = (facetType, val) => {
    const facetSelObj = {
      facetName: facetType.facetName,
      facetField: facetType.facetField,
      facetsSelectedValue: val,
    };

    return facetSelObj;
  };

  const getPayloadData = (facets, facetObj) => {
    const payload = {
      queryString: props.searchVal,
      pageable: {
        pageNo: defaultPage.toString(),
        pageSize: recordsPerPage.toString(),
        sortBy: "",
        sortDir: "",
      },
      selectedFacets: facets,
    };
    if (
      facetObj[RELEASE_DATE_RANGE] !== undefined &&
      facetObj[RELEASE_DATE_RANGE].length > 0
    ) {
      if (
        typeof facetObj["range"][0] != "number" &&
        facetObj[RELEASE_DATE_RANGE][0].includes(" to ")
      ) {
        let dateSplit = facetObj[RELEASE_DATE_RANGE][0].split(" to ");
        payload[RELEASE_START_DATE] = dateSplit[0];
        payload[RELEASE_END_DATE] = dateSplit[1];
      } else {
        payload[RELEASE_DATE_RANGE] = facetObj[RELEASE_DATE_RANGE][0];
      }
    }
    return payload;
  };

  const CustomCloseIcon = () => (
    <div className="CalenderCancel">
      <span
        onClick={() => setFocusedInput(null)}
        className="calander-closeIcon"
      >
        X
      </span>
    </div>
  );
  const addRemoveFacetDeepLevelVal = (
    currentFacets,
    facetResponse,
    deepLevelFacetObj
  ) => {
    var deepLevelFacetObj = { ...deepLevelFacetObj };
    if (facetResponse[currentFacets.deepLevelKey] !== undefined) {
      deepLevelFacetObj[currentFacets.facetType] = [];
      deepLevelFacetObj[currentFacets.facetType].push(
        facetResponse[currentFacets.deepLevelKey]
      );
    }
    setDeepLvelFacets(deepLevelFacetObj);

    dispatch(
      DeepFacetFilterActions.getDeepLevelFacets({
        deepLevel: deepLevelFacetObj,
      })
    );
  };

  const CheckboxFilterApi = async (
    payload,
    currentFacetObj,
    deepLevelFacetObj
  ) => {
    try {
      const response = await fetch("/api/v1/search/search-results", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        if (
          currentFacetObj?.levelValue != currentFacetObj.deelpevel ||
          currentFacetObj == ""
        ) {
          addRemoveFacetDeepLevelVal(
            currentFacetObj,
            data.message.result.aggregations,
            deepLevelFacetObj
          );
        }
        SetSecondLevelData(data.message.result.aggregations);
        props.onFilterFacetPaginate(data.message?.result?.hits?.total?.value);
        return data.message.result;
      } else if (data?.message?.isSuccess === false) {
        setErrorState({ falg: true, msg: data?.message?.result });
      } else {
        setErrorState({ falg: true, msg: data?.message?.result });
      }
    } catch (error) {
      setErrorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const onChange = () => {
    setErrorState({ falg: false, msg: "sucsess" });
  };

  const clearAllFilterFacets = () => {
    var facetObj = { ...selectedFacets };
    facetObj = {};
    setSelectedFacets(facetObj);
    setDisabled(false);
    //setPubCategoryDisable(true);
    setDisableCalender(false);
    setDisbableRadio(false);
    setStartDate(null);
    setEndDate(null);
    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: "",
        facetPayloadVal: "",
      })
    );

    getApplyFilterPayload(facetObj, {}, "");
    dispatch(
      DeepFacetFilterActions.getDeepLevelFacets({
        deepLevel: "",
      })
    );
  };
  const firstLevelCheckboxes = (facetAttribute, facetValueObj, index) => {
    return (
      <>
        <p
          className={
            selectedFacets[facetAttribute.facetFirstLevel]?.length > 0 &&
            !selectedFacets[facetAttribute.facetFirstLevel].includes(
              facetValueObj.key
            )
              ? "d-none"
              : ""
          }
          key={index}
        >
          <div className="accordionitem">
            <div
              className={
                selectedFacets[facetAttribute.facetFirstLevel]?.includes(
                  facetValueObj.key
                )
                  ? "leftcheckbox checked"
                  : "leftcheckbox"
              }
            >
              <input
                aria-label="filter values"
                type="checkbox"
                onChange={(e) => {
                  facetSelectDeselect(
                    facetValueObj.key,
                    facetAttribute.facetKey,
                    facetAttribute.facetFirstLevel,
                    e.target.checked
                  );
                }}
                value={facetValueObj?.key}
                checked={
                  selectedFacets[facetAttribute.facetFirstLevel]?.includes(
                    facetValueObj.key
                  )
                    ? true
                    : false
                }
              />
            </div>
          </div>
          <label aria-label="pub category">{facetValueObj?.key}</label>
        </p>
        <>
          <SecondLevelFilter
            secondFacetFilter={secondLevelData && secondLevelData}
            currentFacetVal={currentFacet}
            facetType={selectedFacets[facetAttribute.facetFirstLevel]}
            SelectedValue={selectedFacets}
            facetAttribute={facetAttribute}
            deepLevelFacets={deepLevelFacets}
            facetSelectDeselect={facetSelectDeselect}
          ></SecondLevelFilter>
        </>

        {facetAttribute.thirdLevel && (
          <ThirdLevelFilter
            thirdFacetFilter={secondLevelData && secondLevelData}
            firstFacetType={selectedFacets[facetAttribute.facetFirstLevel]}
            secondFacetType={
              selectedFacets[facetAttribute.facetSecondLevelField]
            }
            facetsSelected={selectedFacets}
            facetAttribute={facetAttribute}
            deepLevelFacets={deepLevelFacets}
            facetSelectDeselect={facetSelectDeselect}
          />
        )}
      </>
    );
  };

  const clearPropsDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <div className="saerch-facet-main SearchfacetWrapper">
        {!disabled && (
          <AppliedFilter
            selectedFacets={selectedFacets}
            facetSelectDeselect={facetSelectDeselect}
            clearAllFilterFacets={clearAllFilterFacets}
          />
        )}

        {props?.filterFacetAttributes?.map((facetAttribute, facetIndex) => {
          return (
            <>
              {selectedFacets[facetAttribute.facetFirstLevel]?.length > 0 && (
                <Accordion
                  key={facetIndex}
                  className={facetAttribute?.facetClass}
                  style={{ display: "none" }}
                  defaultActiveKey={facetIndex}
                ></Accordion>
              )}
              <Accordion
                key={
                  pubCategoryDisable === true &&
                  facetAttribute?.defaultDisabled === true
                    ? facetIndex
                    : ""
                }
                alwaysOpen
                className={`${
                  facetAttribute?.facetClass ? facetAttribute?.facetClass : ""
                }${
                  facetAttribute?.defaultDisabled &&
                  facetAttribute?.defaultDisabled === true &&
                  pubCategoryDisable === true
                    ? facetAttribute?.disabledClass
                    : ""
                }`}
                defaultActiveKey={
                  selectedFacets[facetAttribute.facetFirstLevel]?.length > 0 ||
                  (pubCategoryDisable === true &&
                    facetAttribute?.defaultDisabled === true)
                    ? facetIndex
                    : ""
                }
              >
                <Accordion.Item
                  eventKey={
                    pubCategoryDisable === true &&
                    facetAttribute?.defaultDisabled === true
                      ? facetIndex
                      : ""
                  }
                >
                  <Accordion.Header>
                    <div className="facet-section">
                      <span className="heading-c">
                        {t(facetAttribute?.facetLabel)}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body
                    className={facetAttribute.class ? facetAttribute.class : ""}
                  >
                    {facetAttribute?.facetKey === RELEASE_DATE_KEY && (
                      <>
                        <p className="customDateLable">
                          {t(DATE_RANGE.SELECT_DATE_RANGE)}
                        </p>
                        <Calendar
                          clearCalendar={disableCalender}
                          keepCalendarDatesActive={() => {
                            setDisableCalender(false);
                          }}
                          startDate={startDate}
                          endDate={endDate}
                          clearPropsDates={clearPropsDates}
                          onDatesChange={getCalenderDateRange}
                        />
                      </>
                    )}

                    {facetAttribute?.facetKey === RELEASE_DATE_KEY
                      ? RELEASE_DATE_VALUES.map((dates) => (
                          <p key={dates?.value}>
                            <div className="accordionitem">
                              <div className="radioLeft">
                                <input
                                  aria-label="filter-radio values"
                                  type="radio"
                                  value={dates?.value}
                                  name={dates?.value}
                                  onChange={(e) => {
                                    releaseDateRangeChange(e);
                                  }}
                                  checked={
                                    selectedFacets[
                                      facetAttribute?.facetFirstLevel
                                    ]?.includes(dates?.value)
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <label aria-label="pub category">
                                {dates?.label}
                              </label>
                            </div>
                          </p>
                        ))
                      : props.filterFacetValue !== undefined
                      ? facetAttribute.facetKey != "" &&
                        props.filterFacetValue[facetAttribute.facetKey][
                          facetAttribute.facetKey
                        ]?.buckets !== undefined
                        ? props.filterFacetValue[facetAttribute.facetKey][
                            facetAttribute.facetKey
                          ]?.buckets.map((facetValueObj, index) =>
                            firstLevelCheckboxes(
                              facetAttribute,
                              facetValueObj,
                              index
                            )
                          )
                        : props.filterFacetValue[facetAttribute.facetKey]
                            ?.filter_conditions !== undefined &&
                          props.filterFacetValue[facetAttribute.facetKey]
                            ?.filter_conditions[facetAttribute.facetKey]
                            ?.buckets !== undefined &&
                          props.filterFacetValue[
                            facetAttribute.facetKey
                          ]?.filter_conditions[
                            facetAttribute.facetKey
                          ]?.buckets.map((facetValueObj, index) =>
                            firstLevelCheckboxes(
                              facetAttribute,
                              facetValueObj,
                              index
                            )
                          )
                      : ""}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          );
        })}
        <div className="ApplyButton">
          {/* <Button
            type="button"
            aria-label="Close Preferences"
            disabled={disabled}
            onClick={getApplyFilterPayload}
          >
            {t(APPLY_FILTER.TITLE)}
          </Button> */}
        </div>
      </div>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};

export default FilterFacets;
