import { NULL_VALUE, URL_SHOP_PART_NUMBER } from "../../helper/constants";

let globalFrameHeight = 0;
// Global addAnalytics function for adding analytic data
/* eslint-disable */
export function addAnalytics(data) {
  if (data) {
    const jasonData = JSON.stringify({
      key: "analytics",
      value: data,
    });
    //pushing analytics data to parent
    parent?.window?.postMessage(jasonData, "*");
  }
}
//number to plainString
function toPlainString(num) {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
}
//Function to sent updated height to iframe
/* eslint-disable */
export function resizeParent(extraHeight = 0) {
  try {
    const elmBody = window.document?.body;
    const elmHtml = window.document?.documentElement;
    if (elmBody && elmHtml) {
      setTimeout(function () {
        let frameHeight = elmHtml.offsetHeight + Math.floor(elmHtml.scrollTop);
        if (frameHeight != globalFrameHeight) {
          if (extraHeight > 0) {
            globalFrameHeight = frameHeight + extraHeight;
          } else {
            globalFrameHeight = frameHeight;
          }
          parent.window.postMessage(globalFrameHeight, "*");
        }
      }, 100);
    }
  } catch {}
}
//scroll to top
export function scrollToParentTop() {
  parent?.window?.postMessage(
    JSON.stringify({ key: "scrolltop", value: "" }),
    "*"
  );
}

/**
 * Checks if a variable is and object with properties
 * @param {*} obj - The variable to check
 * @returns true if the variable obj is an object and contains properties, otherwise returns false
 */
export const isObjectWithProperties = (obj) => {
  return typeof obj === "object" && Object.keys(obj).length > 0;
};

function GetEnvDetails() {
  var currentHostName = window.location.host.toLowerCase();
  var EnvObj = [
    {
      appId: 226,
      hostname: "devaerotechpubs.reactapp.honeywell.com",
      domainId: 2261,
    },
    {
      appId: 262,
      hostname: "qaaerotechpubs.reactapp.honeywell.com",
      domainId: 2621,
    },
    {
      appId: 235,
      hostname: "stageaerotechpubs.reactapp.honeywell.com",
      domainId: 2351,
    },
    {
      appId: 75,
      hostname: "aerotechpubs.honeywell.com",
      domainId: 751,
    },
  ];
  return EnvObj.find((obj) => obj.hostname === currentHostName) || {};
}

/**
 * Fetch SSO url depending on the current environment
 * @returns {string} - The current environment SSO url
 */
export const aemSiteLink = () => {
  var envDetails = GetEnvDetails();
  var appId =
    $('input[name\x3d"app_id"]').val() !== undefined
      ? $('input[name\x3d"app_id"]').val()
      : envDetails.appId;
  var aemSiteUrl = "";
  235 == appId && (aemSiteUrl = "https://aerospacebt.stage.honeywell.com");
  226 == appId &&
    (aemSiteUrl = "https://dpublish-aerospacebt.aws.aem.honeywell.com");
  262 == appId &&
    (aemSiteUrl = "https://qpublish-aerospacebt.aws.aem.honeywell.com");
  75 == appId && (aemSiteUrl = "https://aerospace.honeywell.com");
  var userInfo = $('input[name\x3d"user-info-URI"]').val();
  var apiUrl = userInfo;
  var Redirect_SSO =
    aemSiteUrl +
    "/pif/cwa/oauth/request/j_security_check?resource\x3d" +
    window.location.href +
    "\x26appId\x3d" +
    appId;
  return Redirect_SSO;
};

/**
 * Return analytics url to be use in the Launch script
 * @returns string url
 */
export const getAnalyticstLaunchScriptEnv = () => {
  var envDetails = GetEnvDetails();

  var appId =
    $('input[name\x3d"app_id"]').val() !== undefined
      ? $('input[name\x3d"app_id"]').val()
      : envDetails.appId;

  let analyticsUrl = "";

  235 == appId &&
    (analyticsUrl =
      "https://assets.adobedtm.com/ccbae5a917c7/aead7e1aa50c/launch-f72c5c0a7b4a-staging.min.js");
  226 == appId &&
    (analyticsUrl =
      "https://assets.adobedtm.com/ccbae5a917c7/aead7e1aa50c/launch-a8c9f52de30e-development.min.js");
  262 == appId &&
    (analyticsUrl =
      "https://assets.adobedtm.com/ccbae5a917c7/aead7e1aa50c/launch-cafe5a0cd2b8-development.min.js");
  75 == appId &&
    (analyticsUrl =
      "https://assets.adobedtm.com/ccbae5a917c7/aead7e1aa50c/launch-60ca77fd626d.min.js");

  return analyticsUrl;
};

/**
 * THIS FUNCTION CONVERT STRING ARRAY INTO A LINK ARRAY THAT
 * REDIRECT TO PART NUMBER SHOP PAGE
 * @param {*} value array of strings
 * @param {*} valueLength length of the array
 * @returns array of A tags
 */
export const parseToLink = (value, valueLength) => {
  const pattern = /\bSeries?.*/gi;

  if (typeof value === "string") {
    if (value === NULL_VALUE) return value.toString();

    let valueLink = value;

    // only text containing "Series" or Serie will be trimmed.
    if (pattern.test(value)) {
      valueLink = value.replace(pattern, "").trim();
    }

    return (
      <a
        key={`singleItem-${value}`}
        target="_blank"
        href={`${URL_SHOP_PART_NUMBER}/${valueLink}`}
      >
        {value}
      </a>
    );
  }

  if (!value || value.length === 0) {
    return NULL_VALUE;
  }

  const ary1 = [...value];
  const elementsToProcess =
    ary1.length > valueLength ? ary1.splice(0, valueLength) : ary1;

  if (ary1.includes("N") && ary1.includes("A")) {
    return ary1.toString().replace(/,/g, "");
  }

  return elementsToProcess.flatMap((item, index, arr) => {
    let itemLink = item;
    // only text containing "Series" or Serie will be trimmed.
    if (pattern.test(item)) {
      itemLink = item.replace(pattern, "").trim();
    }
    return [
      <a
        key={index}
        target="_blank"
        href={`${URL_SHOP_PART_NUMBER}/${itemLink}`}
      >
        {item}
      </a>,
      index < arr.length - 1 ? ", " : "",
    ];
  });
};
