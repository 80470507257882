import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Pagination } from "@mui/material";
import {
  LABELKEYS_SEARCH_RESULTS,
  RECORDS_PER_COLUMN_VIEW_ALL,
  RECORDS_PER_PAGE_VIEW_ALL,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { parseToLink } from "../../../assets/js/utils";

const ViewAll = (props: any) => {
  const [showPopup, setshowPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [recordPerPage, setRecordPerPage] = useState<any>(
    RECORDS_PER_PAGE_VIEW_ALL
  );
  const [disablePagniation, setDisablePagniation] = useState<boolean>(true);
  const [currentRecords, setcurrentRecords] = useState([]);
  const viewallData = props.data;
  const { t } = useTranslation();
  const viewAllPopup = () => {
    setshowPopup(true);
    setPaginationDetail();
    handlePageChange("", 1);
  };

  const handleClose = () => {
    setshowPopup(false);
    handlePageChange("", 1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    const lastIndex = page * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const currentPageRecords = viewallData.slice(firstIndex, lastIndex);
    const columnRecords = divideInColumns(currentPageRecords);
    setcurrentRecords(columnRecords);
  };

  const setPaginationDetail = () => {
    const numberOfPage: any = Math.ceil(viewallData.length / recordPerPage);
    numberOfPage > 1 ? setDisablePagniation(false) : setDisablePagniation(true);
    setTotalPage(numberOfPage);
  };

  useEffect(() => {
    setPaginationDetail();
    handlePageChange("", 1);
  }, []);

  const divideInColumns = (currentPageRecords) => {
    return currentPageRecords.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / RECORDS_PER_COLUMN_VIEW_ALL);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  };

  return (
    <>
      <a className="view-all-link" onClick={viewAllPopup}>
        {t(LABELKEYS_SEARCH_RESULTS.SPACING)}
        {t(LABELKEYS_SEARCH_RESULTS.VIEW_ALL)}
      </a>

      <Modal
        className="addremovePopup licagreementpopup Popupwrapper viewallPopupdialog"
        aria-label="dialog Preferences"
        backdrop="static"
        show={showPopup}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h2 className="partnumheader">{props.headerTitle}</h2>
        </Modal.Header>

        <div className="modalbodycontentlic modalbodycontentPopups  modalbodycontent">
          <Modal.Body className="SearchresultsPopup">
            {currentRecords.map((records: any, value) => {
              return (
                <ul key={value} className="col-md-4">
                  {records.map((record, index) => {
                    if (props.headerTitle === "Part Numbers") {
                      return <li>{parseToLink(record, 0)}</li>;
                    }
                    return <li key={index}>{record}</li>;
                  })}
                </ul>
              );
            })}
          </Modal.Body>
          {!disablePagniation && (
            <Pagination
              className="myfavpagination viewallPagination"
              count={totalPage}
              page={currentPage}
              onChange={handlePageChange}
              hidePrevButton={false}
              hideNextButton={false}
              showFirstButton={true}
              showLastButton={true}
              boundaryCount={0}
              siblingCount={1}
              shape={"rounded"}
              color={"primary"}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default ViewAll;
