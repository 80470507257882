import React from "react";
import moment from "moment";

export const customDateTemplate = (dates: Array<any> | null, date: any) => {
  const selectedDates = dates || [];
  const currentDate = moment(date).format("YYYY-MM-DD");
  const foundDate = selectedDates.find(
    (selectedDate) =>
      selectedDate && moment(selectedDate).format("YYYY-MM-DD") === currentDate
  );

  if (foundDate) {
    return <span className="p-highlight-blue">{moment(date).format("D")}</span>;
  }

  return <>{moment(date).format("D")}</>;
};

export const spanToBlueColor = () => {
  const childElements = document.querySelectorAll(".p-highlight-blue");

  childElements.forEach((childElement) => {
    const parentElement = childElement.parentElement;

    if (parentElement) {
      parentElement.classList.add("p-highlight-blue");
    }
  });
};

export const formatInputValueWhenUserTypes = (value: string): string => {
  const nums = value.replace(/[^\d]/g, "").substring(0, 16);
  let formattedValue = "";

  for (let i = 0; i < nums.length; i++) {
    formattedValue += nums[i];
    if (i === 3 || i === 5 || i === 11 || i === 13) {
      formattedValue += "-";
    }
    if (i === 7) {
      formattedValue += " - ";
    }
  }
  return formattedValue;
};

export const isValidDateRange = (
  startDate: string,
  endDate: string
): boolean => {
  const startDateObj = moment(startDate, "YYYY-MM-DD", true);
  const endDateObj = moment(endDate, "YYYY-MM-DD", true);
  const today = moment().startOf("day");

  return (
    startDateObj.isValid() &&
    endDateObj.isValid() &&
    startDateObj.isBefore(endDateObj) &&
    endDateObj.isSameOrBefore(today)
  );
};
