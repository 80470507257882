import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/Service";

const initialState = {
  headerFooterMarkupData: {
    header: "",
    footer: "",
    css: [],
    jsFile: [],
    cloudScript: [],
    status: "",
  },
};

export const fetchAsyncData = createAsyncThunk("headerData/Data", async () => {
  try {
    const res = await callToAPI("/api/v1/config/markup", "GET", {
      "Content-Type": "application.json",
    });
    const data = await res.json();

    return data.message.result;
  } catch (error) {}
});

export const headerFooterDataReducer = createSlice({
  name: "headerFooterData",
  initialState,

  extraReducers: {
    [fetchAsyncData.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAsyncData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        header: payload?.headerMarkup,
        footer: payload?.footerMarkup,
        css: payload?.cssResourcePaths,
        jsFile: payload?.jsResourcePaths,
        cloudScript: payload?.cloudScript,
        status: "success",
      };
    },
    [fetchAsyncData.rejected]: (state, action) => {
      state.status = "unable to fetch data";
    },
  },
});

export const headerFooterDataActions = headerFooterDataReducer.actions;
