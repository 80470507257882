import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/Service";
import { HTTP_STATUS_CODE } from "../../helper/constants";

const initialState = {
  location: "",
};

//loading config data
export const fetchAsyncLocation = createAsyncThunk(
  "techpub/location",
  async () => {
    try {
      const res = await callToAPI("/api/v1/geolocation/fetch-location", "GET", {
        "Content-Type": "application.json",
      });
      if (res.status === HTTP_STATUS_CODE.STATUS_200) {
        const data = await res.json();
        return {
          resStatus: HTTP_STATUS_CODE.STATUS_200,
          location: data?.message,
        };
      } else {
        return { resStatus: res.status, location: "" };
      }
    } catch (error) {
    }
  }
);

// loading sold to values

export const locationReducer = createSlice({
  name: "locationData",
  initialState,
  extraReducers: {
    [fetchAsyncLocation.pending]: (state, action) => {
      return { ...state, isLoadingLocation: true };
    },
    [fetchAsyncLocation.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        location: payload?.location,
        resLocationStatus: payload?.resStatus,
        isLoadingLocation: false,
        status: payload.status,
      };
    },
    [fetchAsyncLocation.rejected]: (state, action) => {
      return {
        ...state,
        isLoadingLocation: false,
        resLocationStatus: payload?.resStatus,
      };
    },
  },
});
export const configActions = locationReducer.actions;
