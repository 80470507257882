import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  ERROR_MSG,
  FAVOURITE_TABLE,
  TABLEPAGINATION,
  TEMPORARY_REVISIONS_ATTRIBUTES,
  TEMP_REV,
  TEMP_REVISIONS,
  VIEW_COLLECTION,
} from "../../../helper/constants";
import "./TempRevisions.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import Feature from "../../common/FeatureFlags/Feature/Feature";
import SearchResults from "../SearchResults/SearchResults";
import TableComponent from "../TableComponent/TableComponent";
import BackToSearch from "../../common/BackToSearch/BackToSearch";
import TablePaginations from "../TablePaginations/TablePaginations";
import { fetchAsyncPortalPermissions } from "../../../storage/reducers/configReducer";
const TempRevisions = (props) => {
  const location: any = useLocation();
  const [totalPageNumber, setTotalPageNumber] = useState<any>([]);
  const [errMSG, setErrorState] = useState({ flag: false, msg: "" });
  const tr_ids = location.state.revids;
  const urldata = location.state.searchVal;
  const searchResultsData: any = location.state.datares;
  const url = window.location.pathname;
  const pubId = url.substring(url.lastIndexOf("/") + 1);
  const [tempRevisionTopData, setTempRevisionTopData] = useState<any>([]);
  const hits: any = [];
  const hitsId = location.state.id;
  const [loader, setLoader] = useState(true);
  const [temprev, setTempRev] = useState<any>([]);
  const [showDownloadColumn, setShowDownloadColumn] = useState(true);
  const { portalPermissions, loadingPortal, user_details, hid } = useSelector(
    (state: any) => state.config
  );

  const dispatch =  useDispatch<any>();
  const { t } = useTranslation();
  const tempRevisionsData = searchResultsData?.hits?.hits[hitsId];
  hits.push(tempRevisionsData);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSearchValue(pubId);
    getTemporaryRevision();
  }, []);

  /*    */
  const getSearchValue = async (pubId) => {
    setLoader(true);
    try {
      const response = await fetch("/api/v1/search/search-results", {
        
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubIds: [pubId],
        }),
      });
      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        downloadcolumnChange(
          data?.message?.result?.hits?.hits[0]?._source.is_subscription_flag,
          data?.message?.result?.hits?.hits[0]?._source.export_lic_description
        );
        setLoader(false);
        const totalValue = data?.message?.result?.hits?.total?.value;

        if (totalValue > 1) {
          const searchDataRes: any = data?.message?.result;
          const singleRecord: any = searchDataRes.hits?.hits[0];
          searchDataRes.hits.hits = [];
          searchDataRes.hits.hits.push(singleRecord);
          searchDataRes.hits.total.value = 1;
          setTempRevisionTopData(searchDataRes);
        } else {
          setTempRevisionTopData(data?.message?.result);
        }
      } else if (data?.message?.isSuccess === false) {
        setLoader(false);
        setErrorState({ flag: true, msg: data?.message?.result });
      } else {
        setLoader(false);
        setErrorState({ flag: true, msg: data?.message?.result });
      }
    } catch (error) {
      setLoader(false);
      setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const downloadcolumnChange = (subscriptionFlag, licenseFlag) => {
    switch (true) {
      case subscriptionFlag === true && licenseFlag.toLowerCase() === "license":
      case subscriptionFlag === false &&
        licenseFlag.toLowerCase() === "license":
        setShowDownloadColumn(false);
        break;
      case licenseFlag.toLowerCase() !== "license":
        if (portalPermissions?.result?.isInternal === true) {
          setShowDownloadColumn(true);
        } 
        else if (hid != '' && portalPermissions?.result?.isInternal === undefined) {
          dispatch(fetchAsyncPortalPermissions(hid));
          if(portalPermissions?.result?.isInternal === true)
          {
            setShowDownloadColumn(true);
          }
        }
        else {
          subscriptionFlag === true
            ? setShowDownloadColumn(true)
            : setShowDownloadColumn(false);
        }
        break;
      // case subscriptionFlag === false && licenseFlag.toLowerCase() !== "license":
      //   setShowDownloadColumn(false);
      //   break;
      // case subscriptionFlag === true && licenseFlag.toLowerCase() !== "license":
      //   setShowDownloadColumn(true);
      //   break;
    }
  };

  const getTemporaryRevision = async (pageNumber = 1) => {
    try {
      const response = await fetch("/api/v1/temporary-revision", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // tr_id : [320542, 320541,320536,320543,169890,320574,4354,320562,4354,320561],
          tr_id: tr_ids,
          pageable: {
            pageNo: pageNumber,
            pageSize: TABLEPAGINATION.RowsPerPage,
            sortBy: "tr_revision_number",
          },
        }),
      });
      const data = await response.json();

      if (data?.message?.isSuccess === true) {
        data?.message?.result?.trDataList
          ? setTempRev(data?.message?.result?.trDataList)
          : setTempRev([]);
        setTotalPageNumber(
          Math.ceil(
            data?.message?.result.totalTrRecords / TABLEPAGINATION.RowsPerPage
          )
        );
      } else if (data?.message?.isSuccess === false) {
        setErrorState({ flag: true, msg: data?.message?.result });
      } else {
        setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setErrorState({ flag: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const onChange = () => {
    setErrorState({ flag: false, msg: "sucsess" });
  };

  const parentPageChange = (page) => {
    // setTotalPageNumber(page);
    getTemporaryRevision(page);
  };
  return (
    <>
      <BackToSearch data={urldata} />
      <Feature name="Search Results">
        <SearchResults
          dataSearch={tempRevisionTopData}
          searchval={location.state.searchVal}
          Watchlistflag={true}
          load={false}
          showMore={false}
          attributes={TEMPORARY_REVISIONS_ATTRIBUTES}
          className="ViewcollectionContainer TopmetaData"
        />
      </Feature>
      <div className="my_top_fav">
        <TableComponent
          attributes={TEMP_REVISIONS}
          topHeader={TEMP_REV.TITLE}
          userData={temprev}
          loaderFlag={loader}
          stampTypeId={
            tempRevisionTopData?.hits?.hits[0]?._source["stamp_type_id"]
              ? tempRevisionTopData?.hits?.hits[0]?._source["stamp_type_id"]
              : ""
          }
          ldm={
            tempRevisionTopData?.hits?.hits[0]?._source[
              "limited_distribution_number"
            ]
          }
          a2a2_indicator={
            tempRevisionTopData?.hits?.hits[0]?._source["2a2a_indicator"]
          }
          purpose="temp_Revision"
          downloadColumn={showDownloadColumn}
          tempRevData={tempRevisionTopData?.hits?.hits?.[0]?._source}
          isLocationID = {true}
          pubId={tempRevisionTopData?.hits?.hits[0]?._source["pub_uuid"]}
        />
        {totalPageNumber > 1 && (
          <TablePaginations
            Type="TR"
            count={totalPageNumber}
            parentPageChange={parentPageChange}
          />
        )}
      </div>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.flag}
        onClose={onChange}
      />
    </>
  );
};

export default TempRevisions;