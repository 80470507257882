import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/Service";
import { HTTP_STATUS_CODE } from "../../helper/constants";

const initialState = {
  mylibrary: "",
  controller:[],
};

//loading config data
export const fetchAsyncMyLibrary = createAsyncThunk(
  "techpub/mylibrary",
  async () => {
    try {
      const res =   await fetch("/api/v1/library",
        {
          method: "POST",
          body: JSON.stringify({
            isMetadata: "0",        
          }),
        }
      );
      if (res.status === HTTP_STATUS_CODE.STATUS_200) {
        const data = await res.json();
        return {
          resStatus: HTTP_STATUS_CODE.STATUS_200,
          mylibrary: data?.message,
        };
      } else {
        return { resStatus: res.status, mylibrary: "" };
      }
    } catch (error) {
    }
  }
);

// loading sold to values

export const myLibraryReducer = createSlice({
  name: "myLibraryData",
  initialState,
  extraReducers: {
    [fetchAsyncMyLibrary.pending]: (state, action) => {
      return { ...state, isLoadingmyLibrary: true };
    },
    [fetchAsyncMyLibrary.fulfilled]: (state, { payload }) => {
     
      return {
        ...state,
        mylibrary: payload?.mylibrary,
        resmyLibraryStatus: payload?.resStatus,
        isLoadingmyLibrary: false,
        status: payload.status,
      };
    },
    [fetchAsyncMyLibrary.rejected]: (state, action) => {
      return {
        ...state,
        isLoadingmyLibrary: false,
        isLoadingmyLibrary: payload?.resStatus,
      };
    },
  },
});
export const configActions = myLibraryReducer.actions;
