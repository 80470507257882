import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  REQUEST_FORM,
  REQUEST_FORM_DATA,
  REQUEST_FORM_TEXT,
  TOPHEADERTITLE,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";


const  ConfirmModalPopup = (props) => {
  const { t } = useTranslation(); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectUrl = () => {
   
    navigate(props.navigationbarVal, { replace: true });
    dispatch(
      RequestFormAction.getRequestFormData({
        requestDataObj: "",
      })
    );
    props.setShowPopup(false);
    
  };
  return (
      <>
      <Modal
      className="favPopup addremovePopup licagreementpopup Popupwrapper"
      aria-label="dialog Preferences"
      show={props.show}
    >
      <Modal.Body className="Modal-body-liccontent requestformPopup modal-body">
        <p>{t(REQUEST_FORM.NAV_TEXT)}</p>
      </Modal.Body>

      <Modal.Footer>
      <div className="footercontainerlic Popupfooter footercontainer">
          <div className="geocheckbtns">
            <Button
              variant="secondary"
              onClick={() => props.setShowPopup(false)}
              className="cancelbtn active btn btn-secondary">
              {t(REQUEST_FORM.NO_BTN)}
            </Button>
            <Button
              variant="primary"
              onClick={redirectUrl}
              className="submitbtn active btn btn-primary btn btn-primary">
              {t(REQUEST_FORM.YES_BTN)}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
      </>
  );
};


export default ConfirmModalPopup;

function dispatch(arg0: { payload: any; type: string; }) {
  throw new Error("Function not implemented.");
}
