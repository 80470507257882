import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { REQUEST_FORM } from "../../../helper/constants";
import { useTranslation } from "react-i18next";

const RequestFormModal = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  const closePopup = () => {
    props.closeRequestFormModal();
  };

  const changeCheckBoxVal = () => {
    props.changeCheckBoxVal();
    props.setDisableSubmitBtn(true);
  };

  return (
    <>
      <Modal
        className="favPopup addremovePopup licagreementpopup Popupwrapper"
        aria-label="dialog Preferences"
        show={props.show}
      >
        <Modal.Body className="Modal-body-liccontent requestformPopup">
          <p>{t(REQUEST_FORM.MODAL_BDY)}</p>
        </Modal.Body>

        <Modal.Footer>
          <div className="footercontainerlic Popupfooter footercontainer">
            <div className="geocheckbtns">
              <Button
                variant="secondary"
                onClick={closePopup}
                className="cancelbtn active btn btn-secondary"
              >
                {t(REQUEST_FORM.NO_BTN)}
              </Button>
              <Button
                variant="primary"
                onClick={changeCheckBoxVal}
                className="submitbtn active btn btn-primary btn btn-primary"
              >
                {t(REQUEST_FORM.YES_BTN)}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestFormModal;
