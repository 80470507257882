import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { REQUEST_ERRORMODAL } from "../../../helper/constants";

const RequestFormErrorHandling = (props) => {
    const { t } = useTranslation();
    return (
        <>
          <Modal
            className="favPopup addremovePopup licagreementpopup Popupwrapper"
            aria-label="dialog Preferences"
            show={props.showPopup}
          >
            <Modal.Body className="Modal-body-liccontent requestformPopup">
              <p>{t(REQUEST_ERRORMODAL.ERROR_MSG)}</p>
            </Modal.Body>
    
            <Modal.Footer>
              <div className="footercontainerlic Popupfooter footercontainer">
                <div className="geocheckbtns">
                  <Button
                    variant="secondary"
                    onClick={props.retryBtn}
                    className="cancelbtn active btn btn-secondary"
                  >
                    {t(REQUEST_ERRORMODAL.RETRY_BTN)}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => props.setRequestFormErrorPopup()}
                    className="submitbtn active btn btn-primary btn btn-primary"
                  >
                    {t(REQUEST_ERRORMODAL.CANCEL_BTN)}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
};

export default RequestFormErrorHandling;
