import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scrollPosition: "",
  scrollValid: ""
};

export const scrollPositionReducer = createSlice({
  name: "scrollPositionReducer",
  initialState,
  reducers: {
    getScrollPosition: (state, { payload }) => {
      return {
        scrollPosition: payload?.scrollPosition,
        scrollValid : payload?.scrollValid
      };
    },
  },
});

export const ScrollPositionActions = scrollPositionReducer.actions;