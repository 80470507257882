import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./Calendar.scss";
import CalendarFooter from "./CalendarFooter";
import calendarIcon from "../FilterFacets/images/calendericon.svg";
import CalendarInput from "./CalendarInput";
import useOnEnterKeyCalendarInput from "./useOnEnterKeyCalendarInput";
import useOutsideCalendarClick from "./useOutsideCalendarClick";
import { customDateTemplate, spanToBlueColor } from "./CalendarActions";

interface Iprops {
  clearCalendar: boolean;
  keepCalendarDatesActive: () => void;
  onDatesChange: (Object) => void;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  clearPropsDates: () => void;
}

const CalendarWrapper: React.FC<Iprops> = ({
  clearCalendar,
  keepCalendarDatesActive,
  onDatesChange,
  startDate,
  endDate,
  clearPropsDates,
}) => {
  const [dates, setDates] = useState<Array<any> | null>(null);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [calendarReRenderKey, setCalendarReRenderKey] = useState(Date.now());
  const wrapperRef = useRef(null);

  // valitade dates is an array and have not null values and all dates are
  // less than or equal to today, then enable apply button or 'Enter' action to search
  const validDates =
    Array.isArray(dates) &&
    dates.every((element) => element !== null) &&
    dates.every((element) => moment(element).toDate() <= moment().toDate());

  // This custom hook is used to handle clicks outside the wrapperRef   (calendar)
  useOutsideCalendarClick(
    wrapperRef,
    () => setOpenCalendar(false),
    startDate,
    endDate,
    setDates,
    validDates,
    dates
  );

  useEffect(() => {
    // Used to handle if page is reload and startDate and endDate are selected
    if (startDate && endDate && dates === null) {
      setDates([moment(startDate).toDate(), moment(endDate).toDate()]);
    } else if (!startDate && !endDate) {
      setDates(null);
    }
    if (clearCalendar) {
      setDates(null);
      clearPropsDates();
    }
  }, [clearCalendar, startDate, endDate]);

  useEffect(() => {
    if (openCalendar) {
      const intervalId = setInterval(() => {
        spanToBlueColor();
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [openCalendar]);

  // While user is typing in the input field of the calendar or select any date,
  // this function is called
  const handleDateChange = (arrayDates: Array<any> | any) => {
    setDates(arrayDates);
  };

  // Apply button on Click action
  const onApply = () => {
    if (Array.isArray(dates)) {
      onDatesChange({
        startDate: moment(dates[0]),
        endDate: moment(dates[1]),
      });
      keepCalendarDatesActive();
    }
  };

  // Function to handle the cancel button
  const onCancel = () => {
    setDates(null);
    setOpenCalendar(false);
    if (startDate !== null && endDate !== null) {
      setDates([moment(startDate).toDate(), moment(endDate).toDate()]);
    }
  };

  // Function to handle the Today button
  const onToday = () => {
    setDates((prevState: any[] | null) => {
      if (Array.isArray(prevState) && prevState.length > 0) {
        return [prevState[0], moment().toDate()];
      } else {
        return [moment().toDate(), null];
      }
    });
    setCalendarReRenderKey(Date.now());
  };

  // This custom hook is used to handle when the Enter key is pressed in the input field of the calendar
  // and calendar state has two dates: start date and end date
  useOnEnterKeyCalendarInput(
    "calendarCustomInput",
    validDates,
    onApply,
    setOpenCalendar,
    keepCalendarDatesActive
  );

  return (
    <div className="calendarAndInputWrapper" ref={wrapperRef}>
      <CalendarInput
        setOpenCalendar={setOpenCalendar}
        dates={dates}
        validDates={validDates}
        setDates={setDates}
        startDate={startDate}
        endDate={endDate}
      />
      {openCalendar && (
        <div className="card flex justify-content-center calendarView">
          <Calendar
            key={calendarReRenderKey}
            className="CalendarRange"
            selectionMode="range"
            dateFormat="yy-mm-dd"
            value={dates}
            numberOfMonths={2}
            onChange={(e) => handleDateChange(e.value)}
            showIcon
            showOtherMonths={false}
            maxDate={moment().toDate()}
            appendTo={document.body}
            readOnlyInput={false}
            yearRange={`1940:${moment().year()}`}
            icon={calendarIcon}
            inline
            yearNavigator
            monthNavigator
            footerTemplate={() => (
              <CalendarFooter
                onToday={onToday}
                onCancel={onCancel}
                onApply={onApply}
                validDates={validDates}
                closeCalendar={setOpenCalendar}
              />
            )}
            dateTemplate={(date) => customDateTemplate(dates, date)}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarWrapper;
