import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./GeoCheck.scss";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCESS_STATUS,
  ERROR_MSG,
  HTTP_STATUS_CODE,
  LABELKEYS_GEOCHECK,
  URLS,
} from "../../../helper/constants";
import { ModalBody } from "react-bootstrap";
import Loader from "../../common/Loader/Loader";
import { components } from "react-select";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import { fetchAsyncLocation } from "../../../storage/reducers/locationReducer";
import { AsyncThunkAction } from "@reduxjs/toolkit";

export const GeoCheck = (props) => {
  const { t } = useTranslation();
  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );
  const dispatch = useDispatch<any>();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedflag, setSelectedFlag] = useState(false);
  const [accesstoken, setAccessToken] = useState("");
  const handleClose = () => setShow(false);
  const [coutrylist, setCoutryList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const { location, resLocationStatus, isLoadingLocation } = useSelector(
    (state: any) => state.location
  );
  const { tokenValid, isLoadingToken } = useSelector(
    (state: any) => state.config
  );
  const approvedStatus = ACCESS_STATUS.APPROVED;
  const checkStatus = tokenValid?.isSuccess;
  const toolAccess =
    checkStatus === true ? tokenValid?.result?.KEY_TOOL_ACCESS : "";
  const getAccesssToken = async () => {
    try {
      const response = await fetch("/api/v1/config/access-token", {
        method: "GET",
      });

      const data = await response.json();
      if (data?.message?.isSuccess === true) {
        setAccessToken(data?.message?.result);

        getCountryList(data?.message?.result);
      } else {
        setErorState({ falg: true, msg: data?.message?.result });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const getCountryList = async (token_data) => {
    try {
      setLoader(true);
      const response = await fetch("/api/v1/geolocation/get-country", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      });

      const data = await response.json();

      if (data?.message?.isSuccess === true) {
        setCoutryList(data?.message?.result);
        setLoader(false);
      } else {
        setErorState({ falg: true, msg: data?.message?.result });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const CountryList = coutrylist.map(function (city) {
    return { value: city["isoCode"], label: city["country"] };
  });

  const handleChange = (selectedOption) => {
    const country = {
      countryName: selectedOption.label,
      countryCode: selectedOption.value,
    };
    setSelectedCountry(country);
    setSelectedFlag(true);
  };

  const handleChangeCheck = (e) => {
    setChecked(e.target.checked);
  };
  useEffect(() => {
    //getAccesssToken();
    getCountryList('');
    if (
      tokenValid &&
      isLoadingToken === false &&
      checkStatus === true &&
      toolAccess != "" &&
      toolAccess == approvedStatus &&
      isLoadingLocation === false
    ) {
      setTimeout(function () {
        setShow(true);
      }, 10);
    }
  }, []);

  const cancelButton = () => {
    props?.onCloseModalGeoCheck(false);
    setShow(false);
  };

  const submitApiCall = async (reqBody) => {
    try {
      setLoader(true);
      const response = await fetch("/api/v1/geolocation/save-location", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });
      //const data = await response.json();
      if (HTTP_STATUS_CODE.STATUS_201 === response.status) {
        setLoader(false);
        setShow(false);
        dispatch(fetchAsyncLocation());
      } else {
        setLoader(false);
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setLoader(false);
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const submitBtnHandler = () => {

    handleClose();
    if (checked === true) {
      const reqBody = {
        ...selectedCountry,
      };
      submitApiCall(reqBody);
      props?.handleGeoCheck(reqBody);

    }
  };

  const divclick = () => {
    if (document.getElementsByClassName("css-1pahdxg-control")[0]) {
      document
        .getElementsByClassName("css-1pahdxg-control")[0]
        .classList.add("Menu_Open");

      document.getElementsByClassName(
        " css-1gtu0rj-indicatorContainer"
      )[0].className = "css-tlfecz-indicatorContainer";
    }
    if (document.getElementsByClassName("css-1s2u09g-control")[0])
      document
        .getElementsByClassName("css-1s2u09g-control")[0]
        .classList.add("Menu_Open");

    if (document.getElementsByClassName("css-tlfecz-indicatorContainer")[0]) {
      document.getElementsByClassName(
        "css-tlfecz-indicatorContainer"
      )[0].className = "css-1gtu0rj-indicatorContainer";
    } else if (
      document.getElementsByClassName("css-1gtu0rj-indicatorContainer")[0]
    ) {
      document.getElementsByClassName(
        "css-1gtu0rj-indicatorContainer"
      )[0].className = "css-tlfecz-indicatorContainer";
    }
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="no-result-found-text">
          {t(LABELKEYS_GEOCHECK.NO_RESULTS_FOUND)}
        </span>
      </components.NoOptionsMessage>
    );
  };

  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };

  return (
    <>
      {loader && <Loader />}
      <Modal
        className="modal-wrapper"
        aria-label="geocheck poppup"
        show={show}
      >
        <div className="modalbodycontent">
          <Modal.Header>
            <Modal.Title>
              <span className="modaltitle">
                {t(LABELKEYS_GEOCHECK.COUNTRY_SELECTION)}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t(LABELKEYS_GEOCHECK.MODAL_TITLE)}</p>
            <div className="CountryDropdown" onClick={divclick}>
              <Select
                options={CountryList}
                components={{ NoOptionsMessage }}
                onChange={handleChange}
                placeholder={t(LABELKEYS_GEOCHECK.SUB_HEADING)}
                className="coutry-ddl"
                aria-label="choose country"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footercontainer">
              <div className="constentmsg">
                <div
                  className={checked ? "leftcheckbox checked" : "leftcheckbox"}
                >
                  <input
                    aria-label="Type Preferences"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChangeCheck}
                    disabled={selectedflag ? false : true}
                  />
                </div>
                <div>
                  <label className="isOptional">
                    {" "}
                    {t(LABELKEYS_GEOCHECK.CHECK_BOX)}
                  </label>
                </div>
              </div>
              <div className="geocheckbtns rightalignbtn">
                <Button
                  type="button"
                  aria-label="Close Preferences"
                  className="cancelbtn active"
                  variant="secondary"
                  onClick={cancelButton}
                >
                  {t(LABELKEYS_GEOCHECK.CANCEL_BUTTON)}
                </Button>

                {checked && selectedflag ? (
                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="submitbtn active"
                    onClick={submitBtnHandler}
                  >
                    {t(LABELKEYS_GEOCHECK.SUBMIT_BUTTON)}
                  </Button>
                ) : (
                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="submitbtn"
                    disabled={true}
                  >
                    {t(LABELKEYS_GEOCHECK.SUBMIT_BUTTON)}
                  </Button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};

